/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import _ from 'lodash'
import { useTheme } from 'react-jss'
import { Trans, useTranslation } from 'react-i18next'
import useStyles from './coupon-card-style'
import CouponStateLabel from '../coupon-state-label'

import combineClassNames from '../../helpers/combineClassNames'
import { useThemeConfig } from '../../hook/use-theme-config'
import IconTime from '../../assets/icons/icon_time.svg'
import placeholderImage from '../../assets/images/icon_image_placeholder.png'

const CouponCardView = (props) => {
  const {
    type,
    miniCartCoupon,
    id,
    displayImage,
    imageUrl,
    title,
    description,
    displayTimeLabel,
    displayTime,
    sharedLabel,
    state,
    stateLabel,
    disabled,
    requiredPoints,
    onSelect,
    isExpired,
    isTransferable,
    isTransferInProgress,
    isNonStarted,
    status,
    statusLabel: _statusLabel,
    usableCouponTokenCount,
  } = props
  // prepare hook
  const { t } = useTranslation()
  const theme = useTheme()
  const { getConfig } = useThemeConfig()
  const hideLoyaltyPoints = getConfig('config.hideLoyaltyPoints', false)

  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  const noImage = _.isEmpty(imageUrl)
  const couponImageUrl = imageUrl || placeholder
  const usableCouponTokenCountLabel = t('ui.couponCard.usableCouponTokenCount', { count: usableCouponTokenCount })
  const showRequiredPoints = !_.isUndefined(requiredPoints) && !hideLoyaltyPoints

  // style
  const styles = useStyles({ displayImage, miniCartCoupon })

  function classStatus(couponStatus) {
    switch (couponStatus) {
      case 'success':
        return styles.add
      case 'error':
        return styles.redeem
      default:
        return styles.used
    }
  }

  function handleSelect() {
    if (_.isFunction(onSelect) && !disabled) {
      onSelect({ id, type })
    }
  }

  const statusType = isExpired && status === 'usable' ? 'expired' : _.camelCase(status)
  const statusLabel = isExpired && status === 'usable'
    ? t('ui.minicartCoupons.couponStateLabel', { context: 'expired' })
    : _statusLabel
  return (
    <div
      className={combineClassNames([styles.couponCard,
        miniCartCoupon && styles.couponCardMinicart,
        (!disabled && _.isFunction(onSelect)) && styles.selectable,
        disabled && styles.disabled,
      ])}
      onClick={handleSelect}
    >
      <div className={styles.couponContent}>
        {
          displayImage && (
            <div className={combineClassNames([styles.couponImage, noImage && styles.noImage])}>
              <div>
                <img src={couponImageUrl} alt="" />
              </div>
            </div>
          )
        }
        <div className={styles.coupontText}>
          <div className={styles.titleBox}>
            {
              !_.isEmpty(title) && (
                <p className={combineClassNames([styles.titleStyle,
                  miniCartCoupon && styles.titleStyleMinicart,
                ])}
                >
                  {title}
                </p>
              )
            }
            {
              !_.isEmpty(stateLabel) && (
                <p className={classStatus(state)}>
                  {stateLabel}
                </p>
              )
            }
          </div>

          {description && (
            <div
              className={styles.clauseStyle}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {
            usableCouponTokenCount > 1 && (
              <div className={styles.usableCouponTokenCount}>
                <span className={styles.usableCouponTokenCountLabel}>
                  {usableCouponTokenCountLabel}
                </span>
              </div>
            )
          }
        </div>
      </div>
      <div className={styles.couponCardFooter}>
        <div
          className={
            combineClassNames([
              styles.couponCardFooterColumn,
              styles.couponCardFooterDateColumn,
            ])
          }
        >
          {
            displayTimeLabel && (
              <div className={styles.dateLabel}>{displayTimeLabel}</div>
            )
          }
          <div className={styles.couponCardFooterDate}>
            <img src={IconTime} alt="icon time" />
            {' '}
            <span className={styles.couponCardFooterText}>
              {displayTime}
            </span>
          </div>
        </div>
        {
          showRequiredPoints && (
            <div
              className={
                combineClassNames([
                  styles.couponCardFooterColumn,
                  styles.couponCardFooterPointColumn,
                ])
              }
            >
              <div className={styles.points}>
                <Trans
                  i18nKey="ui.couponCard.pointToRedeem"
                  values={{ points: requiredPoints }}
                />
              </div>
            </div>
          )
        }
        {
          !_.isEmpty(statusLabel)
          && !showRequiredPoints
          && (
            <div
              className={
                combineClassNames([
                  styles.couponCardFooterColumn,
                  styles.couponCardFooterPointColumn,
                ])
              }
            >
              <div className={styles.couponCardFooterText}>
                {
                  t(
                    'ui.couponCard.statusLabel',
                    {
                      context: statusType,
                      defaultValue: statusLabel,
                    },
                  )
                }
              </div>
              {
                !_.isEmpty(sharedLabel) && (
                  <div
                    className={combineClassNames([
                      styles.couponCardFooterText,
                      styles.couponCardFooterTextSharedLabel,
                    ])}
                  >
                    {sharedLabel}
                  </div>
                )
              }
            </div>
          )
        }
      </div>
      <CouponStateLabel
        {...{
          isExpired,
          isNonStarted,
          isTransferable,
          isTransferInProgress,
          isUsable: status === 'usable',
        }}
      />
    </div>
  )
}

export default CouponCardView
