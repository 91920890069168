/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { useCurrencies } from 'react-omnitech-api'
import CurrencyFormat from '../currency-format'
import { useLink, useMiniCart } from '../../hook'
import { groupCartLineProperties } from '../../helpers'
import useStyles from './mini-cart-style'
import Button from '../button'
import ProductMiniCart from '../product-mini-cart'
import Cross from '../../assets/icons/icon_cross_white.svg'

const MiniCartView = () => {
  // prepare hook
  const { navigate } = useLink()
  const { currencies } = useCurrencies()
  const { t } = useTranslation()
  const {
    cart,
    loading,
    miniCartOpen,
    totalItems,
    skusUnavailableToCheckout,
    closeMiniCart,
    onRemoveCartLine,
  } = useMiniCart()

  // local variable
  const { cartLineProperties = [], priceDetails = {}, currencyCode } = cart
  const { cartSubtotalPrice, subtotalPrice } = priceDetails
  const currency = _.find(currencies, { code: currencyCode })

  const groupedCartLineProperties = useMemo(() => (
    groupCartLineProperties(cartLineProperties)
  ), [cartLineProperties])

  const cartLineItems = useMemo(() => (
    _.map(groupedCartLineProperties, (item) => {
      const showNotAllowedToCheckoutMessage = !_.isEmpty(
        _.intersection(
          [
            _.get(item, 'skuId'),
            ..._.map(_.get(item, 'addons', []), 'skuId'),
          ],
          _.map(skusUnavailableToCheckout, 'id'),
        ),
      )
      return {
        ...item,
        ...showNotAllowedToCheckoutMessage && {
          inlineError: t('ui.dineInMiniCart.itemUnavailable'),
        },
      }
    })
  ), [groupedCartLineProperties, skusUnavailableToCheckout])

  /**
   * handleViewCart
   * go to cart page
   */
  const handleViewCart = () => {
    closeMiniCart()
    navigate('/cart/')
  }
  useEffect(() => {
    document.body.classList[miniCartOpen ? 'add' : 'remove']('modal-open__mini-cart')
    if (!miniCartOpen) {
      // document.body.scrollTop = currenctScrollTop // for safari
      // document.documentElement.scrollTop = currenctScrollTop // for IE/Firefox/...others
    }
  }, [miniCartOpen])

  useEffect(() => (
    // remove modal-open__mini-cart class from body when componentDidMount
    () => {
      document.body.classList.remove('modal-open__mini-cart')
      document.getElementsByTagName('body')[0].setAttribute('style', '')
    }
  ),
  [])

  // style
  const style = useStyles()

  return (
    <>
      <CSSTransition
        in={miniCartOpen}
        timeout={300}
        unmountOnExit
        classNames="mini-cart"
      >
        <div className={style.miniCard}>
          {
            loading || _.isEmpty(cart)
              ? (
                <div className={style.loaderBox}>
                  <div className={style.loader}> </div>
                </div>
              )
              : (
                <>
                  <div className={style.boxItems}>
                    <div className={style.boxTitle}>
                      <h4>{t('ui.miniCard.title')}</h4>
                      <button
                        className={style.closeButton}
                        type="button"
                        onClick={closeMiniCart}
                      >
                        <img src={Cross} alt="" />
                      </button>
                    </div>
                    <div className={style.boxProducts}>
                      {cartLineItems.map((cartLine) => (
                        <ProductMiniCart
                          key={`mini-cart-line-${cartLine.id}`}
                          cartLine={cartLine}
                          currency={currency}
                          dark
                          onRemoveCartLine={onRemoveCartLine}
                        />
                      ))}
                    </div>
                  </div>
                  <div className={style.boxPrice}>
                    <div>
                      <p>{t('ui.miniCard.items')}</p>
                      <p>
                        <span className={style.plus}>x </span>
                        {totalItems}
                      </p>
                    </div>
                    <div>
                      <p>{t('ui.miniCard.subtotal')}</p>
                      <CurrencyFormat
                        value={cartSubtotalPrice || subtotalPrice}
                        className={style.price}
                        currency={currency}
                      />
                    </div>
                    <Button
                      light
                      className={style.button}
                      text={t('ui.miniCard.button')}
                      onClick={handleViewCart}
                    />
                  </div>
                </>
              )
        }
        </div>
      </CSSTransition>
      {
        miniCartOpen && (
          <div className={style.overlap} onClick={closeMiniCart} />
        )
      }
    </>
  )
}

export default MiniCartView
