/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { withBreakpoints } from 'gatsby-plugin-breakpoints'
import { useTheme } from 'react-jss'
import { useSystemSettings, useStores } from 'react-omnitech-api'
import ContentGroup from '../content-group'
import Link from '../link'

import useStyle from './footer-style'
import TofugearLogo from '../../assets/icons/tofugear-logo.svg'
import combineClassNames from '../../helpers/combineClassNames'
import {
  useThemeConfig,
} from '../../hook/use-theme-config'
import useOrderMethod from '../../hook/use-order-method'

const FooterView = ({ className, breakpoints }) => {
  // prepare hook
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    store,
  } = useOrderMethod()
  const { stores } = useStores()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            version
          }
        }
      }
    `,
  )
  const { getSystemSetting } = useSystemSettings()
  const { getConfig, getContentGroup } = useThemeConfig()
  const siteName = getSystemSetting('frontend.site_name')

  // local variable
  const footerLinkContentGroupProps = useMemo(() => ({
    footer: getConfig('config.ui.footerNavigation.footer', true),
    ...getContentGroup('config.ui.footerNavigation', 'ecom_footer_link'),
  }), [getContentGroup])

  const footerLinkTermsContentGroupProps = useMemo(() => ({
    footer: getConfig('config.ui.footerLinkTerms.footer', true),
    ...getContentGroup('config.ui.footerLinkTerms', {
      code: '',
      template: 'List',
    }),
  }), [getContentGroup])

  const footerSocialLinkContentGroupProps = useMemo(() => ({
    footer: getConfig('config.ui.footerSocialMedia.footer', true),
    ...getContentGroup('config.ui.footerSocialMedia', {
      code: 'ecom_footer_social_media',
      template: 'List',
    }),
  }), [getContentGroup])

  const footerFacebookContentGroupProps = useMemo(() => ({
    footer: getConfig('config.ui.footerFacebookPageWidget.footer', true),
    ...getContentGroup('config.ui.footerFacebookPageWidget', {
      code: 'ecom_footer_facebook_page_widget',
      template: 'FacebookPageWidget',
    }),
  }), [getContentGroup])
  const bottomBarContentGroupProps = useMemo(() => (
    getContentGroup('config.ui.footer.bottomBar')
  ), [getContentGroup])
  const hasWidgetFacebook = _.get(footerFacebookContentGroupProps, 'footer') === true
  const styles = useStyle({
    hasWidgetFacebook,
  })
  const useStoreBrandLogo = getConfig('config.ui.footer.logo.useStoreBrandLogo', false)

  const selectedStore = _.find(stores, { id: _.get(store, 'id', '////') })
  const selectedStoreBrandIcon = useStoreBrandLogo && _.get(selectedStore, 'iconDataUri')

  // internal states
  const [width, setWidth] = useState('500')
  const [height, seHeight] = useState('210')

  useEffect(() => {
    if (breakpoints.sm) {
      setWidth('340')
      seHeight('280')
    } else {
      setWidth('500')
      seHeight('230')
    }
  }, [breakpoints])

  return (
    <div className={combineClassNames([
      styles.containerFluid,
      className])}
    >
      <div className={styles.containerLines}>
        <div className={styles.container}>
          <div className={styles.contentLinks}>
            <ContentGroup {...footerLinkContentGroupProps} />

            <div className={styles.socialLink}>
              <ContentGroup {...footerSocialLinkContentGroupProps} />
            </div>
          </div>
          {
            hasWidgetFacebook && (
            <div className={styles.witgetLink}>
              <ContentGroup
                height={height}
                width={width}
                {...footerFacebookContentGroupProps}
              />
            </div>
            )
          }
        </div>
      </div>
      <div className={styles.containerLogo}>
        <div className={styles.boxLogo}>
          <h1 className={styles.logo}>
            <Link gatsbyLink to="/" className={styles.logoImage} id="logo">
              <picture>
                <source
                  srcSet={
                    selectedStoreBrandIcon
                    || getConfig('config.ui.footer.logo.imageDesktop', getConfig('config.logoImageDesktop', TofugearLogo))
                  }
                  media={`(min-width: ${theme.mediaQueries.tabletLandscape})`}
                />
                <img
                  src={
                    selectedStoreBrandIcon
                    || getConfig('config.ui.footer.logo.imageMobile', getConfig('config.logoImageMobile', TofugearLogo))
                  }
                  alt={siteName}
                  className={styles.logoImage}
                />
              </picture>
            </Link>
          </h1>
          <span>{t('ui.footerCheckout.copy')}</span>
        </div>

        <div className={styles.socialLinkLogo}>
          <ContentGroup {...footerSocialLinkContentGroupProps} />
        </div>
      </div>
      { footerLinkTermsContentGroupProps.code && (
        <div className={styles.containerLinksFooter}>
          <ContentGroup {...footerLinkTermsContentGroupProps} />
        </div>
      )}
      {
        bottomBarContentGroupProps.code && (
          <div className={styles.bottomBar}>
            <ContentGroup
              {...bottomBarContentGroupProps}
              lineStyle={styles.bottomBarContent}
            />
          </div>
        )
      }

      <p className={styles.appVersion}>
        {site.siteMetadata.version}
      </p>
    </div>
  )
}

export default withBreakpoints(FooterView)
