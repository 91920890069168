/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import URI from 'urijs'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  cancelRequest,
  useCurrencies,
  useProductGroups,
  useSystemSettings,
  useSkus,
  useDineInCart,
} from 'react-omnitech-api'
import ProductGroupView from './product-group-view'
import { getParamsToTrack } from '../../helpers'
import {
  useAlert,
  useAnalytics,
  useCart,
  useLocation,
  useOrderMethod,
  useProductQuickAddModal,
  useSku,
} from '../../hook'

function ProductGroupController(props) {
  const { code, title, onClick } = props
  // prepare omnitech api
  const { getSystemSetting } = useSystemSettings()
  const { t } = useTranslation()
  const { location } = useLocation()
  const { fetchSkus } = useSkus()
  const {
    orderMethod,
    store,
  } = useOrderMethod()
  const {
    open: openProductQuickAddModal,
  } = useProductQuickAddModal()
  const alert = useAlert()
  const {
    initCart,
  } = useCart()
  const {
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
  } = useSku()
  const {
    trackEvent,
    getProductParams,
  } = useAnalytics()
  const { currencies } = useCurrencies()
  // internal state
  const [productGroupLines, setProductGroupLines] = useState([])
  const [siblings, setSiblings] = useState([])
  const [siblingsLoading, setSiblingsLoading] = useState(false)
  const pageSizeSettings = getSystemSetting('product_groups.page_size') || 4
  const paramsToTrack = getParamsToTrack()
  const fnbEnabled = getSystemSetting('features.fnb.enable')

  const inventoryStoreCode = useMemo(() => {
    if (_.get(orderMethod, 'code', '') === 'dineInMenu') {
      return _.get(store, 'code')
    }
    return _.get(initCart, 'cartShipments.0.inventoryStore.code')
  }, [initCart, orderMethod, store])

  const commerceChannelFromUrl = useMemo(() => {
    const url = new URI(_.get(location, 'href'))
    const search = url.search(true)
    return _.get(search, 'cc')
  }, [location])

  const siblingsProductCodes = useMemo(() => (
    _.compact(
      _.flatMap(productGroupLines, ({ sku }) => _.get(sku, 'product.meta.siblingsProductCodes', [])),
    )
  ), [productGroupLines])

  // /**
  //  * fetchSkuByProductGroup
  //  * pass code as number for productGroupId, or as string to get skus filter by productGroupCode
  //  * get product group line from API by product group code
  //  */

  const fetchSkuByProductGroup = useCallback(async (id, pageSize) => {
    if (_.isEmpty(inventoryStoreCode)) return
    try {
      // api call option
      const extraParams = getSystemSetting('api.v2.skus.index.product_search.ecom.query', {})
      const menuFiltersParam = getMetaMenuFilterParams()
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
      const option = {
        schemaVersion: '2021-07-21',
        includes: [
          'product',
          'color_option',
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.price_details',
          'color_options.stock_level',
          'meta',
          'products.color_option_variant_type',
          'products.category_ids',
          'products.brand_ids',
          'products.meta',
        ].join(','),
        distinct: 'pc',
        sortBy: 'product_group',
        productGroupId: _.isNumber(id) ? id : undefined,
        productGroupCode: !_.isNumber(id) ? id : undefined,
        inventoryStoreCodeEq: inventoryStoreCode,
        priceStoreCodeEq: inventoryStoreCode,
        /* [FL] 20210507 Roku: For new format,
         * it should be product_type_eq, but backend not supported yet
         * please use product_types first
         */
        pageSize,
        productTypes: 'product',
        ...extraParams,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }

      // call api
      const { skus: skusData, pagination: paginationData } = await fetchSkus(option)
      const items = _.map(skusData, (sku) => ({
        sku,
      }))
      setProductGroupLines(items)
    } catch (error) {
      // TODO: handle error
    }
  }, [inventoryStoreCode])

  const fetchSkusByProductCodeApi = useCallback(async (productCodeEq) => {
    setSiblingsLoading(true)
    const menuFiltersParam = getMetaMenuFilterParams()
    const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
    try {
      // api call option
      // setSiblingsLoading(true)
      const option = {
        productCodeEq,
        pageCountless: true,
        // schemaVersion: '2021-04-29',
        includes: [
          'skus.meta',
          'skus.product',
          'skus.price_details',
        ].join(','),
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { skus: _siblings } = await fetchSkus(option)
      setSiblings((prevSiblings) => _.unionBy(_siblings, prevSiblings, 'id'))
    } catch (error) {
      // fail silently
    } finally {
      setSiblingsLoading(false)
    }
  }, [fetchSkus])

  function trackProductsImpression() {
    const products = _.map(productGroupLines, ({ id, sku }, idx) => ({
      ...getProductParams(sku),
      list: title,
      position: (idx + 1),
    }))
    trackEvent('viewProductImpression', {}, { products, title: paramsToTrack.title })
  }

  function handleClickTrackEvent(eventName, product) {
    trackEvent(eventName, {}, { product })
  }

  const onProductClick = ({ productId, colorOptionId }) => {
    openProductQuickAddModal({
      id: productId,
      colorOptionId,
      onAddToCartSuccess: () => {
        alert.show(
          t('screens.products.alertMessage', { context: 'addItemToCartSuccess' }),
          { state: 'default', autoClose: 1000 },
        )
      },
    })
  }

  useEffect(() => {
    if (_.isEmpty(productGroupLines)) return
    trackProductsImpression()
  }, [productGroupLines])

  // call api to get product group line when component is mount
  useEffect(() => {
    fetchSkuByProductGroup(code, pageSizeSettings)

    return function cleanUp() {
      cancelRequest.cancelAll(['fetchProductGroupByCode', 'fetchProductGroupLine'])
    }
  }, [code, pageSizeSettings, commerceChannelFromUrl, fetchSkuByProductGroup])

  useEffect(() => {
    if (!_.isEmpty(siblingsProductCodes)) {
      fetchSkusByProductCodeApi(siblingsProductCodes)
    }
  }, [siblingsProductCodes, fetchSkusByProductCodeApi])

  const viewProps = {
    className: _.get(props, 'className'),
    currency: _.find(currencies, { isBaseCurrency: true }),
    productGroupCode: code,
    productGroupLines,
    title,
    siblings,
    siblingsLoading,
    onClick,
    onClickTrackEvent: handleClickTrackEvent,
    onProductClick,
  }

  return <ProductGroupView {...viewProps} />
}

export default ProductGroupController
