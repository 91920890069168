import moment from 'moment/min/moment-with-locales'
import React, { useMemo } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useCouponMarketplace } from '../../../../hook'
import useStyles from './coupon-marketplace-coupon-tokens-group-by-coupon-style'
import combineClassNames from '../../../../helpers/combineClassNames'
import convertItemsToSectionList from '../../../../helpers/convert-items-to-section-list'
import isExpired from '../../../../helpers/is-expired'
import isExpireSoon from '../../../../helpers/is-expire-soon'
import CouponMarketplaceHeader from '../coupon-marketplace-header'
import CouponsTabPanel from '../coupon-marketplace-tabs/coupons-tab-panel'
import MessageScreen from '../../../message-screen'
import Spinner from '../../../spinner'

const CouponMarketplaceCouponTokenDetailView = ({
  couponTokens = [],
  hasMore,
  isOpen,
  isReady,
  onClose,
  onNextPage,
}) => {
  const { t } = useTranslation()
  const {
    onCouponDetailOpen,
    onCouponMarketplaceClose,
  } = useCouponMarketplace()
  const styles = useStyles()

  /**
 * grouping coupon tokens by month
 */
  const couponTokensListData = useMemo(() => (
    convertItemsToSectionList({
      items: couponTokens,
      sectionTitle: (item) => {
        const date = new Date(item.computedGracePeriodExpiresAt)
        const dateFormat = t('ui.dateFormat.month', { defaultValue: 'MMMM YYYY' })
        const formatedDate = moment(date).format(dateFormat)
        if (isExpired(date)) return t('ui.minicartCoupons.expire', { context: 'expired' })
        if (isExpireSoon(date)) return t('ui.minicartCoupons.expire', { context: 'expireSoon' })
        return t('ui.minicartCoupons.expire', { month: formatedDate })
      },
    })
  ), [couponTokens])

  const getCouponTokenToItem = (couponToken = {}) => {
    const {
      canActions = [],
      coupon,
      computedGracePeriodExpiresAt: endAt,
      sharedAt,
      startAt,
      status,
      statusLabel,
      transferState,
    } = couponToken
    const {
      description,
      discountType,
      discountValue,
      name,
    } = coupon || {}
    const displayTime = `${moment(startAt).format('DD.MM.YYYY')} - ${moment(endAt).format('DD.MM.YYYY')}`
    const isTransferable = !_.isEmpty(
      _.intersection(
        canActions,
        ['transfer_out', 'transfer_in', 'transfer_undo'],
      ),
    )
    const sharedLabel = _.isNil(sharedAt)
      ? sharedAt
      : t(
        'ui.minicartCoupons.myCoupons.sharedAt',
        { time: moment(sharedAt).format('DD.MM.YYYY') },
      )

    return {
      imageUrl: _.get(coupon, 'defaultImage.versions.webSmall'),
      title: name,
      discountType,
      discountValue,
      description,
      displayTime,
      displayTimeLabel: t('ui.minicartCoupons.myCoupons.validPeriod'),
      sharedLabel,
      status,
      statusLabel,
      isExpired: moment().isAfter(endAt),
      isTransferable: isTransferable && transferState !== 'completed',
      isTransferInProgress: transferState === 'transfer_in_progress',
      isNonStarted: status === 'non_started',
    }
  }

  const title = _.get(
    _.first(couponTokens),
    'coupon.name',
  )

  return (
    <div className={
      combineClassNames([styles.container,
        isOpen && styles.containerOpen])
      }
    >
      <CouponMarketplaceHeader
        title={title}
        withBackButton
        onBack={onClose}
        onCloseDetail={onCouponMarketplaceClose}
      />
      <div className={combineClassNames([styles.body, hasMore && styles.bodyHasMore])}>
        {
          isReady
            ? (
              <>
                {
                  couponTokensListData.length > 0
                    ? (
                      <CouponsTabPanel
                        groups={couponTokensListData}
                        hasMore={hasMore}
                        loadMore={onNextPage}
                        getItemData={getCouponTokenToItem}
                        type="couponToken"
                        onSelect={onCouponDetailOpen}
                      />
                    )
                    : (
                      <div className={styles.noContent}>
                        <MessageScreen
                          message={t('ui.minicartCoupons.myCoupons.message')}
                        />
                      </div>
                    )
                }
              </>
            )
            : (
              <div className={styles.loading}>
                <Spinner />
              </div>
            )
        }
      </div>
    </div>
  )
}

export default CouponMarketplaceCouponTokenDetailView
