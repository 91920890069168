/**
 * Create modal context for custom hook
 */
import { createContext } from 'react'

const ProductQuickAddModalContext = createContext({
  close: () => {},
  isOpen: false,
  onContinue: () => {},
  onRequestClose: () => {},
  open: () => {},
})

export default ProductQuickAddModalContext
