import React from 'react'
import { useTheme } from 'react-jss'
import { useSystemSettings } from 'react-omnitech-api'
import Link from '../link'
import useStyles from './header-checkout-style'
import combineClassNames from '../../helpers/combineClassNames'
import TofugearLogo from '../../assets/icons/tofugear-logo.svg'

const HeaderCheckoutView = ({
  className,
}) => {
  const theme = useTheme()
  const { getSystemSetting } = useSystemSettings()
  const siteName = getSystemSetting('frontend.site_name')

  const styles = useStyles()
  return (
    <header className={combineClassNames([styles.containerFluid, className])}>
      <div className={styles.container}>
        <h1 className={styles.logo}>
          <Link gatsbyLink to="/">
            <picture>
              <source
                srcSet={theme.config.logoImageDesktop || TofugearLogo}
                media={`(min-width: ${theme.mediaQueries.tabletLandscape})`}
              />
              <img
                src={theme.config.logoImageMobile || TofugearLogo}
                alt={siteName}
                className={styles.logoImage}
              />
            </picture>
          </Link>
        </h1>
      </div>
    </header>
  )
}

export default HeaderCheckoutView
