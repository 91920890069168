/* eslint-disable react-hooks/exhaustive-deps */
/**
 * DineInMiniCartProvider
 * Contain most logic mini cart
 */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useSkus, useStores } from 'react-omnitech-api'
import { useAlert } from '../use-alert'
import useCart from '../use-cart'
import { useLink } from '../use-link'
import useSku from '../use-sku'
import useOrderMethod from '../use-order-method'
import DineInMiniCartContext from './dine-in-mini-cart-context'
// import usePriceTemplate from '../use-price-template'
// import { trackEvent } from '../../helpers'

export default function DineInMiniCartProvider({ children }) {
  // const CART_PRICE_TEMPLATE_KEY = _.get(usePriceTemplate(), 'code')
  // prepare hook
  const alert = useAlert()
  const { navigate } = useLink()
  const { validateSku } = useSku()
  const {
    fetchStoreDateTimeInformation,
  } = useStores()
  const { fetchSkus } = useSkus()
  const {
    // fetchCart,
    getParams,
    initCart,
    stagingCart,
    resetStagingCart,
    totalStagingItems,
    updateCart,
    updateStagingCart,
  } = useCart()
  const {
    orderMethod,
  } = useOrderMethod()
  // internal state
  // const [cart, setCart] = useState({})
  const [loading, setLoading] = useState(false)
  const [miniCartOpen, setMiniCartOpen] = useState(false)
  const [skusUnavailableToCheckout, setSkusUnavailableToCheckout] = useState([])

  /**
   * apiFetchCart
   * get cart data from API
   */
  // function apiFetchCart() {
  //   const options = {
  //     params: getParams({
  //       includeGroups: ['miniCart', 'dineInMiniCart'],
  //     }),
  //   }
  //   return fetchCart(options)
  // }

  /**
   * apiUpdateCart
   * call API to update cart
   * @param {*} actions
   */
  // function apiUpdateCart(actions) {
  //   const option = {
  //     payload: {
  //       data: {
  //         actions,
  //       },
  //     },
  //     params: getParams({
  //       includeGroups: ['miniCart', 'dineInMiniCart'],
  //     }),
  //   }
  //   return updateCart(option)
  // }

  /**
   * handleError
   * @param {*} error
   */
  function handleError(error) {
    const generalError = _.get(error, 'generalError', {})
    const batchActionErrors = _.get(error, 'batchActionErrors', [])
    const batchActionError = _.find(batchActionErrors, 'message') || {}
    // if batch action is not provided, use the general error message
    const errorMessage = _.isEmpty(batchActionError.message)
      ? generalError.message
      : batchActionError.message
    alert.show(errorMessage, { state: 'error' })
  }

  /**
   * function handleCloseMiniCart() {
   * close mini cart
   */
  function handleCloseMiniCart() {
    setMiniCartOpen(false)
  }

  /**
   * function handleOpenMiniCart() {
   * open mini cart
   */
  function handleOpenMiniCart() {
    setLoading(true)
    setMiniCartOpen(true)
  }

  /**
   * function handleOpenPlaceOrderDialog() {
   * open place order dialog
   */
  function handleOpenPlaceOrderDialog() {
    // setLoading(true)
    // setMiniCartOpen(true)
  }

  /**
   * function handlePlaceOrder() {
   * Add staging cart items to dine in cart
   */
  function handlePlaceOrder() {
    // const includes = [
    //   'cart_line_properties',
    // ]
    const actions = _.map(
      _.get(stagingCart, 'actions', []),
      (action) => (
        _.pick(action, [
          'actionType',
          'groupUuid',
          'identifierUuid',
          'parentIdentifierUuid',
          'productAddonId',
          'quantity',
          'quantityMode',
          'skuId',
        ])
      ),
    )
    setLoading(true)
    return updateCart({
      payload: {
        data: { actions },
        batchUpdateMode: 2,
      },
      params: getParams({
        includes: ['miniCart', 'dineInMiniCart'],
      }),
    })
      .then(() => {
        resetStagingCart()
        navigate('/dine-in-order-placed/')
      })
      .catch((error) => {
        handleError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /**
   * handleRemoveCartLine
   */
  function handleRemoveCartLine(item) {
    const actions = [
      {
        ...item,
        quantity: 0,
      },
    ]
    updateStagingCart({ actions })
    // handleUpdateCart(actions)
    // onTrackRemoveFromCart(cart.id)
  }

  async function checkSkuAllowToCheckout(skuIds) {
    if (_.isEmpty(skuIds)) return
    try {
      const id = _.isArray(skuIds) ? _.join(skuIds, ',') : skuIds
      const option = {
        id,
        includes: [
          'skus.meta',
          'skus.stock_level',
        ].join(','),
      }
      // call api
      const { skus = [] } = await fetchSkus(option)
      // get store dateTimeInformation before valdation
      const storeId = _.get(initCart, 'physicalStoreId')
      const { dateTimeInformation } = await fetchStoreDateTimeInformation({ id: storeId })
      const storeMenuCodes = _.get(dateTimeInformation, 'menuCodes', [])
      const skusWithValidationErrors = _.map(skuIds, (skuId) => {
        const sku = _.find(skus, { id: skuId })
        // pass-in cart exclusions
        const exclusions = _.get(orderMethod, 'dineInCartSkuCheckExclusions', {})
        const validationErrors = _.isEmpty(sku)
          ? ['skuNotFound']
          : _.keys(_.omitBy(validateSku({ sku, storeMenuCodes, exclusions })))
        return {
          ...(sku || { id: skuId, code: skuId }),
          validationErrors,
        }
      })
      setSkusUnavailableToCheckout(
        _.reject(skusWithValidationErrors, ({ validationErrors }) => _.isEmpty(validationErrors)),
      )
    } catch (error) {
      handleError(error)
    }
    setLoading(false)
  }

  /**
   * check cartLines once mini cart is open
   */
  useEffect(() => {
    if (miniCartOpen) {
      checkSkuAllowToCheckout(
        _.uniq(_.compact(_.map(_.get(stagingCart, 'actions', []), 'skuId'))),
      )
    }
  }, [miniCartOpen, stagingCart])

  useEffect(() => {
    if (totalStagingItems > 0) return
    handleCloseMiniCart()
  }, [totalStagingItems])

  const state = {
    loading,
    miniCartOpen,
    totalItems: _.get(stagingCart, 'totalItems', 0),
    skusUnavailableToCheckout,
    openMiniCart: handleOpenMiniCart,
    closeMiniCart: handleCloseMiniCart,
    openPlaceOrderDialog: handleOpenPlaceOrderDialog,
    onRemoveCartLine: handleRemoveCartLine,
    onPlaceOrder: handlePlaceOrder,
  }

  return (
    <DineInMiniCartContext.Provider value={state}>
      {children}
    </DineInMiniCartContext.Provider>
  )
}
