import _ from 'lodash'
import React from 'react'
import { useTheme } from 'react-jss'
import { Helmet } from 'react-helmet'
import Alert from '../alert'
import FooterCheckout from '../footer-checkout'
import HeaderCheckout from '../header-checkout'
import useStyles from './layout-checkout-style'
import PaymentLogos from '../payment-logos'

const LayoutCheckoutView = ({
  children,
  disableFooter,
  disablePaymentFooter,
}) => {
  const styles = useStyles()
  const theme = useTheme()
  const extraScripts = _.get(theme, 'config.pages.layout.extraScripts', [{}])
  return (
    <div className={styles.containerFluid}>
      {!_.isEmpty(_.get(theme, 'typo.fontFamilyUrl')) && (
        <Helmet>
          <link href={_.get(theme, 'typo.fontFamilyUrl')} rel="stylesheet" />
        </Helmet>
      )}
      <HeaderCheckout
        className={styles.header}
      />
      <div className={styles.container}>{children}</div>
      { !disablePaymentFooter && <PaymentLogos /> }
      { !disableFooter && <FooterCheckout useSuspense={false} /> }
      {!_.isEmpty(extraScripts)
        && _.map(extraScripts, (script, idx) => <script key={idx} type={script.type}>{`${script.code}`}</script>)}
      <Alert />
    </div>
  )
}

export default LayoutCheckoutView
