/* eslint-disable react-hooks/exhaustive-deps */
/**
 * ShareProvider
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import useModal from '../use-modal/use-modal'
import SocialMedia from '../../ui/social-media'

export default function ShareHook() {
  const modal = useModal()
  const { t } = useTranslation()
  const useStyles = createUseStyles(() => ({
    logos: {
      justifyContent: 'center',
    },
  }))
  const styles = useStyles()
  const share = ({
    url,
    imageUrl,
    title,
    message,
    modalDialogTitle,
    modalDialogMessage,
  }) => {
    if (navigator.share) {
      navigator.share({
        title,
        text: message,
        url,
      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch(console.error);
    } else {
      modal.open({
        title: modalDialogTitle,
        message: modalDialogMessage,
        contentComponent: <SocialMedia
          title={message}
          imageUrl={imageUrl}
          shareUrl={url}
          logosClassName={styles.logos}
        />,
        buttons: [{
          text: t('ui.share.modalDialog.buttons.dismiss'),
          onClick: () => modal.close(),
        }],
      })
    }
  }

  return {
    share,
  }
}
