import { useContext } from 'react'
import ProductQuickAddModalContext from './product-quick-add-modal-context'

/**
 * useModal
 * The custom hook which provided to external use via context
 */
const useProductQuickAddModal = () => {
  const context = useContext(ProductQuickAddModalContext)
  return context
};

export default useProductQuickAddModal
