import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  modal: {
    backgroundColor: 'transparent',
    padding: 0,
    margin: '0 auto',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    top: 'auto',
    maxHeight: '99dvh',
    fallbacks: {
      maxHeight: '92vh',
    },
    maxWidth: `${theme.mediaQueries.tablet}px`,
    overflow: 'hidden',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      maxHeight: '90vh',
      bottom: '5vh',
    },
  },
  dialogContainer: {
    backgroundColor: theme.colors.background,
    flexShrink: 1,
    flexGrow: 0,
    overflow: 'hidden',
    borderTopLeftRadius: '0.8rem',
    borderTopRightRadius: '0.8rem',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'stretch',

    maxHeight: 0,
    transform: 'translateY(100%)',
    transition: 'transform 200ms ease-in-out',
  },
  dialogContainerActive: {
    maxHeight: 'none',
    transform: 'translateY(0%)',
  },
  dialogHeader: {
    flexShrink: 0,
    position: 'relative',
    minHeight: '4.8rem',
  },
  closeButton: {
    padding: '1rem',
    position: 'absolute',
    right: '0.5rem',
    top: 0,
    bottom: 0,
  },
  closeButtonIcon: {
    width: '1.4rem',
    height: '1.4rem',
  },
  dialogBody: {
    paddingBottom: 'var(--ui-spacer-l)',
    flexShrink: 1,
    flexGrow: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'var(--ui-product-quick-add-modal-grid-template-columns)',
    gridTemplateRows: 'var(--ui-product-quick-add-modal-grid-template-rows)',
    gridTemplateAreas: 'var(--ui-product-quick-add-modal-grid-template-areas)',
    columnGap: 'var(--ui-product-quick-add-modal-column-gap)',
    paddingLeft: 'var(--ui-product-quick-add-modal-padding-left)',
    paddingRight: 'var(--ui-product-quick-add-modal-padding-right)',
  },
  dialogFooter: {
    overflow: 'hidden',
    backgroundColor: theme.colors.background,
    boxShadow: '0rem 0rem 0rem rgba(0, 0, 0, 0)',
    padding: '1.2rem 1.6rem',
    flexShrink: 0,
    zIndex: 10,

    display: 'flex',
    justifyContent: 'space-between',
    gap: '1.6rem',
    alignItems: 'center',
    transition: 'box-shadow 1s',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      borderBottomLeftRadius: '0.8rem',
      borderBottomRightRadius: '0.8rem',
    },
  },
  dialogFooterWithShadow: {
    boxShadow: '0rem -0.5rem 0.5rem rgba(0, 0, 0, 0.1)',
  },
  // dialogButtons: {
  //   maxWidth: '33.8rem',
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  //   marginBottom: '-1.6rem',
  // },
  productQuantity: {
    flexBasis: '80%',
  },
  button: {
    marginBottom: 0,
    backgroundColor: theme.config.ui.buttons.addToCart.active.backgroundColor,
    border: theme.config.ui.buttons.addToCart.active.border,
  },

  // product details
  upperContent: {
    padding: '0 0 1.6rem',
    gap: '1rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'var(--ui-product-quick-add-modal-flex-direction)',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  link: {
    textDecoration: 'none',
    flexGrow: 1,
  },
  figure: {
    gridArea: 'images',
    display: 'var(--ui-product-quick-add-modal-images-display)',
    marginTop: 'var(--ui-product-quick-add-modal-images-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-images-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
    position: 'relative',
    width: '100%',
    aspectRatio: 'var(--ui-product-quick-add-modal-image-aspect-ratio)',
  },
  favouriteButton: {
    position: 'absolute',
    zIndex: 1,
    top: 'var(--ui-spacer-xs)',
    right: 'var(--ui-spacer-xs)',
  },
  icon: {
    position: 'absolute',
    right: '1.3rem',
    top: '1.3rem',
    cursor: 'pointer',
    maxWidth: '1.8rem',
  },
  image: {
    borderRadius: 'var(--ui-product-quick-add-modal-image-border-radius)',
    overflow: 'hidden',
    objectFit: 'cover',
    objectPosition: 'center center',
    width: '100%',
    height: '100%',
    margin: 0,
    display: 'block',
  },
  noImage: {
    backgroundColor: '#f1f1f1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    objectFit: 'contain',
  },
  contentText: {
    fontSize: '1.4rem',
    lineHeight: '1.42em',
    textAlign: 'var(--ui-product-quick-add-modal-content-text-align)',
  },
  titleText: {
    extend: 'contentText',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  sectionTitle: {
    extend: 'titleText',
  },
  title: {
    gridArea: 'title',
    display: 'var(--ui-product-quick-add-modal-title-display, block)',
    marginTop: 'var(--ui-product-quick-add-modal-title-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-title-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
  },
  details: {
    gridArea: 'description',
    display: 'var(--ui-product-quick-add-modal-description-display)',
    marginTop: 'var(--ui-product-quick-add-modal-description-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-description-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
    overflow: 'hidden',
  },
  detialsTitle: {
    display: 'var(--ui-product-quick-add-modal-description-title-display, block)',
  },
  detailsContent: {
    fontSize: '1.4rem',
    '& h1': {
      fontSize: '3.6rem',
      lineHeight: 1.16,
      fontWeight: 600,
      textAlign: 'left',
      padding: '0 0 1.2rem',
      margin: '3rem 0 3rem',
      position: 'relative',
      color: theme.colors.primary,
      '&:after': {
        content: '" "',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '5rem',
        height: '0.3rem',
        backgroundColor: theme.colors.secondary,
      },
    },
    '& ol': {
      listStyleType: 'decimal',
      listStylePosition: 'outside',
      marginLeft: '1.5rem',
    },
    '& ul': {
      listStyleType: 'disc',
      listStylePosition: 'outside',
      marginLeft: '1.5rem',
    },
    '& li': {
      fontSize: '1.4rem',
      lineHeight: 1.71,
      marginBottom: 0,
    },
    '& p': {
      fontSize: '1.4rem',
      marginBottom: 10,
      lineHeight: '2.4rem',
    },
    '& iframe, & p iframe': {
      height: 220,
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        height: 430,
      },
    },
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
    },
    '& img': {
      margin: [[30, 0]],
    },
    '& h2': {
      lineHeight: 1.18,
      fontSize: '3.4rem',
    },
    '& h3': {
      lineHeight: 1.18,
      fontSize: '2.8rem',
    },
    '& h4': {
      lineHeight: 1.18,
      fontSize: '2.4rem',
    },
    '& h5': {
      lineHeight: 1.18,
      fontSize: '2rem',
    },
    '& h6': {
      lineHeight: 1.18,
      fontSize: '1.8rem',
    },
  },
  customLabelsContainer: {
    gridArea: 'customLabels',
    display: 'var(--ui-product-quick-add-modal-custom-labels-display)',
    marginTop: 'var(--ui-product-quick-add-modal-custom-labels-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-custom-labels-margin-bottom)',
    // minHeight: '2rem',
    '&:empty': {
      display: 'none',
    },
  },
  customLabels: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'var(--ui-product-quick-add-modal-custom-labels-justify-content)',
    alignItems: 'baseline',
  },
  colorsBox: {
    gridArea: 'colorOptions',
    display: 'var(--ui-product-quick-add-modal-color-options-display)',
    marginTop: 'var(--ui-product-quick-add-modal-color-options-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-color-options-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
  },
  sizesBox: {
    gridArea: 'sizeOptions',
    display: 'var(--ui-product-quick-add-modal-size-options-display)',
    marginTop: 'var(--ui-product-quick-add-modal-size-options-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-size-options-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
  },
  colorSelect: {
    color: theme.colors.disabled,
  },
  variantTitle: {
    extend: 'sectionTitle',
    marginBottom: 'var(--ui-spacer-s)',
  },
  colorStyle: {
    display: 'block',
    height: '5.2rem',
    cursor: 'pointer',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    '&.select': {
      borderColor: theme.colors.secondary,
    },
  },
  sizeStyle: {
    minHeight: '3.6rem',
    minWidth: '5.1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '1.4rem',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 2.5,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.colors.bgDark,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      background: theme.colors.bgDark,
      borderColor: theme.colors.bgDark,
    },
  },
  sizeSelectedStyle: {
    borderColor: theme.colors.secondary,
    background: hexToRgba(theme.colors.secondary, 0.5),
    '&:hover': {
      borderColor: theme.colors.secondary,
      background: hexToRgba(theme.colors.secondary, 0.5),
    },
  },
  sizeOutOfStock: {
    backgroundColor: hexToRgba(theme.colors.disabled, 0.2),
    '&:hover': {
      // background: theme.colors.background,
      borderColor: theme.colors.gray500,
    },
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '1.5rem',
  },
  optionsItem: {
    minWidth: '4.8rem',
  },
  priceContainer: {
    gridArea: 'price',
    display: 'var(--ui-product-quick-add-modal-price-display, flex)',
    marginTop: 'var(--ui-product-quick-add-modal-price-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-price-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'var(--ui-product-quick-add-modal-price-container-justify-content)',
  },
  price: {
    fontSize: 'var(--ui-product-quick-add-modal-price-font-size)',
    justifyContent: 'var(--ui-product-quick-add-modal-price-list-justify-content)',
  },
  addons: {
    gridArea: 'addons',
    display: 'var(--ui-product-quick-add-modal-addons-display)',
    marginTop: 'var(--ui-product-quick-add-modal-addons-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-addons-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
  },
  recentlyViewed: {
    gridArea: 'recentlyViewed',
    display: 'var(--ui-product-quick-add-modal-recently-viewed-display)',
    marginTop: 'var(--ui-product-quick-add-modal-recently-viewed-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-recently-viewed-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
  },
  recentlyViewedTitle: {
    display: 'var(--ui-product-quick-add-modal-recently-viewed-title-display, block)',
  },
  recommendations: {
    gridArea: 'recommendations',
    display: 'var(--ui-product-quick-add-modal-recommendations-display)',
    marginTop: 'var(--ui-product-quick-add-modal-recommendations-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-recommendations-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
  },
  recommendationsTitle: {
    display: 'var(--ui-product-quick-add-modal-recommendations-title-display, block)',
  },
  attributes: {
    gridArea: 'attributes',
    display: 'var(--ui-product-quick-add-modal-attributes-display)',
    marginTop: 'var(--ui-product-quick-add-modal-attributes-margin-top)',
    marginBottom: 'var(--ui-product-quick-add-modal-attributes-margin-bottom)',
    '&:empty': {
      display: 'none',
    },
  },
  attributesTitle: {
    display: 'var(--ui-product-quick-add-modal-attributes-title-display, block)',
    marginBottom: 'var(--ui-spacer-l)',
  },
  backInStockNotificationButton: {
    backgroundColor: theme.colors.gray300,
    borderColor: theme.colors.gray300,
  },
  backInStockNotificationButtonIcon: {
    margin: [[0, '0.4rem']],
  },
  backInStockNotificationButtonDisabled: {
    opacity: 0.5,
  },

}))

export default useStyles
