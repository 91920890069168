import _ from 'lodash'
import React from 'react'
import combineClassNames from '../../helpers/combineClassNames'
import keyExtractor from '../../helpers/key-extractor'
import useStyles from './product-detail-attributes-style'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductDetailAttributesView = (props) => {
  const {
    className,
    data = [],
    keyExtractor: _keyExtractor = keyExtractor,
  } = props
  const styles = useStyles()

  return (
    <div className={combineClassNames([styles.table, className])}>
      {
        _.map(data, (item, index) => (
          <dl
            key={_keyExtractor(item, index)}
            className={styles.line}
          >
            <dt className={styles.label}>{_.get(item, 'label')}</dt>
            <dd className={styles.value}>{_.get(item, 'value')}</dd>
          </dl>
        ))
      }
    </div>
  )
}

export default ProductDetailAttributesView
