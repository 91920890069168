/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'
import IconArrow from '../../../assets/icons/icon_solid_arrow.svg'

const useStyles = createUseStyles((theme) => ({

  container: {
    marginTop: '0rem',
    marginBottom: '5.5em',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headerBox: {
    padding: '0 20px',
    height: '4.9rem',
    borderBottom: `1px solid ${theme.colors.bgDark}`,
    borderTop: `1px solid ${theme.colors.bgDark}`,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
    '& p': {
      fontSize: '1.4rem',
      color: theme.colors.primary,
      textTransform: 'capitalize',
      margin: 0,
    },
  },
  wrapper: ({ active }) => ({
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: '-5px',
    height: '24px',
    width: '24px',
    transform: active ? 'rotate(90deg)' : 'rotate(0)',
  }),
  iconStyle: {
    backgroundImage: `url(${IconArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    backgroundSize: '0.75rem',
    position: 'absolute',
    width: '1rem',
    height: '1rem',
    right: '2rem',
    top: '50%',
    left: '50%',
    zIndex: '10',
    transform: 'translate(-50% , -50%)',
  },
  languageBox: ({ active }) => ({
    position: 'relative',
    backgroundColor: '#f7f7f7',
    height: active ? 98 : 0,
    overflow: 'hidden',
    transition: 'height 0.3s',
  }),
  languageListItem: {
    backgroundColor: '#f7f7f7',
    padding: '0 20px',
    height: '4.9rem',
    border: 0,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.bgDark}`,
    borderTop: `1px solid ${theme.colors.bgDark}`,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
    fontSize: '1.4rem',
    color: theme.colors.text,
  },
  currentLanguage: {
    color: theme.colors.bgDark,
  },

}))

export default useStyles
