import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    height: '6.8rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      height: 'auto',
    },
  },
  stickyContainer: {
    display: 'block',
    width: '100%',
    boxShadow: '0 0.2rem 1rem rgba(0,0,0,0.1)',
    borderTop: `1px solid ${theme.colors.gray300}`,
    borderBottom: `1px solid ${theme.colors.gray300}`,
    textAlign: 'center',
    backgroundColor: theme.colors.background,
    position: 'fixed',
    zIndex: 10,
    left: 0,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      position: 'static',
    },
  },
  tableNumber: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    marginTop: '0.5rem',
  },
  button: {
    display: 'block',
    width: '100%',
    textTransform: 'none',
    textAlign: 'center',
    fontSize: '1.4rem',
    paddingBottom: '0.5rem',
  },
}))

export default useStyles
