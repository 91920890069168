import _ from 'lodash'

/**
 * getRegistrationFormInitialValues
 * base on form config to generate a initial value for registration form
 *
 * @param { formConfig, defaultCountryCallingCode }
 */
export default function getRegistrationFormInitialValues({
  formConfig = {},
  defaultValues = {},
  defaultCountryCallingCode = '',
  defaultLocale = '',
}) {
  return _.reduce(formConfig, (result, current, key) => {
    let value = ''
    switch (current.type) {
      case 'checkbox':
        value = false
        break;

      default:
        break;
    }
    const newResult = {
      ...result,
      [key]: _.includes(['meta'], key) ? {} : value,
    }
    switch (key) {
      case 'phone':
        newResult.token = ''
        newResult.countryCallingCode = defaultCountryCallingCode
        break
      case 'locale':
        newResult.locale = defaultLocale
        break
      case 'email':
        if (current.requireConfirmation === true) {
          newResult.emailConfirmation = ''
        }
        break
      case 'password':
        if (current.requireConfirmation === true) {
          newResult.passwordConfirmation = ''
        }
        break
      default:
        break
    }
    return {
      ...newResult,
      ...defaultValues,
    }
  }, {})
}
