/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useTheme } from 'react-jss'
import StarRatingComponent from 'react-star-rating-component';
import { BsFillStarFill } from 'react-icons/bs';
import useStyles from './rating-style'

const RatingView = ({ rate, onChange, ...others }) => {
  const { ratingStyle } = useStyles()
  const theme = useTheme()
  const maxRate = 5

  return (
    <div className={ratingStyle}>
      <StarRatingComponent
        name="review"
        starCount={maxRate}
        value={parseInt(rate || 0, 10)}
        onStarClick={onChange}
        starColor={theme.colors.secondary}
        emptyStarColor={theme.colors.gray300}
        renderStarIcon={() => <span><BsFillStarFill size={15} /></span>}
        {...others}
      />
    </div>
  )
}

export default RatingView
