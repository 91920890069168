import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  group: {
    position: 'relative',
    overflow: 'hidden',
    userSelect: 'none',
    // '& *': {
    //   userSelect: 'none',
    // },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.6rem',
    // '&:hover': {
    //   opacity: 0.8,
    // },
  },
  listItemLeft: ({ disabled } = {}) => ({
    paddingRight: '1rem',
    opacity: disabled ? 0.5 : 1,
  }),
  listItemTitle: {
    lineHeight: 1.25,
  },
  separator: {
    border: 'none',
    borderBottom: `0.1rem solid ${theme.colors.gray500}`,
    padding: 0,
    margin: 0,
  },
}))

export default useStyles
