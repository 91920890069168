import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  optionContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    margin: '0.4rem 0',
  },
  title: {
    margin: 0,
  },
  info: {
    fontSize: '1.4rem',
    lineHeight: '1.1em',
    color: theme.colors.gray500,
  },
  name: {
    display: 'var(--ui-input-store-picker-name-display)',
    order: 'var(--ui-input-store-picker-name-order)',
  },
  address: {
    display: 'var(--ui-input-store-picker-address-display)',
    order: 'var(--ui-input-store-picker-address-order)',
  },
  phone: {
    display: 'var(--ui-input-store-picker-phone-display)',
    order: 'var(--ui-input-store-picker-phone-order)',
  },
  openingHours: {
    display: 'var(--ui-input-store-picker-opening-hours-display)',
    order: 'var(--ui-input-store-picker-opening-hours-order)',
  },
}))

export default useStyles
