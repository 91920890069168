/* eslint-disable no-unused-vars */
import Color from 'color';
import _ from 'lodash'
import tick from '../../assets/icons/icon_tick.svg'

function customStylesOnlyText(themes, dark, borderColor, disabled, isClearable) {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      top: '3.6rem',
      width: '100%',
      border: `1px solid ${themes.colors.text}`,
      borderRadius: 0,
      color: themes.colors.text,
      // padding: '0.8rem 1.6rem',
      fontSize: '1.6rem',
      textAlign: 'left',
      zIndex: 5,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0.8rem 0',
    }),
    control: (__, { selectProps: { width } }) => ({
      width: '100%',
      display: 'flex',
      border: `1px solid ${borderColor}`,
      color: dark ? themes.colors.background : themes.colors.text,
      background: dark ? themes.colors.text : themes.colors.background,
      ...(disabled
        ? {
          color: Color(themes.colors.text).alpha(0.2).string(),
          border: `1px solid ${Color(themes.colors.text).alpha(0.2).string()}`,
          ...disabled,
        }
        : {}),
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: dark ? themes.colors.background : themes.colors.text,
      fontSize: '1.6rem',
    }),
    groupHeading: (provided) => ({
      ...provided,
      // background: themes.colors.primary,
      color: themes.colors.primary,
      fontSize: '1.6rem',
      fontWeight: 600,
      paddingLeft: 0,
      marginTop: '1rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'hidden',
      width: '100%',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: 'none',
      minWidth: 'auto',
      margin: 0,
      '& > div': {
        color: themes.colors.background,
        fontSize: '1.6rem',
        '&:nth-of-type(2)': {
          display: 'none',
        },
      },
      '& ~ [class*="multiValue"]': {
        padding: 'relative',
        '&:before': {
          bottom: 3,
          position: 'absolute',
          margin: '0 .5rem 0 0',
          content: '","',
          fontSize: '1.6rem',
        },
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: isClearable ? 'flex' : 'none',
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      // background: themes.colors.primary,
      padding: '.8rem 1.6rem',
      position: 'relative',
      minHeight: '4.8rem',
      color: themes.colors.text,
      '&:after': {
        position: 'absolute',
        content: '""',
        top: '50%',
        right: 0,
        transform: ' translateY(-50%)',
        width: '3rem',
        height: '1.5rem',
        display: isSelected ? 'inline-block' : 'none',
        backgroundImage: `url(${tick})`,
        backgroundRepeat: 'no-repeat',
      },
    }),
    indicatorSeparator: () => ({}),
    placeholder: () => ({
      fontSize: '1.6rem',
    }),
  }
  return customStyles
}

function customStylesQuantityPicker(themes, dark, borderColor, disabled, isClearable) {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      top: 'calc(100% - 0.6rem)',
      width: 'calc(100% + 1em)',
      border: `1px solid ${themes.colors.text}`,
      borderRadius: 0,
      color: themes.colors.text,
      // padding: '0.8rem 1.6rem',
      fontSize: '1.6rem',
      textAlign: 'left',
      zIndex: 5,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0.8rem 0',
    }),
    control: (__, { selectProps: { width } }) => ({
      // width: '100%',
      minWidth: '2em',
      display: 'inline-flex',
      border: `1px solid ${borderColor}`,
      color: dark ? themes.colors.background : themes.colors.text,
      background: dark ? themes.colors.text : themes.colors.background,
      ...(disabled
        ? {
          color: Color(themes.colors.text).alpha(0.2).string(),
          border: `1px solid ${Color(themes.colors.text).alpha(0.2).string()}`,
          ...disabled,
        }
        : {}),
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: dark ? themes.colors.background : themes.colors.text,
      fontSize: '1.6rem',
      transform: 'none',
      position: 'relative',
      top: 0,
      maxWidth: 'none',
    }),
    groupHeading: (provided) => ({
      ...provided,
      // background: themes.colors.primary,
      color: themes.colors.primary,
      fontSize: '1.6rem',
      fontWeight: 600,
      paddingLeft: 0,
      marginTop: '1rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      overflowX: 'hidden',
      width: '100%',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: 'none',
      minWidth: 'auto',
      margin: 0,
      '& > div': {
        color: themes.colors.background,
        fontSize: '1.6rem',
        '&:nth-of-type(2)': {
          display: 'none',
        },
      },
      '& ~ [class*="multiValue"]': {
        padding: 'relative',
        '&:before': {
          bottom: 3,
          position: 'absolute',
          margin: '0 .5rem 0 0',
          content: '","',
          fontSize: '1.6rem',
        },
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: isClearable ? 'flex' : 'none',
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      // background: themes.colors.primary,
      padding: '.8rem 1.6rem',
      position: 'relative',
      minHeight: '4.8rem',
      color: themes.colors.text,
      '&:after': {
        position: 'absolute',
        content: '""',
        top: '50%',
        right: 0,
        transform: ' translateY(-50%)',
        width: '3rem',
        height: '1.5rem',
        display: isSelected ? 'inline-block' : 'none',
        backgroundImage: `url(${tick})`,
        backgroundRepeat: 'no-repeat',
      },
    }),
    indicatorSeparator: () => ({}),
    placeholder: () => ({
      fontSize: '1.6rem',
    }),
  }
  return customStyles
}

function customStylesLanguageDropdown(themes, dark, borderColor, disabled) {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      top: '3.6rem',
      width: '100%',
      border: `1px solid ${themes.colors.text}`,
      borderRadius: 0,
      color: themes.colors.text,
      // padding: '0.8rem 1.6rem',
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      textAlign: 'left',
      zIndex: 5,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    control: (__, { selectProps: { width } }) => ({
      width: '100%',
      display: 'flex',
      border: `1px solid ${borderColor}`,
      color: dark ? themes.colors.background : themes.colors.text,
      background: dark ? themes.colors.text : themes.colors.background,
      ...(disabled
        ? {
          color: Color(themes.colors.text).alpha(0.2).string(),
          border: `1px solid ${Color(themes.colors.text).alpha(0.2).string()}`,
          ...disabled,
        }
        : {}),
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: dark ? themes.colors.background : themes.colors.text,
      fontSize: '1.4rem',
    }),
    groupHeading: (provided) => ({
      ...provided,
      // background: themes.colors.primary,
      color: themes.colors.primary,
      fontSize: '1.6rem',
      fontWeight: 600,
      paddingLeft: 0,
      marginTop: '1rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'hidden',
      width: '100%',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: 'none',
      minWidth: 'auto',
      margin: 0,
      '& > div': {
        color: themes.colors.background,
        fontSize: '1.4rem',
        '&:nth-of-type(2)': {
          display: 'none',
        },
      },
      '& ~ [class*="multiValue"]': {
        padding: 'relative',
        '&:before': {
          bottom: 3,
          position: 'absolute',
          margin: '0 .5rem 0 0',
          content: '","',
          fontSize: '1.4rem',
        },
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      // background: themes.colors.primary,
      padding: '.8rem 1.6rem',
      position: 'relative',
      minHeight: '4.8rem',
      fontSize: '1.4rem',
      color: themes.colors.text,
      '&:after': {
        position: 'absolute',
        content: '""',
        top: '50%',
        right: 0,
        transform: ' translateY(-50%)',
        width: '3rem',
        height: '1.5rem',
        display: isSelected ? 'inline-block' : 'none',
        backgroundImage: `url(${tick})`,
        backgroundRepeat: 'no-repeat',
      },
    }),
    indicatorSeparator: () => ({}),
    placeholder: () => ({
      fontSize: '1.4rem',
    }),
  }
  return customStyles
}

function customStylesCheckBox(themes, dark) {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      top: '3.6rem',
      border: `1px solid ${themes.colors.text}`,
      borderRadius: 0,
      color: themes.colors.primary,
      padding: '0.8rem 0',
      fontSize: '1.6rem',
      textAlign: 'left',
      width: 1170,
      left: -380,
      zIndex: 9999999,
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: 500,
      display: 'flex',
      flexWrap: ' wrap',
    }),
    control: (__, { selectProps: { width } }) => ({
      width,
      display: 'flex',
      border: `1px solid ${themes.colors.primary}`,
      color: dark ? themes.colors.background : themes.colors.text,
      background: dark ? themes.colors.text : themes.colors.background,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: dark ? themes.colors.background : themes.colors.text,
      fontSize: '1.6rem',
    }),
    group: (provided) => ({
      ...provided,
      width: 230,
    }),
    groupHeading: (provided) => ({
      ...provided,
      // background: themes.colors.text,
      color: themes.colors.primary,
      position: 'relative',
      fontSize: '1.6rem',
      fontWeight: 600,
      width: '100%',
      padding: '0 1.6rem 1rem',
      marginBottom: 0,
      marginTop: '1rem',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: ' translateX(-50%)',
        width: 'calc(100% - 3.2rem)',
        height: 1,
        borderBottom: `1px solid ${themes.colors.text}`,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'hidden',
      width: '100%',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: 'none',
      minWidth: 'auto',
      margin: 0,
      '& > div': {
        color: themes.colors.background,
        fontSize: '1.6rem',
        '&:nth-of-type(2)': {
          display: 'none',
        },
      },
      '& ~ [class*="multiValue"]': {
        padding: 'relative',
        '&:before': {
          bottom: 3,
          position: 'absolute',
          margin: '0 .5rem 0 0',
          content: '","',
          fontSize: '1.6rem',
        },
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      // background: themes.colors.primary,
      padding: '.8rem 1.6rem',
      margin: '0 1.6rem',
      position: 'relative',
      minHeight: '4.8rem',
      width: 'initial',
      color: themes.colors.text,
      '& input[type="checkbox"]': {
        opacity: 0,
      },
      '& label': {
        paddingLeft: '1.3rem',
      },
      '&:before': {
        content: '""',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        border: `1px solid ${themes.colors.text}`,
      },
      '&:after': {
        content: '""',
        display: isSelected ? 'block' : 'none',
        top: '50%',
        left: '2rem',
        transform: 'translateY(-50%)',
        position: 'absolute',
        width: '1.2rem',
        height: '1.2rem',
        background: themes.colors.secondary,
      },
    }),
    indicatorSeparator: () => ({}),
    placeholder: () => ({
      fontSize: '1.6rem',
    }),
  }
  return customStyles
}

function customStylesSizes(themes, dark, borderColor, disabled) {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      top: '3.6rem',
      width: '100%',
      border: `1px solid ${themes.colors.text}`,
      borderRadius: 0,
      color: themes.colors.primary,
      // padding: '0.8rem 1.6rem',
      fontSize: '1.6rem',
      textAlign: 'left',
      zIndex: 5,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0.8rem 0',
    }),
    control: (__, { selectProps: { width } }) => ({
      width: '100%',
      display: 'flex',
      border: `1px solid ${borderColor}`,
      color: dark ? themes.colors.background : themes.colors.text,
      background: dark ? themes.colors.text : themes.colors.background,
      ...(disabled
        ? {
          color: Color(themes.colors.text).alpha(0.2).string(),
          border: `1px solid ${Color(themes.colors.text).alpha(0.2).string()}`,
          ...disabled,
        }
        : {}),
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: dark ? themes.colors.background : themes.colors.text,
      fontSize: '1.6rem',
    }),
    groupHeading: (provided) => ({
      ...provided,
      // background: themes.colors.primary,
      color: themes.colors.primary,
      position: 'relative',
      fontSize: '1.6rem',
      fontWeight: 600,
      width: '100%',
      padding: '0 1.6rem 1rem',
      marginBottom: 0,
      marginTop: '1rem',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: ' translateX(-50%)',
        width: 'calc(100% - 3.2rem)',
        height: 1,
        borderBottom: `1px solid ${themes.colors.text}`,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'hidden',
      width: '100%',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: 'none',
      minWidth: 'auto',
      margin: 0,
      '& > div': {
        color: themes.colors.background,
        fontSize: '1.6rem',
        '&:nth-of-type(2)': {
          display: 'none',
        },
      },
      '& ~ [class*="multiValue"]': {
        padding: 'relative',
        '&:before': {
          bottom: 3,
          position: 'absolute',
          margin: '0 .5rem 0 0',
          content: '","',
          fontSize: '1.6rem',
        },
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      // background: themes.colors.primary,
      padding: '.8rem 1.6rem',
      position: 'relative',
      minHeight: '4.8rem',
      color: themes.colors.text,
      '&:after': {
        position: 'absolute',
        content: '""',
        top: '50%',
        right: 0,
        transform: ' translateY(-50%)',
        width: '3rem',
        height: '1.5rem',
        display: isSelected ? 'inline-block' : 'none',
        backgroundImage: `url(${tick})`,
        backgroundRepeat: 'no-repeat',
      },
    }),
    indicatorSeparator: () => ({}),
    placeholder: () => ({
      fontSize: '1.6rem',
    }),
  }
  return customStyles
}

function customStylesColours(themes, dark) {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      top: '3.6rem',
      width: state.selectProps.width,
      border: `1px solid ${themes.colors.text}`,
      borderRadius: 0,
      color: themes.colors.text,
      padding: '0.8rem 1.6rem',
      fontSize: '1.6rem',
      textAlign: 'left',
      zIndex: 5,
    }),
    control: (__, { selectProps: { width } }) => ({
      width,
      display: 'flex',
      border: `1px solid ${themes.colors.text}`,
      color: dark ? themes.colors.background : themes.colors.text,
      background: dark ? themes.colors.text : themes.colors.background,
    }),
    group: (provided) => ({
      ...provided,
      width: '100%',
      '& div:nth-of-type(2)': {
        display: 'flex',
        flexWrap: 'wrap',
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      // background: themes.colors.primary,
      color: themes.colors.primary,
      fontSize: '1.6rem',
      fontWeight: 600,
      paddingLeft: 0,
      borderBottom: `1px solid ${themes.colors.text}`,
      width: '100%',
      paddingBottom: '1rem',
      marginBottom: '1rem',
      marginTop: '1rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'hidden',
      justifyContent: 'flex-start',
      width: '100%',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: 'none',
      minWidth: 'auto',
      margin: 0,
      '& > div': {
        color: themes.colors.background,
        fontSize: '1.6rem',
        '&:nth-of-type(2)': {
          display: 'none',
        },
      },
      '& ~ [class*="multiValue"]': {
        padding: 'relative',
        '&:before': {
          bottom: 3,
          position: 'absolute',
          margin: '0 .5rem 0 0',
          content: '","',
          fontSize: '1.6rem',
        },
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menuList: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'wrap',
    }),
    option: (provided, { data, isSelected }) => ({
      ...provided,
      display: data.is_active ? 'block' : 'block',
      position: 'relative',
      padding: '.8rem 0',
      width: '2rem',
      margin: '.8rem 1rem .8rem 0',
      '& input[type="checkbox"], & label': {
        opacity: 0,
        position: 'absolute',
      },

      '&:before': {
        content: '""',
        top: '50%',
        left: '50%',
        transform: ' translate(-50%, -50%)',
        position: 'absolute',
        cursor: 'pointer',
        width: '2rem',
        height: '2rem',
        borderRadius: '1rem',
        backgroundImage: `url(${_.get(data, 'image.versions.webThumbPortrait', undefined)})`,
        backgroundColor: data.hex_value,
        border: isSelected ? `2px solid ${themes.colors.secondary}` : `1px solid ${themes.colors.gray300}`,
      },
      '&:hover:before': {
        border: `2px solid ${themes.colors.secondary}`,
      },
    }),
    indicatorSeparator: () => ({}),
    placeholder: () => ({
      fontSize: '1.6rem',
    }),
  }
  return customStyles
}

export {
  customStylesOnlyText,
  customStylesColours,
  customStylesCheckBox,
  customStylesSizes,
  customStylesLanguageDropdown,
  customStylesQuantityPicker,
}
