/* eslint-disable react-hooks/exhaustive-deps */
/**
 * MobileMenuProvider
 * Contain open/close logic for mobile side nav
 */
import React, { useEffect, useState } from 'react'
import MobileMenuContext from './mobile-menu-context'

export default function MobileMenuProvider({ children }) {
  // internal state
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  /**
   * function handleCloseMobileMenu() {
   * close mobile side nav
   */
  function handleCloseMobileMenu(event) {
    setMobileMenuOpen(false)
    stopPropagation(event || window.event)
  }

  /**
   * function handleOpenMobileMenu() {
   * open mobile side nav
   */
  function handleOpenMobileMenu(event) {
    setMobileMenuOpen(true)
    stopPropagation(event || window.event)
  }

  function stopPropagation(event) {
    if (typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    } else {
      // eslint-disable-next-line no-param-reassign
      event.cancelBubble = true;
    }
  }

  function clearUp() {
    document.body.classList.remove('modal-open__mobile-menu')
    document.getElementsByTagName('body')[0].setAttribute('style', '')
  }

  useEffect(() => {
    document.body.classList[mobileMenuOpen ? 'add' : 'remove']('modal-open__mobile-menu')
  }, [mobileMenuOpen])

  // remove modal-open class from body when componentWillUnmount
  useEffect(() => clearUp, [])

  const state = {
    mobileMenuOpen,
    openMobileMenu: handleOpenMobileMenu,
    closeMobileMenu: handleCloseMobileMenu,
    clearUp,
  }

  return (
    <MobileMenuContext.Provider value={state}>
      {children}
    </MobileMenuContext.Provider>
  )
}
