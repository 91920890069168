/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */

import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './dropdown-style'

const DropdownView = ({
  borderColor,
  className,
  closeMenuOnSelect,
  components: componentsOverrides,
  customStyles,
  dark,
  disabled,
  errorMessage,
  isMulti,
  isCheckBox,
  isClearable,
  isColors,
  isSearchable,
  label,
  labelClassName,
  labelRequiredClassName,
  options,
  placeholder,
  noOptionsMessage,
  widthDropdown,
  onChange,
  values,
  name,
  required,
  valueSelect,
  onFetchNextPage,
  loading,
}) => {
  const { t } = useTranslation()
  const themes = useTheme()
  const styles = useStyles({ widthDropdown, disabled })
  const [value, setValue] = useState(undefined)
  useEffect(() => {
    if (values) {
      if (isMulti) {
        let selects = []
        if (_.find(options, 'options')) {
          selects = options.map(
            (option) => option.options.filter((opt) => _.castArray(values).includes(opt.value)),
          )
        } else {
          selects = options.filter((opt) => _.castArray(values).includes(opt.value))
        }
        setValue(_.flatten(selects))
      } else if (_.isNumber(values)) {
        setValue({ label: values.toString(), value: values })
      } else {
        setValue({ label: values.label, value: values.value })
      }
    } else if (isClearable && placeholder && valueSelect) {
      setValue({
        label: placeholder,
        value: valueSelect,
      })
    } else {
      setValue(null)
    }
  }, [values, placeholder, valueSelect, isClearable])

  const defaultPlaceholder = t('ui.dropdown.placeholder')
  const defaultNoOptionsMessage = ({ inputValue }) => (
    <>
      {t('ui.dropdown.noOptionsMessage', { inputValue })}
    </>
  )

  const MenuList = (props) => (
    <components.MenuList {...props}>
      {props.children}
      {loading && <span className={styles.loding}>{t('ui.dropdown.loadingMessage')}</span>}
    </components.MenuList>
  );

  const Option = (props) => {
    if (isCheckBox) {
      return (
        <div>
          <components.Option {...props}>
            <input className={styles.checkbox} type="checkbox" checked={props.isSelected} onChange={() => null} />
            <label className={styles.checkboxLabel}>{props.label}</label>
          </components.Option>
        </div>
      )
    }
    return (
      <components.Option {...props}>
        <label />
      </components.Option>
    )
  }

  return (
    <div className={combineClassNames([styles.dropdown, disabled && styles.dropdownDisabled, className])}>
      {label
        && (
        <label
          className={combineClassNames([
            styles.label,
            required && styles.labelRequired,
            labelClassName,
            required && labelRequiredClassName,
          ])}
        >
          {label}
        </label>
        )}
      <Select
        isDisabled={disabled}
        name={name}
        placeholder={placeholder || defaultPlaceholder}
        noOptionsMessage={noOptionsMessage || defaultNoOptionsMessage}
        className={styles.select}
        options={options}
        width={widthDropdown}
        value={value}
        isClearable={isClearable}
        components={
          {
            ...((isCheckBox || isColors) ? { Option } : { MenuList }),
            ...componentsOverrides,
          }
        }
        onChange={onChange}
        isSearchable={isSearchable}
        styles={_.isFunction(customStyles) ? customStyles(themes, dark, borderColor, disabled, isClearable) : {}}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={false}
        onMenuScrollToBottom={onFetchNextPage}
        menuShouldScrollIntoView
        isLoading={loading}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: themes.colors.light,
            primary: themes.colors.light,
          },
        })}
      />
      {errorMessage && <p className={styles.errorStyle}>{errorMessage}</p>}
    </div>
  )
}

DropdownView.propTypes = {
  widthDropdown: PropTypes.string,
  dark: PropTypes.bool,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  label: PropTypes.string,
  borderColor: PropTypes.string,
  className: PropTypes.string,
}

DropdownView.defaultProps = {
  widthDropdown: '100%',
  dark: false,
  isMulti: false,
  closeMenuOnSelect: true,
  isCheckBox: false,
  label: undefined,
  borderColor: '#282828',
  className: '',
}

export default DropdownView
