import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'var(--ui-coupon-state-label-background-color)',
    padding: '0.4rem 0.6rem',
    borderBottomRightRadius: 6,
    display: 'block',
    transform: 'scale(var(--ui-coupon-state-label-scale))',
    transformOrigin: '0 0',
  },
  labelText: {
    display: 'block',
    fontSize: '1.2rem',
    lineHeight: '1.2em',
    color: 'var(--ui-coupon-state-label-color)',
  },
  labelInactive: {
    backgroundColor: 'var(--ui-coupon-state-label-inactive-background-color)',
  },
  labelTextInactive: {
    color: 'var(--ui-coupon-state-label-inactive-color)',
  },
  labelPending: {
    backgroundColor: 'var(--ui-coupon-state-label-pending-background-color)',
  },
  labelTextPending: {
    color: 'var(--ui-coupon-state-label-pending-color)',
  },
}))

export default useStyles
