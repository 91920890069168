/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { globalHistory } from '@reach/router'
import AlertContext from './alert-context'

/**
 * AlertProvider
 * Contain most logic handing error alert
 */
export default function AlertProvider({ children }) {
  const [alertMessage, setAlertMessage] = useState('')
  const [stateMessage, setStateMessage] = useState('error')
  const alertAutoCloseTimer = useRef(null)

  const remove = () => {
    setAlertMessage('')
  }

  const show = (message = '', options = {}) => {
    if (_.isEmpty(message)) return
    const {
      state = 'error',
      autoClose = false,
    } = options
    if (alertAutoCloseTimer.current) {
      clearTimeout(alertAutoCloseTimer.current)
    }
    setAlertMessage(message)
    setStateMessage(state)
    if (autoClose) {
      const autoCloseDelay = autoClose === true ? 4000 : _.toNumber(autoClose)
      alertAutoCloseTimer.current = setTimeout(remove, autoCloseDelay)
    }
  }

  const isOpen = useMemo(() => !_.isEmpty(alertMessage), [alertMessage])

  // when going to a new page, reset the error message
  useEffect(() => {
    remove()
  }, [globalHistory.location.href])

  const contextValue = {
    isOpen,
    alertMessage,
    stateMessage,
    remove,
    show,
  }

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  )
}
