/* eslint-disable no-nested-ternary */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  button: {
    position: 'relative',
    display: 'block',
    width: '100%',
    minHeight: '5rem',
    padding: [['1rem', '1em']],
    fontSize: 'var(--ui-button-font-size)',
    fontWeight: 'var(--ui-button-font-weight)',
    lineHeight: 1.143,
    textAlign: 'center',
    cursor: 'pointer',
    color: 'var(--ui-button-filled-text-color)',
    backgroundColor: 'var(--ui-button-filled-background-color)',
    borderColor: 'var(--ui-button-filled-border-color)',
    borderWidth: 'var(--ui-button-border-width)',
    borderStyle: 'solid',
    borderRadius: 'var(--ui-button-border-radius)',
    outline: 'none',
  },
  disabled: {
    opacity: 'var(--ui-button-disabled-opacity)',
    cursor: 'not-allowed',
  },
  outline: {
    color: 'var(--ui-button-outline-text-color)',
    backgroundColor: 'var(--ui-button-outline-background-color)',
    borderColor: 'var(--ui-button-outline-border-color)',
  },
  dark: {
    color: 'var(--ui-button-dark-text-color)',
    backgroundColor: 'var(--ui-button-dark-background-color)',
    borderColor: 'var(--ui-button-dark-border-color)',
  },
  light: {
    color: 'var(--ui-button-light-text-color)',
    backgroundColor: 'var(--ui-button-light-background-color)',
    borderColor: 'var(--ui-button-light-border-color)',
  },
  alert: {
    color: 'var(--ui-button-alert-text-color)',
    backgroundColor: 'var(--ui-button-alert-background-color)',
    borderColor: 'var(--ui-button-alert-border-color)',
  },
  facebook: {
    color: 'var(--ui-button-facebook-text-color)',
    backgroundColor: 'var(--ui-button-facebook-background-color)',
    borderColor: 'var(--ui-button-facebook-border-color)',
  },
  inline: {
    font: 'inherit',
    lineHeight: 'inherit',
    display: 'inline-block',
    width: 'auto',
    minHeight: 0,
    padding: 0,
    margin: 0,
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: 'inherit',
  },
  mini: {
    display: 'inline-block',
    height: 0,
    minHeight: '3rem',
    width: 'auto',
    padding: [['0.5rem', '1em']],
    borderWidth: '0.1rem',
  },
  icon: {
    left: 10,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    margin: '0 1rem',
  },
  inlineIcon: {
    position: 'static',
    transform: 'none',
    margin: 0,
    marginLeft: '0.8rem',
  },
}))

export default useStyles
