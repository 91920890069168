import _ from 'lodash'
import React from 'react'
import AddressForm from '../address-form'
import AddressBook from '../address-book'
import AddressDeleteModal from '../address-delete-modal'

function AddressManagerView({
  addresses,
  addressSelectable,
  addressType,
  defaultAddress,
  disableCancel,
  forceDefaultDelivery,
  formFieldsLabelOverride,
  isEdit,
  selectedAddress,
  selectedDeleteAddress,
  selectedEditAddress,
  showAddressBook,
  showAddressForm,
  showDeleteAddressModal,
  onAddAddress,
  onAddress,
  onCancel,
  onClose,
  onDeleteAddress,
  onDeleteAddressModalRequestClose,
  onDeleteAddressModalShow,
  onEditAddress,
  onSelectAddress,
  onSetDefaultDelivery,
  onSubmitSuccess,
}) {
  return (
    <div>
      {
        showAddressBook && (
          <AddressBook
            addresses={addresses}
            addressSelectable={addressSelectable}
            addressType={addressType}
            defaultAddress={defaultAddress}
            selectedAddress={selectedAddress}
            onAddress={onAddress}
            onAddAddress={onAddAddress}
            onDeleteAddressModalShow={onDeleteAddressModalShow}
            onClose={onClose}
            onEditAddress={onEditAddress}
            onSelectAddress={onSelectAddress}
            onSetDefaultDelivery={onSetDefaultDelivery}
          />
        )
      }
      {
        showAddressForm && (
          <AddressForm
            addressType={addressType}
            disableCancel={disableCancel}
            forceDefaultDelivery={forceDefaultDelivery}
            labelsOverride={formFieldsLabelOverride}
            selectedAddress={selectedEditAddress}
            isEdit={isEdit}
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
          />
        )
      }
      <AddressDeleteModal
        isOpen={showDeleteAddressModal}
        isPrimary={_.get(selectedDeleteAddress, 'isPrimary', false)}
        onRequestClose={onDeleteAddressModalRequestClose}
        onConfirm={onDeleteAddress}
      />
    </div>
  )
}

export default AddressManagerView
