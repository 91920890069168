import _ from 'lodash'
import parseThemeStyleUnit from '../../helpers/parse-theme-style-unit'
import getPdpComponentSpacingFromLayoutConfig from '../../helpers/get-pdp-component-spacing-from-layout-config'
import getPdpComponentVisibilityFromLayoutConfig from '../../helpers/get-pdp-component-visibility-from-layout-config'
import ratioCalc from '../../helpers/ratioImage'
import hexToRgba from '../../helpers/hex-to-rgba'
import parseAspectRatio from '../../helpers/parse-aspect-ratio'

const uiHeaderToolsMenuDefaultValue = {
  mobile: ['cart'],
  tabletLandscape: ['language', 'account', 'cart', 'search'],
}
const pagesProductLayoutDefaultValue = {
  mobile: [
    'images',
    'spacer_m',
    'title',
    'spacer_xs',
    'disclaimerContentGroup',
    'price',
    'spacer_xs',
    'customLabels',
    'rating',
    'spacer_m',
    'colorOptions',
    'spacer_m',
    'sizeOptions',
    'addons',
    'spacer_m',
    'quantity',
    'spacer_m',
    'availabilityInStore',
    'spacer_l',
    'actionPanel',
    'spacer_l',
    'whatsappAma',
    'spacer_m',
    'share',
    'spacer_l',
    'attributes',
    'description',
    'contentGroup',
    'recommendations',
    'recentlyViewed',
    'reviews',
  ],
  tablet: [
    ['images', 'title'],
    ['images', 'spacer_m'],
    ['images', 'disclaimerContentGroup'],
    ['images', 'spacer_xs'],
    ['images', 'price'],
    ['images', 'spacer_xs'],
    ['images', 'customLabels'],
    ['images', 'spacer_xs'],
    ['images', 'rating'],
    ['images', 'spacer_l'],
    ['images', 'colorOptions'],
    ['images', 'spacer_l'],
    ['images', 'sizeOptions'],
    ['images', 'spacer_l'],
    ['images', 'addons'],
    ['images', 'spacer_m'],
    ['images', 'quantity'],
    ['images', 'spacer_m'],
    ['images', 'availabilityInStore'],
    ['images', 'spacer_l'],
    ['images', 'actionPanel'],
    ['images', 'spacer_l'],
    ['images', 'whatsappAma'],
    ['images', 'spacer_m'],
    ['images', 'share'],
    ['spacer_xl', 'spacer_xl'],
    ['attributes', 'attributes'],
    ['spacer_l', 'spacer_l'],
    ['description', 'description'],
    ['spacer_l', 'spacer_l'],
    ['contentGroup', 'contentGroup'],
    ['spacer_l', 'spacer_l'],
    ['recommendations', 'recommendations'],
    ['spacer_l', 'spacer_l'],
    ['recentlyViewed', 'recentlyViewed'],
    ['spacer_l', 'spacer_l'],
    ['reviews', 'reviews'],
  ],
}
const uiProductQuickAddModalLayoutDefaultValue = {
  minimum: [
    'images',
    'spacer_s',
    'customLabels',
    'spacer_s',
    'title',
    'spacer_s',
    // 'disclaimerContentGroup',
    'price',
    'spacer_s',
    // 'rating',
    // 'spacer_m',
    'colorOptions',
    'spacer_s',
    'sizeOptions',
    'spacer_s',
    'addons',
    // 'spacer_m',
    // 'availabilityInStore',
    // 'spacer_l',
    // 'whatsappAma',
    'spacer_m',
    // 'share',
    // 'spacer_l',
    'attributes',
    'spacer_m',
    // 'description',
    // 'contentGroup',
    'recommendations',
    'spacer_m',
    'recentlyViewed',
    // 'reviews',
  ],
  mobile: [
    ['images', 'title'],
    ['images', 'spacer_s'],
    ['images', 'customLabels'],
    ['images', 'spacer_s'],
    ['images', 'description'],
    ['images', 'spacer_s'],
    // ['images', 'spacer_s'],
    // ['images', 'disclaimerContentGroup'],
    ['images', 'price'],
    ['spacer_m', 'spacer_m'],
    ['colorOptions', 'colorOptions'],
    ['spacer_m', 'spacer_m'],
    ['sizeOptions', 'sizeOptions'],
    ['spacer_m', 'spacer_m'],
    ['addons', 'addons'],
    ['spacer_m', 'spacer_m'],
    ['attributes', 'attributes'],
    ['spacer_m', 'spacer_m'],
    ['recommendations', 'recommendations'],
    ['spacer_m', 'spacer_m'],
    ['recentlyViewed', 'recentlyViewed'],
    // ['contentGroup', 'contentGroup'],
  ],
}
const getThemeConfigStylesRegistrationList = (mergedTheme) => ([
  // ## ui ##
  {
    key: 'colors.background',
    styleProperty: '--ui-body-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'colors.innerBackground',
    styleProperty: '--ui-body-inner-background-color',
    defaultValue: 'transparent',
  },
  // -- Spacers --
  // spacer xs = '0.6rem'
  // spacer s = '1rem'
  // spacer m = '1.6rem'
  // spacer l = '3rem'
  // spacer xl = '6rem'
  {
    key: 'config.ui.spacer.xs',
    styleProperty: '--ui-spacer-xs',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '0.6rem',
  },
  {
    key: 'config.ui.spacer.s',
    styleProperty: '--ui-spacer-s',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '1rem',
  },
  {
    key: 'config.ui.spacer.m',
    styleProperty: '--ui-spacer-m',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '1.6rem',
  },
  {
    key: 'config.ui.spacer.l',
    styleProperty: '--ui-spacer-l',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '3rem',
  },
  {
    key: 'config.ui.spacer.xl',
    styleProperty: '--ui-spacer-xl',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '6rem',
  },
  // -- Rounded Corners --
  // radius xs = '0.1rem'
  // radius s = '0.2rem'
  // radius m = '0.4rem'
  // radius l = '0.8rem'
  // radius xl = '1.6rem'
  // radius xxl = '2rem'
  {
    key: 'config.ui.roundedCorner.xs',
    styleProperty: '--ui-radius-xs',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '0.1rem',
  },
  {
    key: 'config.ui.roundedCorner.s',
    styleProperty: '--ui-radius-s',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '0.2rem',
  },
  {
    key: 'config.ui.roundedCorner.m',
    styleProperty: '--ui-radius-m',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '0.4rem',
  },
  {
    key: 'config.ui.roundedCorner.l',
    styleProperty: '--ui-radius-l',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '0.8rem',
  },
  {
    key: 'config.ui.roundedCorner.xl',
    styleProperty: '--ui-radius-xl',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '1.6rem',
  },
  {
    key: 'config.ui.roundedCorner.xxl',
    styleProperty: '--ui-radius-xxl',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '2rem',
  },
  // -- alert --
  {
    key: 'config.ui.alert.default.position',
    styleProperty: '--ui-alert-default-left',
    getValueFn: (value) => {
      if (!_.isBoolean(value)) return value
      return value ? 'sticky' : 'static'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.alert.default.backgroundColor',
    styleProperty: '--ui-alert-default-background-color',
    defaultValue: hexToRgba(mergedTheme.colors.overlay, 0.9),
  },
  {
    key: 'config.ui.alert.default.textColor',
    styleProperty: '--ui-alert-default-text-color',
    defaultValue: mergedTheme.colors.textInvert,
  },
  {
    key: 'config.ui.alert.default.borderColor',
    styleProperty: '--ui-alert-default-border-color',
    defaultValue: mergedTheme.colors.overlay,
  },
  {
    key: 'config.ui.alert.default.borderWidth',
    styleProperty: '--ui-alert-default-border-width',
    defaultValue: 0,
  },
  {
    key: 'config.ui.alert.default.roundedCorners',
    styleProperty: '--ui-alert-default-border-radius',
    defaultValue: '0.8rem',
  },
  {
    key: 'config.ui.alert.error.backgroundColor',
    styleProperty: '--ui-alert-error-background-color',
    defaultValue: hexToRgba(mergedTheme.colors.alert, 0.9),
  },
  {
    key: 'config.ui.alert.error.textColor',
    styleProperty: '--ui-alert-error-text-color',
    defaultValue: mergedTheme.colors.textInvert,
  },
  {
    key: 'config.ui.alert.error.borderColor',
    styleProperty: '--ui-alert-error-border-color',
    defaultValue: mergedTheme.colors.alert,
  },
  {
    key: 'config.ui.alert.error.borderWidth',
    styleProperty: '--ui-alert-error-border-width',
    defaultValue: 'var(--ui-alert-default-border-width)',
  },
  {
    key: 'config.ui.alert.error.roundedCorners',
    styleProperty: '--ui-alert-error-border-radius',
    defaultValue: 'var(--ui-alert-default-border-radius)',
  },
  {
    key: 'config.ui.alert.success.backgroundColor',
    styleProperty: '--ui-alert-success-background-color',
    defaultValue: hexToRgba(mergedTheme.colors.success, 0.9),
  },
  {
    key: 'config.ui.alert.success.textColor',
    styleProperty: '--ui-alert-success-text-color',
    defaultValue: mergedTheme.colors.text,
  },
  {
    key: 'config.ui.alert.success.borderColor',
    styleProperty: '--ui-alert-success-border-color',
    defaultValue: mergedTheme.colors.success,
  },
  {
    key: 'config.ui.alert.success.borderWidth',
    styleProperty: '--ui-alert-success-border-width',
    defaultValue: 'var(--ui-alert-default-border-width)',
  },
  {
    key: 'config.ui.alert.success.roundedCorners',
    styleProperty: '--ui-alert-success-border-radius',
    defaultValue: 'var(--ui-alert-default-border-radius)',
  },
  // -- button --
  {
    key: 'colors.ui.button.darkColor',
    styleProperty: '--ui-button-dark-color',
    defaultValue: mergedTheme.colors.dark,
  },
  {
    key: 'colors.ui.button.lightColor',
    styleProperty: '--ui-button-light-color',
    defaultValue: mergedTheme.colors.light,
  },
  {
    key: 'config.ui.button.dark.borderColor',
    styleProperty: '--ui-button-dark-border-color',
    defaultValue: 'var(--ui-button-dark-color)',
  },
  {
    key: 'config.ui.button.dark.backgroundColor',
    styleProperty: '--ui-button-dark-background-color',
    defaultValue: 'var(--ui-button-dark-color)',
  },
  {
    key: 'config.ui.button.dark.textColor',
    styleProperty: '--ui-button-dark-text-color',
    defaultValue: 'var(--ui-button-light-color)',
  },
  {
    key: 'config.ui.button.light.borderColor',
    styleProperty: '--ui-button-light-border-color',
    defaultValue: 'var(--ui-button-light-color)',
  },
  {
    key: 'config.ui.button.light.backgroundColor',
    styleProperty: '--ui-button-light-background-color',
    defaultValue: 'var(--ui-button-light-color)',
  },
  {
    key: 'config.ui.button.light.textColor',
    styleProperty: '--ui-button-light-text-color',
    defaultValue: 'var(--ui-button-dark-color)',
  },
  {
    key: 'config.ui.button.outline.borderColor',
    styleProperty: '--ui-button-outline-border-color',
    defaultValue: 'var(--ui-button-dark-color)',
  },
  {
    key: 'config.ui.button.outline.backgroundColor',
    styleProperty: '--ui-button-outline-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.button.outline.textColor',
    styleProperty: '--ui-button-outline-text-color',
    defaultValue: 'var(--ui-button-dark-color)',
  },
  {
    key: 'config.ui.button.filled.borderColor',
    styleProperty: '--ui-button-filled-border-color',
    defaultValue: mergedTheme.colors.secondary,
  },
  {
    key: 'config.ui.button.filled.backgroundColor',
    styleProperty: '--ui-button-filled-background-color',
    defaultValue: mergedTheme.colors.secondary,
  },
  {
    key: 'config.ui.button.filled.textColor',
    styleProperty: '--ui-button-filled-text-color',
    defaultValue: 'var(--ui-button-light-color)',
  },
  {
    key: 'config.ui.button.alert.borderColor',
    styleProperty: '--ui-button-alert-border-color',
    defaultValue: mergedTheme.colors.alert,
  },
  {
    key: 'config.ui.button.alert.backgroundColor',
    styleProperty: '--ui-button-alert-background-color',
    defaultValue: mergedTheme.colors.alert,
  },
  {
    key: 'config.ui.button.alert.textColor',
    styleProperty: '--ui-button-alert-text-color',
    defaultValue: 'var(--ui-button-light-color)',
  },
  {
    key: 'config.ui.button.facebook.borderColor',
    styleProperty: '--ui-button-facebook-border-color',
    defaultValue: mergedTheme.colors.facebook,
  },
  {
    key: 'config.ui.button.facebook.backgroundColor',
    styleProperty: '--ui-button-facebook-background-color',
    defaultValue: mergedTheme.colors.facebook,
  },
  {
    key: 'config.ui.button.facebook.textColor',
    styleProperty: '--ui-button-facebook-text-color',
    defaultValue: 'var(--ui-button-light-color)',
  },
  {
    key: 'config.ui.button.disabled.opacity',
    styleProperty: '--ui-button-disabled-opacity',
    defaultValue: 0.2,
  },
  {
    key: 'config.ui.button.cornerRadius',
    styleProperty: '--ui-button-border-radius',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-radius-m)',
  },
  {
    key: 'config.ui.button.borderWidth',
    styleProperty: '--ui-button-border-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 2,
  },
  {
    key: 'config.ui.button.fontSize',
    styleProperty: '--ui-button-font-size',
    getValueFn: parseThemeStyleUnit,
    defaultValue: '1.6rem',
  },
  {
    key: 'config.ui.button.fontWeight',
    styleProperty: '--ui-button-font-weight',
    defaultValue: '600',
  },

  // -- clock --
  {
    key: 'config.ui.clock.textColor',
    styleProperty: '--ui-clock-text-color',
    defaultValue: mergedTheme.colors.alert,
  },
  // -- header --
  {
    key: 'config.ui.header.enableSticky',
    styleProperty: '--ui-header-position',
    getValueFn: (value) => {
      if (!_.isBoolean(value)) return value
      return value ? 'sticky' : 'static'
    },
    defaultValue: {
      mobile: true,
      tabletLandscape: false,
    },
  },
  {
    key: [
      'config.headerBackgroundColor',
      'colors.background',
    ],
    styleProperty: '--ui-header-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.headerBorderWidth',
    styleProperty: '--ui-header-border-bottom-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 1,
      tabletLandscape: 0,
    },
  },
  {
    key: 'config.headerBorderStyle',
    styleProperty: '--ui-header-border-bottom-style',
    defaultValue: 'solid',
  },
  {
    key: 'config.headerBorderColor',
    styleProperty: '--ui-header-border-bottom-color',
    defaultValue: mergedTheme.colors.gray300,
  },
  {
    key: 'config.headerTopSpacing',
    styleProperty: '--ui-header-top-spacing',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 0,
      tabletLandscape: '3.3rem',
    },
  },
  {
    key: 'config.headerBottomSpacing',
    styleProperty: '--ui-header-bottom-spacing',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 0,
      tabletLandscape: '2.7rem',
    },
  },
  // -- header - logo --
  {
    key: 'config.headerLogoWidth',
    getValueFn: parseThemeStyleUnit,
    styleProperty: '--ui-header-logo-width',
    defaultValue: {
      mobile: '10rem',
      tabletLandscape: '20rem',
    },
  },
  {
    key: 'config.headerCheckoutLogoWidth',
    getValueFn: parseThemeStyleUnit,
    styleProperty: '--ui-header-checkout-logo-width',
    defaultValue: 'var(--ui-header-logo-width)',
  },
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-logo-container-justify-content',
    getValueFn: (pos) => {
      switch (pos) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        default:
          return 'center';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  // -- header - tools menu --
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-tools-menu-position',
    getValueFn: (pos) => {
      switch (pos) {
        case 'right':
          return 'static';
        default:
          return 'absolute';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  {
    key: 'config.headerToolsMenuPosition',
    styleProperty: '--ui-header-tools-menu-top',
    getValueFn: (pos) => {
      switch (pos) {
        case 'bottom':
          return 'auto';
        default:
          return 0;
      }
    },
    defaultValue: {
      mobile: 'center',
      tabletLandscape: 'bottom',
    },
  },
  {
    key: 'config.headerToolsMenuPosition',
    styleProperty: '--ui-header-tools-menu-bottom',
    getValueFn: (pos) => {
      switch (pos) {
        case 'top':
          return 'auto';
        default:
          return 0;
      }
    },
    defaultValue: {
      mobile: 'center',
      tabletLandscape: 'bottom',
    },
  },
  ..._.flatMap(
    ['cart', 'language', 'account', 'search', 'stores'],
    (item) => ([
      {
        key: 'config.headerToolsMenu',
        styleProperty: `--ui-header-tools-menu-item-${_.kebabCase(item)}-display`,
        getValueFn: (menu) => (_.includes(menu, item) ? 'inline-flex' : 'none'),
        defaultValue: uiHeaderToolsMenuDefaultValue,
      },
      {
        key: 'config.headerToolsMenu',
        styleProperty: `--ui-header-tools-menu-item-${_.kebabCase(item)}-order`,
        getValueFn: (menu) => _.indexOf(menu, item),
        defaultValue: uiHeaderToolsMenuDefaultValue,
      },
    ]),
  ),
  {
    key: 'config.headerToolsMenu',
    styleProperty: '--ui-header-tools-menu-item-account-dropdown-transform',
    getValueFn: (menu) => {
      if (!_.includes(menu, 'account')) return 'none'
      const rightSideItemCount = _.size(menu) - _.indexOf(menu, 'account') - 1
      if (rightSideItemCount > 1) {
        return 'translateX(-20%)'
      }
      return 'translateX(-70%)'
    },
    defaultValue: uiHeaderToolsMenuDefaultValue,
  },
  // -- header - burger menu --
  {
    key: 'config.headerBurgerMenuEnabled',
    styleProperty: '--ui-header-burger-menu-display',
    getValueFn: (enabled) => (enabled ? 'flex' : 'none'),
    defaultValue: {
      mobile: true,
      tabletLandscape: false,
    },
  },
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-burger-menu-position',
    getValueFn: (pos) => {
      switch (pos) {
        case 'left':
          return 'static';
        default:
          return 'absolute';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  {
    key: 'config.headerBurgerMenuLabelEnabled',
    getValueFn: (enabled) => (enabled ? 'block' : 'none'),
    styleProperty: '--ui-header-burger-menu-label-display',
    defaultValue: {
      mobile: false,
      tabletLandscape: true,
    },
  },
  // -- header - mega menu --
  {
    key: 'config.headerMegaMenuBackgroundColor',
    styleProperty: '--ui-header-mega-menu-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.headerMegaMenuEnabled',
    styleProperty: '--ui-header-mega-menu-display',
    getValueFn: (enabled) => (enabled ? 'block' : 'none'),
    defaultValue: {
      mobile: false,
      tabletLandscape: true,
    },
  },
  {
    key: 'config.headerMegaMenuBottomSpacing',
    styleProperty: '--ui-header-mega-menu-bottom-spacing',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 0,
      tabletLandscape: 0,
    },
  },
  {
    key: 'config.headerMegaMenuTopSpacing',
    styleProperty: '--ui-header-mega-menu-top-spacing',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 0,
      tabletLandscape: 0,
    },
  },
  {
    key: 'config.headerMegaMenuItemIndicatorStyle',
    styleProperty: '--ui-header-mega-menu-item-indicator-top',
    getValueFn: (value) => {
      switch (value) {
        case 'background':
          return 0
        case 'tab':
          return '0.2rem'
        default:
          return 'calc(100% - 0.2rem)'
      }
    },
    defaultValue: 'bottomLine',
  },
  {
    key: 'config.headerMegaMenuItemIndicatorStyle',
    styleProperty: '--ui-header-mega-menu-item-indicator-left',
    getValueFn: (value) => {
      switch (value) {
        case 'background':
        case 'tab':
          return '-1em'
        default:
          return 0
      }
    },
    defaultValue: 'bottomLine',
  },
  {
    key: 'config.headerMegaMenuItemIndicatorStyle',
    styleProperty: '--ui-header-mega-menu-item-indicator-right',
    getValueFn: (value) => {
      switch (value) {
        case 'background':
        case 'tab':
          return '-1em'
        default:
          return 0
      }
    },
    defaultValue: 'bottomLine',
  },
  {
    key: 'config.headerMegaMenuItemTextFontWeight',
    styleProperty: '--ui-header-mega-menu-item-font-weight',
    defaultValue: 'normal',
  },
  {
    key: [
      'config.headerMegaMenuItemTextColor',
      'config.headerColorText',
      'colors.primary',
    ],
    styleProperty: '--ui-header-mega-menu-item-text-color',
    defaultValue: 'currentColor',
  },
  {
    key: [
      'config.headerMegaMenuItemActiveColor',
      'colors.hover',
    ],
    styleProperty: '--ui-header-mega-menu-item-active-color',
    defaultValue: 'currentColor',
  },
  {
    key: [
      'config.headerMegaMenuItemActiveTextColor',
      'config.headerMegaMenuItemActiveColor',
      'colors.hover',
    ],
    styleProperty: '--ui-header-mega-menu-item-active-text-color',
    defaultValue: 'currentColor',
  },
  // -- header - Order Method --
  {
    key: 'config.headerOrderMethodBorderWidth',
    styleProperty: '--ui-header-order-method-border-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 1,
      tabletLandscape: 0,
    },
  },
  {
    key: 'config.headerOrderMethodBorderStyle',
    styleProperty: '--ui-header-order-method-border-style',
    defaultValue: 'solid',
  },
  {
    key: 'config.headerOrderMethodBorderColor',
    styleProperty: '--ui-header-order-method-border-color',
    defaultValue: mergedTheme.colors.gray300,
  },
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-order-method-below-logo-display',
    getValueFn: (pos, mq) => {
      if (mq === 'mobile') {
        return 'block'
      }
      switch (pos) {
        case 'left':
        case 'right':
          return 'none';
        default:
          return 'block';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-order-method-near-logo-display',
    getValueFn: (pos, mq) => {
      if (mq === 'mobile') {
        return 'none'
      }
      switch (pos) {
        case 'left':
        case 'right':
          return 'block';
        default:
          return 'none';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  {
    key: 'config.headerOrderMethodPosition',
    styleProperty: '--ui-header-order-method-below-logo-row-justify-content',
    getValueFn: (pos) => {
      switch (pos) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        default:
          return 'center';
      }
    },
    defaultValue: {
      mobile: 'left',
      tabletLandscape: 'center',
    },
  },
  // -- input store picker --
  ..._.flatMap(
    ['name', 'address', 'phone', 'openingHours'],
    (item) => ([
      {
        key: 'config.ui.inputStorePicker.options.contents',
        styleProperty: `--ui-input-store-picker-${_.kebabCase(item)}-display`,
        getValueFn: (menu) => (_.includes(menu, item) ? 'block' : 'none'),
        defaultValue: ['name', 'address', 'openingHours'],
      },
      {
        key: 'config.ui.inputStorePicker.options.contents',
        styleProperty: `--ui-input-store-picker-${_.kebabCase(item)}-order`,
        getValueFn: (menu) => _.indexOf(menu, item),
        defaultValue: ['name', 'address', 'openingHours'],
      },
    ]),
  ),
  // -- sidebar menu --
  {
    key: 'config.ui.sidebarMenu.loginRegisterButtonsEnabled',
    styleProperty: '--ui-sidebar-menu-account-buttons-display',
    getValueFn: (enabled) => (enabled ? 'flex' : 'none'),
    defaultValue: true,
  },
  {
    key: ['config.ui.sidebarMenu.searchEnabled', 'config.sidebarMenuSearchEnabled'],
    styleProperty: '--ui-sidebar-menu-search-display',
    getValueFn: (enabled) => (enabled ? 'block' : 'none'),
    defaultValue: {
      mobile: true,
      tabletLandscape: false,
    },
  },
  // -- product list --
  {
    key: 'config.ui.productList.numberOfColumns',
    styleProperty: '--ui-product-list-item-width',
    getValueFn: (value, mq) => {
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      const gapWidth = _.includes(['mobile'], mq) || _.isNil(mq) ? 1.6 : 3
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((gapWidth * numGaps) / numCol, 2)}rem)`
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: {
      mobile: 2,
      tablet: 4,
    },
  },
  {
    key: 'config.ui.productList.gap',
    styleProperty: '--ui-product-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 16,
      tablet: 30,
    },
  },
  // -- product card --
  {
    key: 'config.ratioPlp',
    styleProperty: '--ui-product-card-image-padding-bottom',
    getValueFn: ratioCalc,
  },
  {
    key: 'config.ratioPlp',
    styleProperty: '--ui-product-card-image-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: 'auto',
  },
  {
    key: 'config.ui.productCard.thumbnail.roundedCorners',
    styleProperty: '--ui-product-card-image-border-radius',
    getValueFn: (value) => {
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productCard.price.position',
    styleProperty: '--ui-product-card-price-container-justify-content',
    getValueFn: (value) => {
      switch (value) {
        case 'bottom':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    },
    defaultValue: 'auto',
  },
  {
    key: 'config.ui.productCard.price.fontSize',
    styleProperty: '--ui-product-card-price-font-size',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 16,
      tablet: 18,
    },
  },
  {
    key: ['config.ratioPlpSwatch', 'config.ratioThumbnail'],
    styleProperty: '--ui-product-card-swatch-image-padding-bottom',
    getValueFn: ratioCalc,
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-flex-direction',
    getValueFn: (value) => {
      if (value === 'left') {
        return 'row'
      }
      if (value === 'right') {
        return 'row-reverse'
      }
      return 'column'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-thumbnail-width',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return '36%'
      }
      return 'auto'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-thumbnail-max-width',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return '12rem'
      }
      return 'none'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-content-padding-default',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 0
      }
      return '1rem 1rem 2rem'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-content-text-align-default',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 'left'
      }
      return 'center'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-content-align-items-default',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 'flex-start'
      }
      return 'center'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-custom-labels-justify-content-default',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 'flex-start'
      }
      return 'center'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-price-list-justify-content-default',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 'flex-start'
      }
      return 'center'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.contentAlign',
    styleProperty: '--ui-product-card-content-text-align',
    defaultValue: 'var(--ui-product-card-content-text-align-default)',
  },
  {
    key: 'config.ui.productCard.contentAlign',
    styleProperty: '--ui-product-card-content-padding',
    getValueFn: (value) => {
      if (_.startsWith(value, 'var(--')) {
        return value
      }
      if (value === 'left') {
        return '1rem 1rem 2rem 0'
      }
      if (value === 'right') {
        return '1rem 0 2rem 1rem'
      }
      return '1rem 1rem 2rem'
    },
    defaultValue: 'var(--ui-product-card-content-padding-default)',
  },
  {
    key: 'config.ui.productCard.contentAlign',
    styleProperty: '--ui-product-card-content-align-items',
    getValueFn: (value) => {
      if (_.startsWith(value, 'var(--')) {
        return value
      }
      if (value === 'left') {
        return 'flex-start'
      }
      if (value === 'right') {
        return 'flex-end'
      }
      return 'center'
    },
    defaultValue: 'var(--ui-product-card-content-align-items-default)',
  },
  {
    key: 'config.ui.productCard.contentAlign',
    styleProperty: '--ui-product-card-custom-labels-justify-content',
    getValueFn: (value) => {
      if (_.startsWith(value, 'var(--')) {
        return value
      }
      if (value === 'left') {
        return 'flex-start'
      }
      if (value === 'right') {
        return 'flex-end'
      }
      return 'center'
    },
    defaultValue: 'var(--ui-product-card-custom-labels-justify-content-default)',
  },
  {
    key: 'config.ui.productCard.contentAlign',
    styleProperty: '--ui-product-card-price-list-justify-content',
    getValueFn: (value) => {
      if (_.startsWith(value, 'var(--')) {
        return value
      }
      if (value === 'left') {
        return 'flex-start'
      }
      if (value === 'right') {
        return 'flex-end'
      }
      return 'center'
    },
    defaultValue: 'var(--ui-product-card-price-list-justify-content-default)',
  },
  {
    key: 'config.ui.productCard.padding',
    styleProperty: '--ui-product-card-padding',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  {
    key: 'colors.ui.productCard.backgroundColor',
    styleProperty: '--ui-product-card-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.productCard.roundedCorners',
    styleProperty: '--ui-product-card-border-radius',
    getValueFn: (value) => {
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productCard.thumbnail.roundedCorners',
    styleProperty: '--ui-product-card-image-border-radius',
    getValueFn: (value) => {
      if (_.isNil(value)) {
        return 'var(-ui-product-card-image-border-radius)'
      }
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productCard.price.position',
    styleProperty: '--ui-product-card-price-container-justify-content',
    getValueFn: (value) => {
      if (_.isEmpty(value)) return 'var(--ui-product-card-price-container-justify-content)'
      switch (value) {
        case 'bottom':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    },
  },
  {
    key: 'config.ui.productCard.enableDetails',
    styleProperty: '--ui-product-card-content-details-display',
    getValueFn: (value) => {
      if (_.isBoolean(value)) {
        return value ? '-webkit-box' : 'none'
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.favouriteButton.enableBackground',
    styleProperty: '--ui-favourite-button-background-display',
    getValueFn: (value) => {
      if (_.isBoolean(value)) return value ? 'block' : 'none'
      return value
    },
    defaultValue: false,
  },
  // -- product group list --
  {
    key: 'config.ui.productGroup.list.gap',
    styleProperty: '--ui-product-group-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-product-list-gap)',
  },
  {
    key: 'config.ui.productGroup.numberOfColumns',
    styleProperty: '--ui-product-group-item-width',
    getValueFn: (value, mq) => {
      if (_.isString(value) && _.startsWith(value, 'var(--')) return value
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      const gapWidth = _.includes(['mobile'], mq) || _.isNil(mq) ? 1.6 : 3
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((gapWidth * numGaps) / numCol, 2)}rem)`
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: 'var(--ui-product-list-item-width)',
  },

  // -- product quick add modal content --
  {
    key: 'config.ui.productQuickAddModal.paddingHorizontal',
    styleProperty: '--ui-product-quick-add-modal-padding-left',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-spacer-m)',
  },
  {
    key: 'config.ui.productQuickAddModal.paddingHorizontal',
    styleProperty: '--ui-product-quick-add-modal-padding-right',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-spacer-m)',
  },
  {
    key: 'config.ui.productQuickAddModal.columnGap',
    styleProperty: '--ui-product-quick-add-modal-column-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-spacer-m)',
  },
  {
    key: 'config.ui.productQuickAddModal.columns',
    styleProperty: '--ui-product-quick-add-modal-grid-template-columns',
    getValueFn: (value) => {
      if (_.isArray(value)) {
        // e.g. [200, '1fr'] -> "20rem 1fr"
        return _.join(_.map(value, parseThemeStyleUnit), ' ')
      }
      return value
    },
    defaultValue: {
      minimum: '100%',
      mobile: '33.33% 1fr',
    },
  },
  {
    key: 'config.ui.productQuickAddModal.layout',
    styleProperty: '--ui-product-quick-add-modal-grid-template-areas',
    getValueFn: (value) => {
      if (_.isArray(value)) {
        // e.g. ['images', 'spacer_m', 'title', 'price', ...] -> "images" "title" "price"
        // e.g. [['images', 'title'], ['images', 'spacer_m'], ['images', 'price'], ...] ->
        // `
        //  "images title"
        //  "images price"
        //  ...
        // `
        return _.join(
          _.compact(_.map(value, (row) => {
            const rowItems = _.castArray(row)
            // skip spacers
            if (_.some(rowItems, (item) => _.startsWith(item, 'spacer'))) {
              return null
            }
            return `"${_.join(rowItems, ' ')}"`
          })),
          '\n',
        )
      }
      return false
    },
    defaultValue: uiProductQuickAddModalLayoutDefaultValue,
  },
  {
    key: 'config.ui.productQuickAddModal.layout',
    styleProperty: '--ui-product-quick-add-modal-grid-template-rows',
    getValueFn: (value) => {
      // const defaultRowValue = 'minmax(0, min-content)'
      const defaultRowValue = 'auto'
      if (_.isArray(value)) {
        const numberOfColumns = _.max(_.map(value, (row) => _.size(_.castArray(row))))
        let result = []
        _.forEach(value, (row, index) => {
          const prevRow = index > 0 ? _.nth(value, index - 1) : []
          const nextRow = _.nth(value, index + 1) || []
          const rowItems = _.castArray(row)
          const spanItems = _.map(
            rowItems,
            (item, rowIdx) => (
              _.isEqual(_.nth(prevRow, rowIdx), item)
                ? item
                : null
            ),
          )
          // Make the last row of span items more space
          if (
            !_.every(spanItems, _.isNil)
            && !_.every(_.difference(spanItems, nextRow), _.isNil)
            && numberOfColumns > 1
          ) {
            result = [
              ...result,
              '1fr',
            ]
          } else if (
            _.some(rowItems, (item) => _.startsWith(item, 'spacer'))
          ) {
            // skip spacers
          } else {
            result = [
              ...result,
              defaultRowValue,
            ]
          }
        })
        return _.join(result, ' ')
      }
      return defaultRowValue
    },
    defaultValue: uiProductQuickAddModalLayoutDefaultValue,
  },
  ..._.flatMap(
    [
      'title',
      'description',
      'price',
      'customLabels',
      'images',
      'colorOptions',
      'sizeOptions',
      'addons',
      'attributes',
      'recentlyViewed',
      'recommendations',
    ],
    (component) => ([
      {
        key: 'config.ui.productQuickAddModal.layout',
        styleProperty: `--ui-product-quick-add-modal-${_.kebabCase(component)}-display`,
        getValueFn: (value) => (
          getPdpComponentVisibilityFromLayoutConfig({
            layout: value,
            component,
          })
        ),
        defaultValue: uiProductQuickAddModalLayoutDefaultValue,
      },
      {
        key: 'config.ui.productQuickAddModal.layout',
        styleProperty: `--ui-product-quick-add-modal-${_.kebabCase(component)}-margin-top`,
        getValueFn: (value) => {
          const spacer = getPdpComponentSpacingFromLayoutConfig({
            layout: value,
            component,
            direction: 'top',
          })
          if (spacer) {
            return `var(--ui-${_.kebabCase(spacer)})`
          }
          return '0rem'
        },
        defaultValue: uiProductQuickAddModalLayoutDefaultValue,
      },
      {
        key: 'config.ui.productQuickAddModal.layout',
        styleProperty: `--ui-product-quick-add-modal-${_.kebabCase(component)}-margin-bottom`,
        getValueFn: (value) => {
          const spacer = getPdpComponentSpacingFromLayoutConfig({
            layout: value,
            component,
            direction: 'bottom',
          })
          if (spacer) {
            return `var(--ui-${_.kebabCase(spacer)})`
          }
          return '0rem'
        },
        defaultValue: uiProductQuickAddModalLayoutDefaultValue,
      },
    ]),
  ),
  // description
  {
    key: 'config.ui.productQuickAddModal.description.title.visible',
    styleProperty: '--ui-product-quick-add-modal-description-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: false,
  },
  // recentlyViewed
  {
    key: 'config.ui.productQuickAddModal.recentlyViewed.title.visible',
    styleProperty: '--ui-product-quick-add-modal-recently-viewed-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: true,
  },
  // recommendations
  {
    key: 'config.ui.productQuickAddModal.recommendations.title.visible',
    styleProperty: '--ui-product-quick-add-modal-recommendations-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: true,
  },
  // attributes
  {
    key: 'config.ui.productQuickAddModal.attributes.title.visible',
    styleProperty: '--ui-product-quick-add-modal-attributes-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: true,
  },
  {
    key: ['config.ui.productQuickAddModal.thumbnail.ratio', 'config.ratioPdp'],
    styleProperty: '--ui-product-quick-add-modal-image-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: 'auto',
  },
  {
    key: 'config.ui.productQuickAddModal.thumbnail.roundedCorners',
    styleProperty: '--ui-product-quick-add-modal-image-border-radius',
    getValueFn: (value) => {
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productQuickAddModal.price.position',
    styleProperty: '--ui-product-quick-add-modal-price-container-justify-content',
    getValueFn: (value) => {
      switch (value) {
        case 'bottom':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    },
    defaultValue: 'auto',
  },
  {
    key: 'config.ui.productQuickAddModal.price.fontSize',
    styleProperty: '--ui-product-quick-add-modal-price-font-size',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 16,
      tablet: 18,
    },
  },
  {
    key: 'config.ui.productQuickAddModal.content.align',
    styleProperty: '--ui-product-quick-add-modal-content-text-align',
    defaultValue: 'left',
  },
  {
    key: 'config.ui.productQuickAddModal.content.align',
    styleProperty: '--ui-product-quick-add-modal-content-align-items',
    getValueFn: (value) => {
      if (value === 'left') {
        return 'flex-start'
      }
      if (value === 'right') {
        return 'flex-end'
      }
      return 'center'
    },
    defaultValue: 'left',
  },
  {
    key: 'config.ui.productQuickAddModal.content.align',
    styleProperty: '--ui-product-quick-add-modal-custom-labels-justify-content',
    getValueFn: (value) => {
      if (value === 'left') {
        return 'flex-start'
      }
      if (value === 'right') {
        return 'flex-end'
      }
      return 'center'
    },
    defaultValue: 'left',
  },
  {
    key: 'config.ui.productQuickAddModal.content.align',
    styleProperty: '--ui-product-quick-add-modal-price-list-justify-content',
    getValueFn: (value) => {
      if (value === 'left') {
        return 'flex-start'
      }
      if (value === 'right') {
        return 'flex-end'
      }
      return 'center'
    },
    defaultValue: 'left',
  },
  {
    key: 'config.ui.productQuickAddModal.padding',
    styleProperty: '--ui-product-quick-add-modal-padding',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  {
    key: 'config.ui.productQuickAddModal.roundedCorners',
    styleProperty: '--ui-product-quick-add-modal-border-radius',
    getValueFn: (value) => {
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productQuickAddModal.thumbnail.roundedCorners',
    styleProperty: '--ui-product-quick-add-modal-image-border-radius',
    getValueFn: (value) => {
      if (_.isNil(value)) {
        return 'var(-ui-product-quick-add-modal-image-border-radius)'
      }
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productQuickAddModal.price.position',
    styleProperty: '--ui-product-quick-add-modal-price-container-justify-content',
    getValueFn: (value) => {
      if (_.isEmpty(value)) return 'var(--ui-product-quick-add-modal-price-container-justify-content)'
      switch (value) {
        case 'bottom':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    },
  },
  // -- mini cart --
  {
    key: ['config.ratioCartImg', 'config.ratioThumbnail'],
    styleProperty: '--ui-mini-cart-products-item-image-padding-bottom',
    getValueFn: ratioCalc,
  },
  // -- coupon sidebar --
  {
    key: 'config.ratioCouponDetailImg',
    styleProperty: '--ui-coupon-sidebar-detail-image',
    getValueFn: ratioCalc,
  },
  {
    key: ['config.ratioCouponsItemImg', 'config.ratioThumbnail'],
    styleProperty: '--ui-coupon-card-image-padding-bottom',
    getValueFn: ratioCalc,
  },
  {
    key: ['config.ui.couponCard.usableCouponTokenCountLabel.background'],
    styleProperty: '--ui-coupon-card-usable-coupon-token-count-label-background-color',
    defaultValue: mergedTheme.colors.secondary,
  },
  {
    key: ['config.ui.couponCard.sharedLabel.text'],
    styleProperty: '--ui-coupon-card-shared-label-color',
    defaultValue: mergedTheme.colors.disabled,
  },
  {
    key: ['config.ui.couponCard.usableCouponTokenCountLabel.text'],
    styleProperty: '--ui-coupon-card-usable-coupon-token-count-label-color',
    defaultValue: mergedTheme.colors.textInvert,
  },
  // -- coupon state label
  {
    key: ['config.ui.couponStateLabel.background', 'config.couponTransferableLabelBackgroundColor'],
    styleProperty: '--ui-coupon-state-label-background-color',
    defaultValue: mergedTheme.colors.secondary,
  },
  {
    key: ['config.ui.couponStateLabel.text', 'config.couponTransferableLabelTextColor'],
    styleProperty: '--ui-coupon-state-label-color',
    defaultValue: mergedTheme.colors.textInvert,
  },
  {
    key: ['config.ui.couponStateLabel.inactiveBackground', 'config.couponExpiredLabelBackgroundColor'],
    styleProperty: '--ui-coupon-state-label-inactive-background-color',
    defaultValue: mergedTheme.colors.disabled,
  },
  {
    key: ['config.ui.couponStateLabel.inactiveText', 'config.couponExpiredLabelTextColor'],
    styleProperty: '--ui-coupon-state-label-inactive-color',
    defaultValue: mergedTheme.colors.textInvert,
  },
  {
    key: ['config.ui.couponStateLabel.pendingBackground'],
    styleProperty: '--ui-coupon-state-label-pending-background-color',
    defaultValue: mergedTheme.colors.active,
  },
  {
    key: ['config.ui.couponStateLabel.pendingText'],
    styleProperty: '--ui-coupon-state-label-pending-color',
    defaultValue: mergedTheme.colors.textInvert,
  },
  {
    key: ['config.ui.couponStateLabel.scale'],
    styleProperty: '--ui-coupon-state-label-scale',
    defaultValue: 1,
  },
  // -- Footer --
  {
    key: ['config.ui.footer.backgroundColor', 'config.footerDownBackgroundColor', 'colors.background'],
    styleProperty: '--ui-footer-background-color',
    defaultValue: '#FFFFFF',
  },
  {
    key: ['config.ui.footer.textColor', 'colors.text'],
    styleProperty: '--ui-footer-text-color',
    defaultValue: '#222',
  },
  {
    key: ['config.ui.footer.menu.borderBottomWidth', 'config.ui.footer.menu.borderWidth'],
    styleProperty: '--ui-footer-menu-border-bottom-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 1,
  },
  {
    key: ['config.ui.footer.menu.borderBottomStyle', 'config.ui.footer.menu.borderStyle'],
    styleProperty: '--ui-footer-menu-border-bottom-style',
    defaultValue: 'solid',
  },
  {
    key: ['config.ui.footer.menu.borderBottomColor', 'config.ui.footer.menu.borderColor'],
    styleProperty: '--ui-footer-menu-border-bottom-color',
    defaultValue: mergedTheme.colors.disabled,
  },
  {
    key: ['config.ui.footer.menu.borderTopWidth', 'config.ui.footer.menu.borderWidth'],
    styleProperty: '--ui-footer-menu-border-top-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 1,
  },
  {
    key: ['config.ui.footer.menu.borderTopStyle', 'config.ui.footer.menu.borderStyle'],
    styleProperty: '--ui-footer-menu-border-top-style',
    defaultValue: 'solid',
  },
  {
    key: ['config.ui.footer.menu.borderTopColor', 'config.ui.footer.menu.borderColor'],
    styleProperty: '--ui-footer-menu-border-top-color',
    defaultValue: mergedTheme.colors.disabled,
  },
  {
    key: ['config.ui.footer.menu.backgroundColor', 'config.footerUpBackgroundColor'],
    styleProperty: '--ui-footer-menu-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.footer.logo.backgroundColor',
    styleProperty: '--ui-footer-logo-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.footer.logo.textColor',
    styleProperty: '--ui-footer-logo-text-color',
  },
  {
    key: 'config.ui.footer.logo.width',
    getValueFn: parseThemeStyleUnit,
    styleProperty: '--ui-footer-logo-width',
    defaultValue: 'var(--ui-header-logo-width)',
  },
  {
    key: ['config.ui.footer.links.backgroundColor', 'config.footerDownBackgroundColor'],
    styleProperty: '--ui-footer-links-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.footer.bottomBar.backgroundColor',
    styleProperty: '--ui-footer-bottom-bar-background-color',
    defaultValue: '#000000',
  },
  {
    key: 'config.ui.footer.bottomBar.textColor',
    styleProperty: '--ui-footer-bottom-bar-text-color',
    defaultValue: '#ffffff',
  },
  {
    key: 'config.ui.footer.bottomBar.textAlign',
    styleProperty: '--ui-footer-bottom-bar-text-align',
    defaultValue: 'center',
  },
  {
    key: 'config.ui.footer.bottomBar.fontSize',
    styleProperty: '--ui-footer-bottom-bar-font-size',
    getValueFn: parseThemeStyleUnit,
  },
  {
    key: 'config.ui.footer.bottomBar.borderTopWidth',
    styleProperty: '--ui-footer-bottom-bar-border-top-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  {
    key: 'config.ui.footer.bottomBar.borderTopStyle',
    styleProperty: '--ui-footer-bottom-bar-border-top-style',
    defaultValue: 'solid',
  },
  {
    key: 'config.ui.footer.bottomBar.borderTopColor',
    styleProperty: '--ui-footer-bottom-bar-border-top-color',
    defaultValue: 'currentColor',
  },
  {
    key: 'config.ui.footer.bottomBar.topSpacing',
    styleProperty: '--ui-footer-bottom-bar-padding-top',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  {
    key: 'config.ui.footer.bottomBar.bottomSpacing',
    styleProperty: '--ui-footer-bottom-bar-padding-bottom',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  // -- List --
  {
    key: 'config.ui.list.title.color',
    styleProperty: '--ui-list-title-color',
    defaultValue: 'currentColor',
  },
  {
    key: 'config.ui.list.item.color',
    styleProperty: '--ui-list-item-color',
    defaultValue: 'currentColor',
  },
  {
    key: 'config.ui.list.item.linkPrefix',
    styleProperty: '--ui-list-item-link-prefix',
    defaultValue: '"-"',
  },
  // -- content groups --
  {
    key: 'config.ui.contentGroup.imageList.numberOfColumns',
    styleProperty: '--ui-content-group-image-list-number-of-columns',
    defaultValue: {
      mobile: 2,
      tablet: 3,
    },
  },
  {
    key: 'config.ui.contentGroup.imageList.gap',
    styleProperty: '--ui-content-group-image-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 16,
      tablet: 30,
    },
  },
  {
    key: 'config.ui.contentGroup.imageList.item.name.color',
    styleProperty: '--ui-content-group-image-list-item-name-color',
    defaultValue: '#ffffff',
  },
  // ## pages ##
  // -- account favourites --
  {
    key: 'config.pages.account.favourites.list.gap',
    styleProperty: '--pages-account-favourites-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-product-list-gap)',
  },
  {
    key: 'config.pages.account.favourites.numberOfColumns',
    styleProperty: '--pages-account-favourites-item-width',
    getValueFn: (value, mq) => {
      if (_.isString(value) && _.startsWith(value, 'var(--')) return value
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      const gapWidth = _.includes(['mobile'], mq) || _.isNil(mq) ? 1.6 : 3
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((gapWidth * numGaps) / numCol, 2)}rem)`
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: 'var(--ui-product-list-item-width)',
  },
  // -- account order --
  {
    key: ['config.ratioAccountOrdersItemImg', 'config.ratioThumbnail'],
    styleProperty: '--pages-account-orders-item-thumbnail-padding-bottom',
    getValueFn: ratioCalc,
  },
  {
    key: ['config.ratioAccountOrderProductImg', 'config.ratioThumbnail'],
    styleProperty: '--pages-account-order-product-thumbnail-padding-bottom',
    getValueFn: ratioCalc,
  },
  // -- cart --
  {
    key: ['config.ratioCartImg', 'config.ratioThumbnail'],
    styleProperty: '--pages-cart-item-thumbnail-aspect-ratio',
    getValueFn: parseAspectRatio,
  },
  {
    key: 'config.pages.cart.item.thumbnail.roundedCorners',
    styleProperty: '--pages-cart-item-thumbnail-border-radius',
    defaultValue: 0,
  },
  // -- categories --
  {
    key: 'config.pages.categories.numberOfColumns',
    styleProperty: '--pages-categories-item-width',
    getValueFn: (value) => {
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((1.6 * numGaps) / numCol, 2)}rem)`
    },
    defaultValue: {
      mobile: 2,
      tablet: 4,
    },
  },
  {
    key: ['config.ratioCategoryThumb'],
    styleProperty: '--pages-categories-item-thumbnail-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: '4/3',
  },
  // -- products --
  {
    key: 'config.pages.products.pagination.display',
    styleProperty: '--pages-products-pagination-top-display',
    getValueFn: (value) => (_.includes(['top', 'both'], value) ? 'block' : 'none'),
    defaultValue: 'both',
  },
  {
    key: 'config.pages.products.pagination.display',
    styleProperty: '--pages-products-pagination-bottom-display',
    getValueFn: (value) => (_.includes(['bottom', 'both'], value) ? 'block' : 'none'),
    defaultValue: 'both',
  },
  {
    key: 'config.pages.products.numberOfColumns',
    styleProperty: '--pages-products-item-width',
    getValueFn: (value, mq) => {
      if (_.isString(value) && _.startsWith(value, 'var(--')) return value
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      const gapWidth = _.includes(['mobile'], mq) || _.isNil(mq) ? 1.6 : 3
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((gapWidth * numGaps) / numCol, 2)}rem)`
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: 'var(--ui-product-list-item-width)',
  },
  {
    key: 'config.pages.products.list.gap',
    styleProperty: '--pages-products-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-product-list-gap)',
  },
  // -- products comparison --
  {
    key: 'config.ratioPlp',
    styleProperty: '--pages-products-comparison-thumbnail-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: '1:1',
  },
  // -- product detail --
  {
    key: 'config.ratioPdp',
    styleProperty: '--pages-product-image-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: '1:1',
  },
  {
    key: 'config.ui.images.zoom.enabled',
    styleProperty: '--pages-product-image-zoom-button-display',
    getValueFn: (value = true) => (value ? 'block' : 'none'),
    defaultValue: true,
  },
  {
    key: 'config.pages.product.columns',
    styleProperty: '--pages-product-grid-template-columns',
    getValueFn: (value) => {
      if (_.isArray(value)) {
        // e.g. [200, '1fr'] -> "20rem 1fr"
        return _.join(_.map(value, parseThemeStyleUnit), ' ')
      }
      return value
    },
    defaultValue: {
      mobile: '100%',
      tablet: 'min(55%, 67rem) 1fr',
    },
  },
  {
    key: 'config.pages.product.layout',
    styleProperty: '--pages-product-grid-template-areas',
    getValueFn: (value) => {
      if (_.isArray(value)) {
        // e.g. ['images', 'spacer_m', 'title', 'price', ...] -> "images" "title" "price"
        // e.g. [['images', 'title'], ['images', 'spacer_m'], ['images', 'price'], ...] ->
        // `
        //  "images title"
        //  "images price"
        //  ...
        // `
        return _.join(
          _.compact(_.map(value, (row) => {
            const rowItems = _.castArray(row)
            // skip spacers
            if (_.some(rowItems, (item) => _.startsWith(item, 'spacer'))) {
              return null
            }
            return `"${_.join(rowItems, ' ')}"`
          })),
          '\n',
        )
      }
      return false
    },
    defaultValue: pagesProductLayoutDefaultValue,
  },
  {
    key: 'config.pages.product.layout',
    styleProperty: '--pages-product-grid-template-rows',
    getValueFn: (value) => {
      // const defaultRowValue = 'minmax(0, min-content)'
      const defaultRowValue = 'auto'
      if (_.isArray(value)) {
        const numberOfColumns = _.max(_.map(value, (row) => _.size(_.castArray(row))))
        let result = []
        _.forEach(value, (row, index) => {
          const prevRow = index > 0 ? _.nth(value, index - 1) : []
          const nextRow = _.nth(value, index + 1) || []
          const rowItems = _.castArray(row)
          const spanItems = _.map(
            rowItems,
            (item, rowIdx) => (
              _.isEqual(_.nth(prevRow, rowIdx), item)
                ? item
                : null
            ),
          )
          // Make the last row of span items more space
          if (
            !_.every(spanItems, _.isNil)
            && !_.every(_.difference(spanItems, nextRow), _.isNil)
            && numberOfColumns > 1
          ) {
            result = [
              ...result,
              '1fr',
            ]
          } else if (
            _.some(rowItems, (item) => _.startsWith(item, 'spacer'))
          ) {
            // skip spacers
          } else {
            result = [
              ...result,
              defaultRowValue,
            ]
          }
        })
        return _.join(result, ' ')
      }
      return defaultRowValue
    },
    defaultValue: pagesProductLayoutDefaultValue,
  },
  ..._.flatMap(
    [
      'images',
      'title',
      'disclaimerContentGroup',
      'price',
      'customLabels',
      'rating',
      'colorOptions',
      'sizeOptions',
      'availabilityInStore',
      'addons',
      'quantity',
      'actionPanel',
      'whatsappAma',
      'share',
      'attributes',
      'description',
      'contentGroup',
      'recommendations',
      'recentlyViewed',
      'reviews',
    ],
    (component) => ([
      {
        key: 'config.pages.product.layout',
        styleProperty: `--pages-product-${_.kebabCase(component)}-display`,
        getValueFn: (value) => (
          getPdpComponentVisibilityFromLayoutConfig({
            layout: value,
            component,
          })
        ),
        defaultValue: pagesProductLayoutDefaultValue,
      },
      {
        key: 'config.pages.product.layout',
        styleProperty: `--pages-product-${_.kebabCase(component)}-margin-top`,
        getValueFn: (value) => {
          const spacer = getPdpComponentSpacingFromLayoutConfig({
            layout: value,
            component,
            direction: 'top',
          })
          if (spacer) {
            return `var(--ui-${_.kebabCase(spacer)})`
          }
          return '0rem'
        },
        defaultValue: pagesProductLayoutDefaultValue,
      },
      {
        key: 'config.pages.product.layout',
        styleProperty: `--pages-product-${_.kebabCase(component)}-margin-bottom`,
        getValueFn: (value) => {
          const spacer = getPdpComponentSpacingFromLayoutConfig({
            layout: value,
            component,
            direction: 'bottom',
          })
          if (spacer) {
            return `var(--ui-${_.kebabCase(spacer)})`
          }
          return '0rem'
        },
        defaultValue: pagesProductLayoutDefaultValue,
      },
    ]),
  ),
  // 'attributes',
  {
    key: 'config.pages.product.attributes.title.visible',
    styleProperty: '--pages-product-attributes-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: true,
  },
  // 'description',
  {
    key: 'config.pages.product.description.title.visible',
    styleProperty: '--pages-product-description-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: true,
  },
  // 'recommendations',
  {
    key: 'config.pages.product.recommendations.title.visible',
    styleProperty: '--pages-product-recommendations-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: true,
  },
  // 'recentlyViewed',
  {
    key: 'config.pages.product.recently-viewed.title.visible',
    styleProperty: '--pages-product-recently-viewed-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: true,
  },
  // 'reviews',
  {
    key: 'config.pages.product.reviews.title.visible',
    styleProperty: '--pages-product-reviews-title-display',
    getValueFn: (value) => (
      value ? false : 'none'
    ),
    defaultValue: true,
  },
  // -- stores --
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-flex-wrap',
    getValueFn: (value) => {
      const heroImageRatio = parseAspectRatio(value)
      const heroImageRatioInNumber = _.divide(
        ..._.map(
          _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
          _.toNumber,
        ),
      )
      const isHeroImageTooWide = heroImageRatioInNumber > 1.5
      return isHeroImageTooWide ? 'wrap' : 'nowrap'
    },
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-hero-order',
    getValueFn: (value, mq) => {
      if (_.includes(['mobile'], mq) || _.isNil(mq)) return 1
      const heroImageRatio = parseAspectRatio(value)
      const heroImageRatioInNumber = _.divide(
        ..._.map(
          _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
          _.toNumber,
        ),
      )
      const isHeroImageTooWide = heroImageRatioInNumber > 1.5
      return isHeroImageTooWide ? 1 : 3
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-hero-flex-basis',
    getValueFn: (value) => {
      const heroImageRatio = parseAspectRatio(value)
      const heroImageRatioInNumber = _.divide(
        ..._.map(
          _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
          _.toNumber,
        ),
      )
      const isHeroImageTooWide = heroImageRatioInNumber > 1.5
      return isHeroImageTooWide ? '100%' : '36%'
    },
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-content-flex-basis',
    getValueFn: (value) => {
      const heroImageRatio = parseAspectRatio(value)
      const heroImageRatioInNumber = _.divide(
        ..._.map(
          _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
          _.toNumber,
        ),
      )
      const isHeroImageTooWide = heroImageRatioInNumber > 1.5
      return isHeroImageTooWide ? '50%' : '28%'
    },
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-image-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsMap',
    styleProperty: '--pages-store-locations-item-map-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: 'var(--pages-store-locations-item-image-ratio)',
  },
])
export default getThemeConfigStylesRegistrationList
