import _ from 'lodash'

export default function getPdpComponentSpacingFromLayoutConfig({
  layout = [],
  component,
  direction = 'top',
}) {
  const isBottom = direction === 'bottom'
  const columns = _.zip(..._.map(layout, _.castArray))
  let spacer = null
  _.forEach(columns, (col) => {
    const index = isBottom ? _.lastIndexOf(col, component) : _.indexOf(col, component)
    if (
      // only last item of column have spacer on component's bottom
      (!isBottom || index === (_.size(col) - 2))
      && index >= 0
    ) {
      const targetRow = _.nth(col, index + (isBottom ? 1 : -1))
      if (_.startsWith(targetRow, 'spacer')) {
        spacer = targetRow
      }
    }
  })
  return spacer
}
