import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => {
  const cell = {
    padding: '2rem',
    background: theme.colors.background,
    fontSize: '1.4rem',
    lineHeight: '1.25em',
    textTransform: 'capitalize',
    border: `1px solid ${theme.colors.gray300}`,
    margin: '-1px',
  }
  return ({
    table: {
      textAlign: 'left',
      background: theme.colors.gray300,
      border: `1px solid ${theme.colors.gray300}`,
    },
    line: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      margin: 0,
    },
    label: {
      extend: cell,
      width: '39%',
      maxWidth: '27rem',
    },
    value: {
      extend: cell,
      flex: 1,
      background: theme.colors.light,
    },
  })
})

export default useStyles
