/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash'
import React, { useMemo, useEffect, useState } from 'react'
// import {
//   Field,
// } from 'formik'
import { usePrevious } from '../../hook'
import combineClassNames from '../../helpers/combineClassNames'
import {
  customStylesQuantityPicker,
} from '../dropdown/custom-styles'
import Dropdown from '../dropdown'
import useStyles from './input-quantity-style'

const InputQuantityView = ({
  valueEditMode = 'default', // 'default' or 'picker' or 'input'
  max = Infinity,
  min = 0,
  step = 1,
  value = 0,
  inline = false,
  disabled,
  readOnly = false,
  onChange,
  // formik,
}) => {
  const [validValue, setValidValue] = useState(value)
  const [valueUpdatedByControls, setValueUpdatedByControls] = useState(value)
  const inputRerenderKey = useMemo(() => {
    if (value === valueUpdatedByControls) {
      return _.uniqueId('quantity_input_')
    }
  }, [value, valueUpdatedByControls])
  const prevValue = usePrevious(validValue)
  const qtyList = _.map(
    _.range(min, _.min([max + 1, 999]), step),
    (i) => ({
      label: i.toString(),
      value: i,
    }),
  )
  const valueZeroView = useMemo(() => value === 0 && min === 0 && inline, [value, min, inline])
  const styles = useStyles({ valueZeroView })
  const onIncrease = () => {
    const newValue = Math.min(value + step, max)
    setValueUpdatedByControls(newValue)
  }
  const onDecrease = () => {
    const newValue = Math.max(value - step, min)
    setValueUpdatedByControls(newValue)
  }
  const onPickerChange = (option) => {
    setValueUpdatedByControls(option.value)
  }
  const onInputBlur = ({ nativeEvent }) => {
    const text = _.get(nativeEvent, 'target.value', value)
    let valueInNumber = _.toNumber(text)
    if (_.isEmpty(text)) {
      valueInNumber = value
    }
    if (
      _.isFunction(onChange)
      && value !== valueInNumber
    ) onChange(valueInNumber)
  }
  const handleInputFocus = (event) => {
    event.preventDefault()
    if (!_.inRange(value, min, max + 1)) {
      _.set(event, 'target.value', '')
      if (_.isFunction(onChange)) onChange(prevValue)
      event.target.focus()
    }
    event.target.select()
  }

  const renderValue = () => {
    switch (disabled || readOnly ? 'default' : valueEditMode) {
      case 'picker':
        return (
          <Dropdown
            className={styles.quantityDropdown}
            widthDropdown="100%"
            values={value}
            customStyles={customStylesQuantityPicker}
            options={qtyList}
            onChange={onPickerChange}
            isSearchable={false}
          />
        )
      case 'input':
        return (
          <input
            key={inputRerenderKey}
            type="number"
            inputMode="numeric"
            name="quantity_input"
            max={max}
            min={min}
            step={step}
            placeholder={validValue}
            defaultValue={value}
            onBlur={onInputBlur}
            onFocus={handleInputFocus}
            className={styles.quantityInput}
          />
        )
      default:
        return (
          <div className={combineClassNames([styles.value, inline && styles.valueInline])}>
            {value}
          </div>
        )
    }
  }

  useEffect(() => {
    if (
      _.isFunction(onChange)
      && valueUpdatedByControls !== value
    ) {
      onChange(valueUpdatedByControls)
    }
  }, [valueUpdatedByControls])

  useEffect(() => {
    if (_.inRange(value, min, max + 1)) {
      setValidValue(value)
    }
  }, [value, min, max])

  return (
    <div className={combineClassNames([styles.container, inline && styles.containerInline])}>
      { !valueZeroView && (
        <button
          type="button"
          className={
            combineClassNames([
              styles.button,
              valueZeroView && styles.buttonValueZeroView,
              styles.buttonDecrease,
            ])
          }
          onClick={!disabled ? onDecrease : undefined}
          disabled={disabled || readOnly || value <= min}
        >
          <i className={combineClassNames([styles.buttonIcon, styles.buttonIconDecrease])} />
        </button>
      )}
      {!valueZeroView && renderValue()}
      <button
        type="button"
        className={
          combineClassNames([
            styles.button,
            valueZeroView && styles.buttonValueZeroView,
            styles.buttonIncrease,
          ])
        }
        onClick={!disabled ? onIncrease : undefined}
        disabled={disabled || readOnly || value >= max}
      >
        <i className={combineClassNames([styles.buttonIcon, styles.buttonIconIncrease])} />
      </button>
    </div>
  )
}

export default InputQuantityView
