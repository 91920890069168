import _ from 'lodash'

export default function getPdpComponentVisibilityFromLayoutConfig({
  layout = [],
  component,
  visibleValue = 'block',
}) {
  return _.includes(_.uniq(_.flatMap(layout)), component)
    ? visibleValue
    : 'none'
}
