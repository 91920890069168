/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { useLocation } from '@reach/router'
import { cancelRequest, useAuth } from 'react-omnitech-api'
import { useAlert } from '../../hook/use-alert'
import { useLink } from '../../hook/use-link'
import { useFavourites } from '../../hook/use-favourites'

import { getUrlFromLocation } from '../../helpers'
import FavouriteButtonView from './favourite-button-view'

function FavouriteButtonController({
  colorOptionId,
  favourite,
  icon,
  iconButton,
  label,
  className,
  onChange,
}) {
  // prepare hook
  const alert = useAlert()
  const { auth } = useAuth()
  const location = useLocation()
  const { navigate } = useLink()
  const {
    createFavourite,
    deleteFavourite,
    touchedFavourites,
  } = useFavourites()

  // internal state
  const [like, setLike] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!auth.userId) {
      navigate(
        '/login/',
        {
          replace: true,
          state: {
            redirectUrl: getUrlFromLocation(location),
          },
        },
      )
      return
    }

    // TODO: check it favourite, it is add or remove
    const postFunction = like ? deleteFavourite : createFavourite

    try {
      setLoading(true)
      const data = await postFunction(colorOptionId)
      if (_.isFunction(onChange)) {
        onChange(like ? { favourite: null } : data)
      }
      setLike(!like)
    } catch (err) {
      alert.show(err, { state: 'error' })
    } finally {
      setLoading(false)
    }
  }, [alert, colorOptionId, createFavourite, deleteFavourite, like])

  useEffect(() => () => {
    cancelRequest.cancelAll([
      'createFavourite',
      'deleteFavourite',
    ])
  }, [])

  useEffect(() => {
    setLike(
      _.get(touchedFavourites, colorOptionId, _.has(favourite, 'id')),
    )
  }, [favourite, touchedFavourites])

  const viewProps = {
    favourite,
    icon,
    iconButton,
    like,
    loading,
    onClick: handleClick,
    label,
    className,
  }

  return (
    <FavouriteButtonView {...viewProps} />
  )
}

export default FavouriteButtonController
