/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerModal: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    background: theme.colors.background,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '-100vh',
    transition: 'bottom 300ms',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      right: -500,
      transition: 'right 300ms',
      width: 500,
    },
  },
  containerModalOpen: {
    bottom: '0',
    transition: 'bottom 300ms',
    zIndex: 3,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      right: 0,
      transition: 'right 300ms',
    },
  },
  boxModal: {
    textAlign: 'center',
  },
  closeButton: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleModal: {
    letterSpacing: '0.5px',
    color: theme.colors.primary,
    fontSize: '2.1rem',
    marginBottom: '2.9rem',
    fontWeight: 600,
  },
  textModal: {
    textAlign: 'center',
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    color: theme.colors.gray500,
    marginBottom: '3.5rem',
    maxWidth: 265,
    margin: '0 auto',
  },
  boxButtons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
  },
  confirmButton: {
    width: 140,
    margin: [[0, 10]],
  },
  cancelButton: {
    extend: 'confirmButton',
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {

  },

}))

export default useStyles
