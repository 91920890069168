import { createContext } from 'react'

/*
 * CouponMarketplaceContext
 * Create Alert context for custom hook
 */
const CouponMarketplaceContext = createContext({
  canSelfUse: false,
  coupon: {},
  coupons: [],
  couponToken: {},
  couponTokens: [],
  couponUsage: {},
  detailType: 'coupon',
  hasMoreCoupons: false,
  hasMoreCouponTokens: false,
  isCouponDetailOpen: false,
  isCouponMarketplaceOpen: false,
  isCouponsReady: false,
  isCouponRedeemed: false,
  isCouponTokenDetailOpen: false,
  isCouponTokensReady: false,
  isDetailOpen: false,
  isDetailReady: false,
  isLoading: false,
  isModalOpen: false,
  isPopupOpen: false,
  shareLoading: false,
  showQrCodeMask: false,
  showQrCode: false,
  showSecretQrCode: false,
  secretQrCodeValue: null,
  modalContent: {},
  popupMessage: {},
  userCurrentPoints: 0,
  transferLoading: false,
  onApplyCoupon: () => {},
  onApplyCouponError: () => {},
  onApplyCouponSuccess: () => {},
  onCouponDetailClose: () => {},
  onCouponDetailOpen: () => {},
  onCouponMarketplaceClose: () => {},
  onCouponMarketplaceOpen: () => {},
  onFetchCoupons: () => {},
  onFetchCouponsNextPage: () => {},
  onFetchCouponTokens: () => {},
  onFetchCouponTokensNextPage: () => {},
  onModalClose: () => {},
  onModalOpen: () => {},
  onPopupClose: () => {},
  onPopupOpen: () => {},
  onRedeemCoupon: () => {},
  onRedeemCouponConfirm: () => {},
  onRedeemCouponError: () => {},
  onRedeemCouponSuccess: () => {},
  onRequireCouponSecret: () => {},
  onShare: () => {},
  onTransferIn: () => {},
  onTransferOut: () => {},
  onTransferUndo: () => {},
  hasMoreCouponTokensGroupbyCoupon: false,
  isCouponTokensGroupByCouponOpen: false,
  isCouponTokensGroupbyCouponReady: false,
  CouponTokensGroupbyCoupon: [],
  onCouponTokensGroupByCouponClose: () => {},
  onFetchCouponTokensGroupByCouponNextPage: () => {},
})

export default CouponMarketplaceContext
