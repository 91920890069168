import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  listItem: {
    padding: '16px 14px',
    margin: 0,
    color: theme.colors.text,
    '&:hover': {
      backgroundColor: theme.colors.light,
    },
    '& a, & span': {
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
    },
  },
  listCoupon: {
    '& span': {
      cursor: 'pointer',
      textTransform: 'capitalize',
    },
  },
}))

export default useStyles
