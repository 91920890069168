import { createUseStyles } from 'react-jss'
import Color from 'color'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    'body.modal-open__mini-cart': {
      overflow: 'hidden',
      height: '100vh',
      width: '100%',
      position: 'fixed',
    },
  },
  overlap: {
    position: 'fixed',
    display: 'block',
    zIndex: 99,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'trensparent',
  },
  miniCard: {
    position: 'fixed',
    bottom: '-53rem',
    zIndex: '100',
    right: '0',
    height: '53rem',
    width: '100vw',
    backgroundColor: Color(theme.colors.text).alpha(0.95).string(),
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    '&.mini-cart-enter': {
      bottom: '-53rem',
    },
    '&.mini-cart-enter-active': {
      bottom: 0,
      transition: 'bottom 300ms',
    },
    '&.mini-cart-enter-done': {
      bottom: 0,
    },
    '&.mini-cart-exit': {
      bottom: 0,
    },
    '&.mini-cart-exit-active': {
      bottom: '-53rem',
      transition: 'bottom 300ms',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      position: 'fixed',
      top: 0,
      zIndex: '100',
      right: '-48.5rem',
      height: '-webkit-fill-available',
      fallbacks: {
        height: '100vh',
      },
      width: '48.5rem',
      padding: '3rem 2.1rem 4rem 3rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      '&.mini-cart-enter': {
        right: '-48.5rem',
      },
      '&.mini-cart-enter-active': {
        right: '0',
        transition: 'right 300ms',
      },
      '&.mini-cart-enter-done': {
        right: '0',
      },
      '&.mini-cart-exit': {
        right: '0',
      },
      '&.mini-cart-exit-active': {
        right: '-48.5rem',
        transition: 'right 300ms',
      },
    },
  },
  boxItems: {
    display: 'flex',
    flexDirection: 'column',
  },
  boxTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 0,
    '& h4': {
      color: theme.colors.background,
      textTransform: 'uppercase',
      fontSize: '2rem',
      marginBottom: 0,

    },
    '& img': {
      cursor: 'pointer',
      transform: ' scale(0.8)',
      margin: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginBottom: '0.9rem',
      '& h4': {
        color: theme.colors.background,
        textTransform: 'uppercase',
        fontSize: '2rem',
        marginBottom: 0,
      },
      '& img': {
        cursor: 'pointer',
        transform: ' scale(1.5)',
      },

    },
  },
  boxProducts: {
    maxHeight: '32rem',
    overflowY: 'auto',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      maxHeight: 'calc(100vh - 30rem)',
      overflowY: 'auto',
    },

  },
  boxPrice: {
    // padding: '0 19px 0 10px',
    '&  div:first-child': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& p': {
        color: theme.colors.background,
        textTransform: 'uppercase',
        marginBottom: 0,
        lineHeight: 1.1,
      },
    },
    '&  div:nth-child(2)': {
      display: 'flex',
      justifyContent: 'space-between',
      '& p': {
        marginBottom: '1.687rem',
        color: theme.colors.background,
        textTransform: 'uppercase',
        fontSize: '2rem',
      },
    },
  },
  price: {
    fontSize: '2rem',
    color: theme.colors.background,
  },
  closeButton: {
    background: 'transparent',
    border: 0,
    padding: 0,
    '&:focus, img:focus': {
      outline: 'none',
    },
  },
  '@keyframes miniCartload': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  loader: {
    borderRadius: '50%',
    width: '10em',
    height: '10em',
    margin: [[60, 'auto']],
    fontSize: 10,
    position: 'relative',
    textIndent: '-9999em',
    borderTop: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderRight: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderBottom: `1.1em solid ${hexToRgba(theme.colors.secondary, 0.2)}`,
    borderLeft: `1.1em solid ${theme.colors.secondary}`,
    transform: 'translateZ(0)',
    animation: '$miniCartload 1.1s infinite linear',
    '&:after': {
      borderRadius: '50%',
      width: '10em',
      height: '10em',
    },
  },
  loaderBox: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
  },
  button: {
    height: '5rem',
    margin: 0,
  },
  plus: {
    textTransform: 'lowercase',
    fontSize: 14,
  },
}))

export default useStyles
