import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    overflowX: 'auto',
    scrollbarWidth: 'thin',
    background: 'var(--ui-header-mega-menu-background-color)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  activeIndicator: {
    color: 'var(--ui-header-mega-menu-item-active-text-color)',
    '&:after': {
      content: '""',
      backgroundColor: 'var(--ui-header-mega-menu-item-active-color)',
      position: 'absolute',
      bottom: 0,
      left: 'var(--ui-header-mega-menu-item-indicator-left)',
      right: 'var(--ui-header-mega-menu-item-indicator-right)',
      top: 'var(--ui-header-mega-menu-item-indicator-top)',
    },
  },
  menu: {
    width: 'fit-content',
    position: 'relative',
    margin: '0 auto',
    padding: 0,
    display: 'flex',
    listStyleType: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItem: {
    padding: [[0, 10]],
    // height: '3.5rem',
    fontSize: '1.4rem',
    margin: 0,
    whiteSpace: 'nowrap',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[0, 20]],
    },
  },
  menuItemLarge: {
    padding: [[0, 60]],
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[0, 80]],
    },
  },
  menuItemLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'var(--ui-header-mega-menu-item-text-color)',
    fontWeight: 'var(--ui-header-mega-menu-item-font-weight)',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    display: 'block',
    position: 'relative',
    '&:hover': {
      extend: 'activeIndicator',
    },
    '& p': {
      textTransform: 'lowercase',
      fontSize: 14,
      color: theme.colors.primary,
      '&:hover': {
        color: theme.colors.secondary,
      },
    },
  },
  active: {
    extend: 'activeIndicator',
  },
  menuItemLinkText: {
    position: 'relative',
    zIndex: 2,
  },
}))

export default useStyles
