import { createUseStyles } from 'react-jss'
import hexToRgba from '../../../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  sectionListHeaderContainer: ({ error } = {}) => ({
    backgroundColor: theme.colors.light,
    borderTop: `0.1rem solid ${theme.colors.gray300}`,
    padding: '1.2rem 1.6rem',
    ...error && {
      borderLeft: `0.3rem solid ${theme.colors.alert}`,
      paddingLeft: '0.9rem',
      backgroundColor: hexToRgba(theme.colors.alert, 0.15),
    },
  }),
  sectionListHeaderText: ({ error, required } = {}) => ({
    fontSize: theme.typo.h5Size,
    margin: 0,
    ...required && {
      '&::after': {
        content: '"*"',
        color: theme.colors.alert,
        paddingLeft: '0.2rem',
      },
    },
    ...error && {
      color: theme.colors.alert,
    },
  }),
  sectionListHeaderDesc: {
    fontSize: '1.2rem',
    lineHeight: 1.25,
  },
  price: {
    fontSize: '2.1rem',
    marginTop: '0.5rem',
  },
}))

export default useStyles
