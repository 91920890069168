import _ from 'lodash'
import React, { useState } from 'react'
import { useSystemSettings } from 'react-omnitech-api'
import SocialMediaView from './social-media-view'
import getSocialMediaOptions from '../../helpers/get-social-media-options'

const SocialMediaController = (props) => {
  const [show, setShow] = useState(false);
  const { getSystemSetting } = useSystemSettings()
  const {
    title,
    imageUrl,
    hashtags,
    shareUrl,
    phoneNumber,
    ...rest
  } = props

  const shareOption = getSocialMediaOptions({
    url: shareUrl,
    title,
    imageUrl,
    hashtags,
    phoneNumber,
  })
  const socialMediaActive = _.forEach(shareOption, (value, key) => Object.assign(shareOption, {
    [key]: {
      isActive: !!getSystemSetting(`social_sharer.enable_${key}`),
      ...value,
    },
  }))
  const hasAnySocialMediaActive = _.includes(_.reduce(socialMediaActive, (result, value) => {
    if (value.isActive) result.push(value.isActive)
    return result
  }, []), true)

  function handleShare(provider) {
    const shareProvider = shareOption[provider]
    const params = _.map(_.keys(shareProvider.options), (key) => (
      `${key}=${encodeURIComponent(shareProvider.options[key])}`
    ))
    const providerUrl = shareProvider.url
    const joiner = providerUrl.indexOf('?') !== -1 ? '&' : '?'
    const sharerFullUrl = `${providerUrl}${joiner}${params.join('&')}`
    window.open(
      sharerFullUrl,
      provider === 'email' ? '_self' : '_blank', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=400, height=400',
    )
  }

  function copyToClipboard() {
    setShow(true)
    navigator.clipboard.writeText(_.join(_.compact([title, shareUrl]), ' \n'))
    setTimeout(() => {
      setShow(false);
    }, 1000);
  }

  const viewProps = {
    show,
    socialMediaActive,
    hasAnySocialMediaActive,
    onShare: handleShare,
    copyToClipboard,
    ...rest,
  }

  return (
    <SocialMediaView {...viewProps} />
  )
}

export default SocialMediaController
