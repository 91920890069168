import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { useDineInCart, useUser } from 'react-omnitech-api'
// import CurrencyFormat from '../currency-format'
import { useDineInMiniCart, useThemeConfig } from '../../hook'
import useStyles from './dine-in-mini-cart-style'
import Button from '../button'
import Modal from '../modal'
import { isNullOrUndefined, groupAddonsWithQuantity } from '../../helpers'
import ClearIcon from '../../assets/icons/icon_clear.svg'
// import ProductMiniCart from '../product-mini-cart'
import Cross from '../../assets/icons/icon_cross_white.svg'

const Item = (props) => {
  const {
    addons = [],
    inlineError,
    inlineErrors = [],
    title,
    quantity,
    onRemove,
    // onEdit,
  } = props
  const styles = useStyles()
  return (
    <div className={styles.item}>
      <p className={styles.itemTitle}>{`${quantity} x ${title}`}</p>
      {
        _.map(groupAddonsWithQuantity(addons), (addon) => (
          <p
            key={_.get(addon, 'identifierUuid')}
            className={styles.addon}
          >
            {`${_.get(addon, 'title')} x ${_.get(addon, 'quantity')}`}
          </p>
        ))
      }
      {
        !_.isEmpty(inlineError) && (
          <p className={styles.itemError}>{inlineError}</p>
        )
      }
      {
        _.map(inlineErrors, (error, index) => (
          <p key={`inline_error_${index}`} className={styles.itemError}>{error}</p>
        ))
      }
      {/* <button
        className={styles.editButton}
        type="button"
        onClick={onEdit}
      >
        edit
      </button> */}
      <button
        className={styles.clearIconButton}
        type="button"
        onClick={onRemove}
      >
        <img className={styles.clearIcon} src={ClearIcon} alt="delete" />
      </button>
    </div>
  )
}

const DineInMiniCartView = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            version
          }
        }
      }
    `,
  )
  // prepare hook
  const { t } = useTranslation()
  const {
    // cart,
    loading,
    miniCartOpen,
    // totalItems,
    skusUnavailableToCheckout,
    closeMiniCart,
    onRemoveCartLine,
    onPlaceOrder,
  } = useDineInMiniCart()

  const {
    stagingCart,
  } = useDineInCart()
  const {
    user,
  } = useUser()
  const { getConfig } = useThemeConfig()

  const isTester = _.includes(_.get(user, 'roles', []), 'tester')

  // Theme config
  const enableAppVersion = getConfig('config.ui.dineInMiniCart.enableAppVersion', false)
  const enableInlineErrorSkuCodes = getConfig('config.ui.dineInMiniCart.enableInlineErrorSkuCodes', false)

  // State
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  // local variable
  const { actions = [] } = stagingCart

  const groupedCartLineProperties = useMemo(() => (_.map(
    _.filter(actions, ({ productAddonId }) => isNullOrUndefined(productAddonId)),
    (line) => ({
      ...line,
      addons: _.filter(
        actions,
        ({ productAddonId, groupUuid }) => !isNullOrUndefined(productAddonId) && _.isEqual(groupUuid, _.get(line, 'groupUuid')),
      ),
    }),
  )), [actions])

  const cartLineItems = useMemo(() => (
    _.map(groupedCartLineProperties, (item) => {
      const notAllowedSkus = _.filter(skusUnavailableToCheckout, (sku) => (
        _.includes(
          [
            _.get(item, 'skuId'),
            ..._.map(_.get(item, 'addons', []), 'skuId'),
          ],
          sku.id,
        )
      ))
      const invalidKeys = _.uniq(_.flatMap(notAllowedSkus, 'validationErrors'))
      const showNotAllowedToCheckoutMessage = !_.isEmpty(invalidKeys)
      return {
        ...item,
        ...showNotAllowedToCheckoutMessage && {
          inlineErrors: isTester
            ? [`${t('ui.dineInMiniCart.itemUnavailable')} ::Tester only:: ${_.join(invalidKeys, ' | ')}`]
            : _.compact([
              ..._.map(invalidKeys, (context) => (
                t('ui.dineInMiniCart.itemUnavailable', { context })
              )),
              enableInlineErrorSkuCodes && `[${_.join(_.map(notAllowedSkus, 'code'))}]`,
            ]),
        },
      }
    })
  ), [groupedCartLineProperties, skusUnavailableToCheckout, t])

  const continueButtonDisabled = useMemo(() => (
    _.isEmpty(actions)
    || !_.isEmpty(skusUnavailableToCheckout)
  ), [actions, skusUnavailableToCheckout])

  /**
   * handleContinue
   * go to cart page
   */
  const handleContinue = () => {
    closeMiniCart()
    // FL: Show place order dialog
    setConfirmationDialogOpen(true)
  }

  const onConfirmationDialogRequestClose = () => {
    setConfirmationDialogOpen(false)
  }

  const handlePlaceOrder = async () => {
    try {
      await onPlaceOrder()
      // alert.show('Order Placed')
    } catch (error) {
      console.log('//// error ...', error)
    } finally {
      setConfirmationDialogOpen(false)
    }
  }

  useEffect(() => {
    document.body.classList[miniCartOpen ? 'add' : 'remove']('modal-open__dine-in-mini-cart')
    if (!miniCartOpen) {
      // document.body.scrollTop = currenctScrollTop // for safari
      // document.documentElement.scrollTop = currenctScrollTop // for IE/Firefox/...others
    }
  }, [miniCartOpen])

  useEffect(() => () => {
    // remove modal-open__dine-in-mini-cart class from body when componentDidMount
    document.body.classList.remove('modal-open__dine-in-mini-cart')
    document.getElementsByTagName('body')[0].setAttribute('style', '')
  },
  [])

  // style
  const style = useStyles({ miniCartOpen })

  return (
    <>
      <CSSTransition
        in={miniCartOpen}
        timeout={300}
        unmountOnExit
        classNames="mini-cart"
      >
        <div className={style.miniCard}>
          {
            loading
              ? (
                <div className={style.loaderBox}>
                  <div className={style.loader}> </div>
                </div>
              )
              : (
                <>
                  <div className={style.boxItems}>
                    <div className={style.boxTitle}>
                      <h4>{t('ui.miniCard.title')}</h4>
                      <button
                        className={style.closeButton}
                        type="button"
                        onClick={closeMiniCart}
                      >
                        <img src={Cross} alt="" />
                      </button>
                    </div>
                    <div className={style.boxProducts}>
                      {cartLineItems.map((cartLine) => (
                        <Item
                          key={_.get(cartLine, 'identifierUuid')}
                          // onEdit={() => { onItemEdit(cartLine) }}
                          onRemove={() => { onRemoveCartLine(cartLine) }}
                          {...cartLine}
                        />
                      ))}
                    </div>
                  </div>
                  {
                    enableAppVersion && (
                      <small className={style.appVersion}>{site.siteMetadata.version}</small>
                    )
                  }
                  <div className={style.boxPrice}>
                    {/* <div>
                      <p>{t('ui.miniCard.items')}</p>
                      <p>
                        <span className={style.plus}>x </span>
                        {totalItems}
                      </p>
                    </div>
                    <div>
                      <p>{t('ui.miniCard.subtotal')}</p>
                      <CurrencyFormat
                        value={cartTotalPrice || totalPrice}
                        className={style.price}
                        currency={currency}
                      />
                    </div> */}
                    <Button
                      className={style.button}
                      text={t('ui.dineInMiniCart.buttons.continue')}
                      onClick={handleContinue}
                      disabled={continueButtonDisabled}
                    />
                  </div>
                </>
              )
        }
        </div>
      </CSSTransition>
      {
        miniCartOpen && (
          <Button className={style.overlap} onClick={closeMiniCart} type="button">Close</Button>
        )
      }
      <Modal
        dialog
        isOpen={confirmationDialogOpen}
        onRequestClose={onConfirmationDialogRequestClose}
      >
        <div className={style.dialogContainer}>
          <div className={style.dialogBody}>
            <h1 className={style.dialogTitle}>{t('ui.dineInMiniCart.confirmationDialog.title')}</h1>
            <p className={style.dialogDescription}>{t('ui.dineInMiniCart.confirmationDialog.description')}</p>
          </div>
          <div className={style.dialogButtons}>
            <Button
              className={style.dialogButton}
              dark
              text={t('ui.dineInMiniCart.confirmationDialog.buttons.confirm')}
              onClick={handlePlaceOrder}
              disabled={loading}
            />
            <Button
              className={style.dialogButton}
              border
              text={t('ui.dineInMiniCart.confirmationDialog.buttons.cancel')}
              onClick={onConfirmationDialogRequestClose}
              disabled={loading}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DineInMiniCartView
