/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrencies } from 'react-omnitech-api'
import CustomLabels from '../custom-labels'
import FavouriteButton from '../favourite-button'
import Link from '../link'
import Checkbox from '../checkbox'
import Image from './components/image'
import PriceList from '../price-list'
import SwatchList from './components/swatch-list'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './product-card-style'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductCardView = (props) => {
  const {
    activeCustomLabels,
    className,
    contentClassName,
    disableNavigation,
    upperContentClassName,
    thumbnailClassName,
    thumbnailImageClassName,
    favouriteButtonClassName,
    priceListContainerClassName,
    priceListClassName,
    colorName,
    colorOptionId,
    colorOptionVariantType = {},
    compareData,
    enableComparisonEcom,
    favourite,
    favouritesEnabled,
    firstSkuOfSelectedColorOption,
    hasMoreThan,
    imageUrl,
    inventoryStoreCode,
    maxNoOfCustomLabel,
    memberPrice,
    onAddToCompare,
    onClick,
    onFavouriteChange,
    onSwatchItemClick,
    onSwatchMoreClick,
    originalPrice,
    productDetailUrl,
    priceSuffix,
    priceLoading,
    sellPrice,
    showSwatches,
    sku,
    stockLevel,
    title,
    detailsPlainText,
  } = props

  // prepare hook
  const { t } = useTranslation()
  const { currencies } = useCurrencies()
  const currency = _.find(currencies, { isBaseCurrency: true })

  const showCompareCheckbox = compareData && enableComparisonEcom

  const styles = useStyles()

  return (
    <div
      className={combineClassNames([
        styles.productCard,
        showCompareCheckbox && styles.productCardCompare,
        className,
      ])}
    >
      <div
        className={combineClassNames([styles.upperContent, upperContentClassName])}
      >
        <figure
          className={combineClassNames([styles.figure, thumbnailClassName])}
        >
          {
            favouritesEnabled && (
              <div
                className={combineClassNames([
                  styles.favouriteButton,
                  favouriteButtonClassName,
                ])}
              >
                <FavouriteButton
                  colorOptionId={colorOptionId}
                  favourite={favourite}
                  onChange={onFavouriteChange}
                  icon
                />
              </div>
            )
          }
          <Link
            to={disableNavigation ? null : productDetailUrl}
            onClick={onClick}
            className={styles.link}
            gatsbyLink={!disableNavigation}
          >
            <Image
              alt={title}
              containerClassName={combineClassNames([styles.image, thumbnailImageClassName])}
              containerNoImageClassName={combineClassNames([styles.noImage])}
              src={imageUrl}
            />
          </Link>
        </figure>
        <Link
          to={disableNavigation ? null : productDetailUrl}
          onClick={onClick}
          className={combineClassNames([styles.link, styles.content, contentClassName])}
          gatsbyLink={!disableNavigation}
        >
          <div className={styles.customLabelsContainer}>
            <CustomLabels
              className={styles.customLabels}
              stockLevel={stockLevel}
              customLabels={_.take(activeCustomLabels, maxNoOfCustomLabel)}
              frontendPosition="1"
            />
          </div>

          {/* TODO: replace it with MultilineTitle, using `|` as line break */}
          <p
            className={combineClassNames([
              styles.contentText,
              styles.title,
            ])}
          >
            {title}
          </p>
          {/* TODO: in `p` level, show sku.product.defaultColorOption.name */}
          {/* TODO: in `pc` level, show sku.colorOption.name */}
          {!colorOptionVariantType.hideVariant && (
            <p
              className={combineClassNames([
                styles.contentText,
                styles.colorSelect,
              ])}
            >
              {colorName}
            </p>
          )}
          {
            !_.isEmpty(detailsPlainText) && (
              <p
                className={combineClassNames([
                  styles.contentText,
                  styles.details,
                ])}
              >
                {detailsPlainText}
              </p>
            )
          }
          {
            priceLoading
              ? (
                <></>
              )
              : (
                <div
                  className={
                    combineClassNames([
                      styles.priceContainer,
                      priceListContainerClassName,
                    ])
                  }
                >
                  <PriceList
                    className={combineClassNames([styles.price, priceListClassName])}
                    currency={currency}
                    // Show price for member if rank price provided by sku.meta
                    items={[
                      {
                        ...(!_.isNil(memberPrice) && { label: t('screens.product.price.nonMember') }),
                        sellPrice,
                        originalPrice,
                        suffix: priceSuffix,
                      },
                      ...!_.isNil(memberPrice)
                        ? [{
                          label: t('screens.product.price.member'),
                          sellPrice: memberPrice,
                          suffix: priceSuffix,
                        }] : [],
                    ]}
                    // align="center"
                  />
                </div>
              )
          }
        </Link>
      </div>
      {
        showSwatches && (
          <SwatchList
            sku={sku}
            inventoryStoreCode={inventoryStoreCode}
            onItemClick={onSwatchItemClick}
            onMoreClick={onSwatchMoreClick}
            selectedColorOptionId={colorOptionId}
          />
        )
      }
      {
        showCompareCheckbox
          && (
          <Checkbox
            wrapperStyle={styles.checkbox}
            frontStyle={styles.checkboxFront}
            labelStyle={styles.checkboxLabel}
            checked={_.find(compareData, (obj) => obj.id === _.get(firstSkuOfSelectedColorOption, 'id', sku.id))}
            onChange={() => onAddToCompare(firstSkuOfSelectedColorOption || sku)}
            disabled={hasMoreThan && !_.find(compareData, (obj) => obj.id === sku.id)}
          >
            <p className={styles.textCheckbox}>{t('ui.productCard.compare')}</p>
          </Checkbox>
          )
      }
    </div>
  )
}

export default ProductCardView
