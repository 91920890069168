import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  label: {
    marginLeft: 'var(--ui-spacer-l)',
    fontSize: '1.4rem',
    lineHeight: 1.43,
    margin: 0,
    flexGrow: 0,
    flexShrink: 1,
  },
  checkbox: {
    position: 'absolute',
    opacity: 0,
    '&:checked + $front:after': {
      display: 'block',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: '0.2rem',
  },
  wrapperDisabled: {
    opacity: 0.4,
  },
  front: {
    position: 'relative',
    cursor: 'pointer',
    width: '2rem',
    height: '2rem',
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: '2rem',
    transform: 'translateY(0.2rem)',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: `1px solid ${theme.colors.primary}`,
    },
    '&:after': {
      content: '""',
      display: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) scale(0.6)',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: theme.colors.secondary,
    },
  },
  checkboxError: {
    '& $front': {
      '&:before': {
        borderColor: theme.colors.alert,
      },
    },
  },
}))

export default useStyles
