import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({

  paymentLogos: {
    textAlign: 'center',
    padding: '0 2rem',
    '& h4': {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: 1.25,
      marginBottom: '2.8rem',
    },
  },
  boxLogos: {
    textAlign: 'center',
    '& h5': {
      textAlign: 'center',
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: 1.25,
      marginBottom: '2.8rem',
      textTransform: 'capitalize',
    },
    '& ul': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      alignItems: 'center',
      listStyle: 'none',
      margin: 0,
      '& li': {
        width: '5.5rem',
        height: '3.2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.colors.gray300}`,
        borderRadius: '0.4rem',
        margin: '0.5rem 0.5rem',
        '& img': {
          margin: 0,
        },
      },
    },
  },

  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    paymentLogos: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    boxLogos: {
      '& ul': {
        '& li': {
          width: '4.8rem',
          height: '2.8rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${theme.colors.gray300}`,
          borderRadius: '0.4rem',
          margin: '0 0.5rem',
        },
      },
    },
  },
}))

export default useStyles
