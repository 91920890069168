import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    background: theme.colors.background,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: '-100vh',
    transition: 'bottom 300ms',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      right: -500,
      transition: 'right 300ms',
      width: 500,
    },
  },
  containerOpen: {
    bottom: '0',
    transition: 'bottom 300ms',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      right: 0,
      transition: 'right 300ms',
    },
  },
  body: {
    alignSelf: 'stretch',
    flex: 1,
    overflowY: 'auto',
    background: theme.colors.light,
  },
  bodyHasMore: {
    paddingBottom: '10rem',
  },
}))

export default useStyles
