import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(
  () => ({
    wrapper: {
      position: 'fixed',
      zIndex: 99999,
      top: 0,
      overflow: 'visible',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    alert: {
      position: 'relative',
      overflow: 'hidden',
      padding: '1.6rem 4rem 1.6rem 1.6rem',
      margin: '1rem',
      // minWidth: '16rem',
      minWidth: 'calc(100% - 2rem)',
      color: 'var(--ui-alert-default-text-color)',
      backgroundColor: 'var(--ui-alert-default-background-color)',
      borderColor: 'var(--ui-alert-default-border-color)',
      borderWidth: 'var(--ui-alert-default-border-width)',
      borderRadius: 'var(--ui-alert-default-border-radius)',
      borderStyle: 'solid',
    },
    alertErorr: {
      color: 'var(--ui-alert-error-text-color)',
      backgroundColor: 'var(--ui-alert-error-background-color)',
      borderColor: 'var(--ui-alert-error-border-color)',
      borderWidth: 'var(--ui-alert-error-border-width)',
      borderRadius: 'var(--ui-alert-error-border-radius)',
    },
    alertSuccess: {
      color: 'var(--ui-alert-success-text-color)',
      backgroundColor: 'var(--ui-alert-success-background-color)',
      borderColor: 'var(--ui-alert-success-border-color)',
      borderWidth: 'var(--ui-alert-success-border-width)',
      borderRadius: 'var(--ui-alert-success-border-radius)',
    },
    message: {
      fontSize: '1.6rem',
      fontWeight: 'normal',
      lineHeight: '1.6rem',
      letterSpacing: '0.05rem',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '1.6rem',
      cursor: 'pointer',
      outline: 'none',
      '& img': {
        marginBottom: 0,
      },
    },
    cross: {
      margin: 0,
      display: 'block',
      '& path': {
        fill: 'currentColor',
      },
    },
  }),
)

export default useStyles
