import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '3rem',
  },
  headerComponent: {
    flexShrink: 1,
    flexGrow: 1,
  },
  headerControls: {
    flexShrink: 0,
    paddingLeft: theme.gap,
  },
  clearButton: {
    textTransform: 'none',
  },
}))

export default useStyles
