/* eslint-disable max-len */
import _ from 'lodash'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import {
  cancelRequest,
  useBackInStockNotifications,
  useI18n,
  useProducts,
  useSkus,
  useStores,
  // useSystemSettings,
} from 'react-omnitech-api'
import useSku from '../use-sku'
import useCart from '../use-cart'
import useOrderMethod from '../use-order-method'
import usePriceTemplate from '../use-price-template'
import { useThemeConfig } from '../use-theme-config'

import parseStockLevel from '../../helpers/parse-stock-level'
import groupAddonsWithQuantity from '../../helpers/group-addons-with-quantity'
import getNestedAddonSkus from '../../helpers/get-nested-addon-skus'

const findProductAddonById = ({ productAddons = [], id }) => {
  const pa = _.find(productAddons, { id })
  if (_.isEmpty(pa)) { // Not found
    const nestedProductAddons = _.flatMapDeep(productAddons, ({ addons: _addons }) => _.map(_addons, 'productAddons'))
    if (_.isEmpty(nestedProductAddons)) return {}
    return findProductAddonById({ productAddons: nestedProductAddons, id })
  }
  return pa
}

export default function useProduct(id, options = {}) {
  const {
    // ['noCache', 'addons', 'siblings', 'reviews'],
    includes = ['noCache', 'addons', 'siblings'],
    cartLinePropertiesGroupUuid,
    defaultSelectedColorOptionId,
    defaultSelectedSizeOptionId,
  } = options
  const [tasks, setTasks] = useState([])
  const [product, setProduct] = useState({})
  const [productAddons, setProductAddons] = useState([])
  const [seoMetas, setSeoMetas] = useState([])
  const [siblings, setSiblings] = useState([])
  const [productQuantity, setProductQuantity] = useState(1)
  // const [storeMenuCodes, setStoreMenuCodes] = useState([])
  const [selectedColorOptionId, setSelectedColorOptionId] = useState(defaultSelectedColorOptionId)
  const [selectedSizeOptionId, setSelectedSizeOptionId] = useState(defaultSelectedSizeOptionId)
  const [addonsValue, setAddonsValue] = useState([])
  const [addonsTouched, setAddonsTouched] = useState([])
  const [isAddonsValid, setIsAddonsValid] = useState(false)
  const [isSkusValid, setIsSkusValid] = useState(false)
  const [addToCartButtonState, setAddToCartButtonState] = useState('loading')

  const { getConfig } = useThemeConfig()
  const {
    stores,
  } = useStores()
  const { fetchProduct } = useProducts()
  const { fetchSkus } = useSkus()
  const {
    createCart,
    fetchCart,
    initCart,
    updateCart,
    updateStagingCart,
    inventoryStoreCode: useCartInventoryStoreCode,
    getParams,
    getSkuTotalQuantityInCart,
  } = useCart()
  const { createBackInStockNotifications } = useBackInStockNotifications()
  const {
    orderMethod,
    store,
  } = useOrderMethod()
  const PRICE_TEMPLATE_KEY = _.get(usePriceTemplate(), 'code')

  const {
    // isAllowToCheckoutAll,
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
    isAllowToCheckoutAllAsync,
  } = useSku()
  const { currentLanguage } = useI18n()
  // const { getSystemSetting } = useSystemSettings()

  // const fnbEnabled = getSystemSetting('features.fnb.enable')
  const enableBackInStockNotification = getConfig('config.pages.product.enableBackInStockNotification', false)
  const enableAddToCart = _.get(orderMethod, 'enableAddToCart', true) !== false

  const reset = () => {
    setProductQuantity(1)
    setSelectedColorOptionId(defaultSelectedColorOptionId)
    setSelectedSizeOptionId(defaultSelectedSizeOptionId)
    setAddonsValue([])
    setAddonsTouched([])
    setIsAddonsValid(false)
    setIsSkusValid(false)
  }

  const cleanUp = () => {
    setTasks([])
    setProduct({})
    setProductAddons([])
    setSeoMetas([])
    setSiblings([])
    setAddToCartButtonState('loading')
  }

  const convertSkusToProducts = (skus) => {
    const groupedSkus = _.groupBy(skus, 'product.id')
    // return products
    return _.map(groupedSkus, (_skus) => ({
      ..._.get(_.first(_skus), 'product', {}),
      skus: _.map(_skus, (sku) => _.omit(sku, ['product'])),
    }))
  }

  const updateTask = (keys, state, params = {}) => {
    if (_.isEmpty(keys)) return
    const keysInArray = _.castArray(keys)
    setTasks((prevTasks) => ([
      // remove existing tasks
      ..._.reject(prevTasks, ({ key: prevKey }) => _.includes(keysInArray, prevKey)),
      // add tasks by keys
      ..._.map(keysInArray, (key) => ({
        // defaults
        key,
        state: state || 'loading',
        ...params,
      })),
    ]))
  }

  const addTask = (keys, params = {}) => {
    if (_.isEmpty(keys)) return
    updateTask(keys, 'loading', params)
  }

  const removeTask = (keys) => {
    if (_.isEmpty(keys)) return
    const keysInArray = _.castArray(keys)
    setTasks((prevTasks) => ([
      // remove existing tasks
      ..._.reject(prevTasks, ({ key: prevKey }) => _.includes(keysInArray, prevKey)),
    ]))
  }

  const initTask = () => {
    setTasks([
      {
        key: 'init',
        state: 'loading',
      },
      {
        key: 'noCache',
        state: 'loading',
      },
    ])
  }

  const getTask = useCallback((key) => (
    _.find(tasks, { key })
  ), [tasks])
  const getTaskState = useCallback((key) => (
    _.get(
      getTask(key),
      'state',
    )
  ), [getTask])

  const loadings = useMemo(() => (
    _.map(
      _.filter(
        tasks,
        ({ state }) => state === 'loading',
      ),
      'key',
    )
  ), [tasks])

  // export all states individually for use in useEffect dependent
  // => { 'initState': 'success', 'cartState': 'loading', ... }
  const taskStates = useMemo(() => (
    _.zipObject(
      _.map(tasks, ({ key }) => _.camelCase(`${key}_state`)),
      _.map(tasks, 'state'),
    )
  ), [tasks])

  const addItemsToCartState = useMemo(() => (
    _.get(taskStates, 'addItemsToCartState')
  ), [taskStates])

  const orderMethodCode = useMemo(() => (
    _.get(orderMethod, 'code')
  ), [orderMethod])
  const orderMethodCommerceType = useMemo(() => (
    _.get(orderMethod, 'commerceType')
  ), [orderMethod])
  const orderMethodCommerceChannel = useMemo(() => (
    _.get(orderMethod, 'commerceChannel')
  ), [orderMethod])
  const siblingsProductCodes = useMemo(() => (
    _.get(product, 'meta.siblingsProductCodes', [])
  ), [product])
  const productAddonIds = useMemo(() => (
    _.get(product, 'productAddonIds', [])
  ), [product])
  const inventoryStoreCode = useMemo(() => {
    if (orderMethodCode === 'dineIn') {
      const physicalStoreId = _.get(initCart, 'physicalStoreId')
      const physicalStoreCode = _.get(_.find(stores, { id: physicalStoreId }))
      return physicalStoreCode || _.get(store, 'code')
    }
    return useCartInventoryStoreCode
  }, [initCart, orderMethodCode, stores, store, useCartInventoryStoreCode])
  const inventoryStoreId = useMemo(() => {
    if (orderMethodCode === 'dineIn') {
      const physicalStoreId = _.get(initCart, 'physicalStoreId')
      return physicalStoreId || _.get(store, 'id')
    }
    return _.get(initCart, 'cartShipments.0.inventoryStore.id')
  }, [initCart, orderMethodCode, store])

  const cartLinePropertiesToEdit = useMemo(
    () => (_.filter(
      _.get(initCart, 'cartLineProperties', []),
      ['groupUuid', cartLinePropertiesGroupUuid || '/////'],
    )),
    [initCart, cartLinePropertiesGroupUuid],
  )

  const selectedSibling = useMemo(() => {
    const selectedValue = _.find(
      addonsValue,
      ({ productAddonId, quantity }) => (
        _.isNull(productAddonId)
        && quantity > 0
      ),
    )
    return _.find(siblings, { id: _.get(selectedValue, 'addonId') })
  }, [siblings, addonsValue])
  const selectedMainProduct = useMemo(() => (
    _.isEmpty(_.get(product, 'meta.siblingsProductCodes'))
      ? product
      : selectedSibling
  ), [product, selectedSibling])
  const selectedSkus = useMemo(() => {
    let skus = _.filter(_.get(product, 'skus', []), {
      colorOptionId: selectedColorOptionId,
      sizeOptionId: selectedSizeOptionId,
    })
    if (!_.isEmpty(productAddons)) {
      const selectedAddons = _.filter(addonsValue, ({ quantity }) => quantity > 0)
      const selectedAddonSkus = _.filter(
        getNestedAddonSkus(productAddons),
        ({ id: productAddonSkuId }) => (
          _.includes(_.map(selectedAddons, 'skuId'), productAddonSkuId)
        ),
      )
      if (!_.isEmpty(selectedAddonSkus)) {
        skus = _.uniqBy([
          ...skus,
          ...selectedAddonSkus,
        ], 'id')
      }
    }
    return skus
  }, [
    // getTaskState,
    product,
    productAddons,
    addonsValue,
    selectedColorOptionId,
    selectedSizeOptionId,
  ])

  const storeId = useMemo(() => (
    _.get(initCart, 'physicalStoreId')
    || _.get(orderMethod, 'store.id')
    || _.get(initCart, 'cartShipments.0.inventoryStore.id')
  ), [initCart, orderMethod])

  const displayStockLevel = useMemo(() => {
    let skus = []
    skus = _.filter(_.get(product, 'skus', []), { colorOptionId: selectedColorOptionId })
    if (!_.isNil(selectedSizeOptionId)) {
      skus = _.filter(skus, { sizeOptionId: selectedSizeOptionId })
    }
    if (!_.isEmpty(siblings)) {
      skus = _.flatMap(siblings, 'skus')
    }
    if (!_.isEmpty(selectedSibling)) {
      skus = _.get(selectedSibling, 'skus', [])
    }
    return _.sum(_.map(skus, ({ stockLevel }) => parseStockLevel(stockLevel)))
  }, [
    product,
    selectedColorOptionId,
    selectedSibling,
    selectedSizeOptionId,
    siblings,
  ])

  const isOutOfStock = useMemo(() => (
    displayStockLevel <= 0
  ), [displayStockLevel])

  const noPrice = useMemo(() => (
    _.some(_.map(selectedSkus, 'sellPrice'), _.isNull)
  ), [selectedSkus])

  const updateCartActions = useMemo(() => {
    const sku = _.find(_.get(product, 'skus', []), { colorOptionId: selectedColorOptionId, sizeOptionId: selectedSizeOptionId })
    // FL: For Non-fnb use `add_main_cart_line_property` as actionType
    if (
      !_.includes(['takeAway', 'dineIn'], orderMethodCommerceType)
      && _.isEmpty(product, 'meta.siblingsProductCodes') // NO siblings
      && _.isEmpty(_.get(product, 'productAddonIds', [])) // NO addons
    ) {
      return [{
        actionType: 'add_main_cart_line_property',
        skuId: _.get(sku, 'id'),
        quantity: productQuantity,
        quantityMode: 'increment',
      }]
    }

    const main = _.find(addonsValue, ['productAddonId', null])
      || {
        skuId: _.get(sku, 'id'),
        skuCode: _.get(sku, 'code'),
        productAddonId: null,
        addonId: _.get(product, 'id'), // for item update
        quantityIndex: 0,
        title: _.get(product, 'title'), // for staging cart display
      }
    const others = _.reject(addonsValue, ['productAddonId', null])
    const commonActionProps = {
      actionType: 'update_cart_line_property',
      quantityMode: 'fixed',
    }
    const groupUuid = uuidv4()
    const combinedActions = [
      {
        ...main,
        ...commonActionProps,
        groupUuid,
        parentIdentifierUuid: null,
        quantity: productQuantity,
        // salesRemark: noteToChefText
      },
      ..._.flatMap(others, ({ quantity: qty, ...addon }) => (
        _.times(qty, (quantityIndex) => ({
          ...addon,
          ...commonActionProps,
          groupUuid,
          quantity: 1,
          quantityIndex,
        }))
      )),
    ]

    // Sort actions by parents count to make sure parentIdentifierUuid can be found
    const sortedActions = _.sortBy(combinedActions, ({ parents = [] }) => _.size(parents))

    const actionsWithIdentifierUuid = _.reduce(sortedActions, (result, action) => {
      const {
        parents = [],
        productAddonId,
        groupUuid: actionGroupUuid,
      } = action
      const parent = _.last(parents)
      const actionWithIdentifierUuid = {
        ...action,
        identifierUuid: uuidv4(),
      }
      if (_.isNull(productAddonId)) { // main
        result.push(actionWithIdentifierUuid)
      } else if (_.isEmpty(parent)) {
        result.push({
          ...actionWithIdentifierUuid,
          parentIdentifierUuid: _.get(_.find(result, { productAddonId: null, groupUuid: actionGroupUuid }), 'identifierUuid'),
        })
      } else {
        result.push({
          ...actionWithIdentifierUuid,
          parentIdentifierUuid: _.get(
            _.find(result, {
              ..._.pick(parent, [
                'productAddonId',
                'addonId',
                'skuId',
                'quantityIndex',
              ]),
              groupUuid,
            }),
            'identifierUuid',
          ),
        })
      }
      return result
    }, [])

    if (orderMethodCommerceType === 'dineIn') {
      return actionsWithIdentifierUuid
    }

    const actions = _.map(
      actionsWithIdentifierUuid,
      (action) => (
        _.pick(action, [
          'actionType',
          'groupUuid',
          'identifierUuid',
          'parentIdentifierUuid',
          'productAddonId',
          'quantity',
          'quantityMode',
          'skuId',
          'customerRemark',
        ])
      ),
    )

    // Remove Previous items when updating cart item
    const actionToRemoveMain = !_.isEmpty(cartLinePropertiesToEdit)
      ? [{
        ...commonActionProps,
        quantity: 0,
        productAddonId: null,
        skuId: _.toInteger(_.get(_.find(cartLinePropertiesToEdit, 'isMainProperty'), 'sku.id')),
        ..._.pick(_.find(cartLinePropertiesToEdit, 'isMainProperty'), [
          'groupUuid',
          'identifierUuid',
          'parentIdentifierUuid',
        ]),
      }]
      : []

    return [
      ...actionToRemoveMain,
      ...actions,
    ]
  }, [
    addonsValue,
    cartLinePropertiesToEdit,
    orderMethodCommerceType,
    product,
    productQuantity,
    selectedColorOptionId,
    selectedSizeOptionId,
  ])

  // const validateSkus = useCallback(async () => {
  //   const exclusions = _.get(orderMethod, 'pdpSkuCheckExclusions', {})
  //   try {
  //     const result = await isAllowToCheckoutAllAsync({ skus: selectedSkus, exclusions, storeId })
  //     setIsSkusValid(result)
  //   } catch (error) {
  //     // fail silently
  //   }
  // }, [selectedSkus, orderMethod, storeId])
  const validateSkus = async () => {
    const exclusions = _.get(orderMethod, 'pdpSkuCheckExclusions', {})
    try {
      const result = await isAllowToCheckoutAllAsync({ skus: selectedSkus, exclusions, storeId })
      setIsSkusValid(result)
      updateTask('skusValidation', 'success')
    } catch (error) {
      updateTask('skusValidation', 'failure', { error })
      // fail silently
    }
  }

  const addRecentlyViewed = useCallback(
    ({
      colorOptionId,
      productId,
      url,
    }) => {
      try {
        const data = JSON.parse(window.localStorage.getItem('recentlyViewedProducts'))
        const recentlyViewedProducts = _.get(data, orderMethodCommerceChannel, [])
        const updatedList = [
          {
            colorOptionId,
            productId,
            url,
            updatedAt: moment().format(),
          },
          ..._.take(
            _.reject(recentlyViewedProducts, { productId }),
            99, // limit to 100 items
          ),
        ]
        window.localStorage.setItem(
          'recentlyViewedProducts',
          JSON.stringify({
            ...data,
            [orderMethodCommerceChannel]: updatedList,
          }),
        )
      } catch (error) {
        // fail silently
      }
    },
    [
      orderMethodCommerceChannel,
    ],
  )

  const fetchCartApi = useCallback(async () => {
    try {
      const option = {
        params: {
          // schemaVersion: '2021-04-29',
          includes: [
            'cart_line_properties',
            'cart_line_properties.color_option',
            'cart_line_properties.sku',
            'cart_line_properties.product',
            'cart_line_properties.product_addon',
            'cart_shipments',
            'cart_shipments.inventory_store',

            'skus.color_option',
            'skus.product',
            'skus.size_option',
            'skus.stock_level',
          ].join(','),
          refresh_cart: true,
        },
      }
      // call api
      await fetchCart(option)
      // setFetchCartForEditReady(true)
      updateTask('cart', 'success')
    } catch (error) {
      updateTask('cart', 'failure', { error })
      // const generalError = _.get(error, 'generalError', {})
      // if (generalError.code === 404) {
      //   recordNotFound(currentPage)
      //   return
      // }
      // alert.show(generalError.message)
    }
  }, [fetchCart])

  /**
   * fetchProductApi
   * get product data from API
   */
  const fetchProductApi = async () => {
    try {
      // api call option
      setProduct({})
      const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const option = {
        id,
        includes: [
          'category_ids',
          'color_option_variant_type',
          'color_options.images',
          'color_options.meta',
          'color_options',
          'default_color_option_id',
          'meta',
          'product_addon_ids',
          'product_detail_attributes',
          'reviews_summary',
          'reviews.user_default',
          'share_details',
          'size_option_variant_type',
          'size_options',
          'skus.color_option_id',
          'skus.meta',
          'skus.size_option_id',
          'skus',
        ].join(','),
        ...menuFiltersParam, // for 'product_addon_ids'
        ...menuCodeFiltersParam, // for 'product_addon_ids'
      }
      // call api
      const { product: data, seoMetas: seoMetasData } = await fetchProduct(option)
      setProduct(data)
      setSeoMetas(seoMetasData)
      updateTask('init', 'success')
    } catch (error) {
      updateTask('init', 'failure', { error })
      // const generalError = _.get(error, 'generalError', {})
      // if (generalError.code === 404) {
      //   recordNotFound(currentPage)
      //   return
      // }
      // alert.show(generalError.message)
    }
  }

  /**
   * fetchProductDetailsApi
   * get product details for non cached data
   */
  const fetchProductDetailsApi = async () => {
    if (_.isEmpty(inventoryStoreCode)) return
    const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
    const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
    try {
      // api call option
      const option = {
        id,
        includes: [
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.price_details',
          'color_options.stock_level',
          'color_options',
          'skus.price_details',
          'skus.stock_level',
          'skus',
          'stock_level',
        ].join(','),
        inventoryStoreCodeEq: inventoryStoreCode,
        priceStoreCodeEq: inventoryStoreCode,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { product: data } = await fetchProduct(option)
      // Merge product data
      setProduct((p) => {
        const { colorOptions, skus } = p
        return {
          ...p,
          colorOptions: _.map(colorOptions, (co) => {
            const colorOptionDetail = _.find(_.get(data, 'colorOptions', []), { id: co.id })
            return _.merge({}, co, colorOptionDetail)
          }),
          skus: _.map(skus, (sku) => {
            const skuDetail = _.find(_.get(data, 'skus', []), { id: sku.id })
            return _.merge({}, sku, skuDetail)
          }),
          stockLevel: _.get(data, 'stockLevel', null),
        }
      })
      updateTask('noCache', 'success')
    } catch (error) {
      updateTask('noCache', 'failure', { error })
    //   const generalError = _.get(error, 'generalError', {})
    //   if (generalError.code === 404) {
    //     recordNotFound(currentPage)
    //     return
    //   }
    //   alert.show(generalError.message)
    // } finally {
    //   setProductReady(true)
    }
  }

  const fetchProductAddonsApi = async () => {
    try {
      // api call option
      setProductAddons([])
      const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const option = {
        id,
        schemaVersion: '2021-04-29',
        includes: [
          'addons.color_options',
          'addons.nested_product_addons',
          'addons.price_details',
          'addons.skus',
          'color_options.price_details',
          'color_options.stock_level',
          'color_options',
          'default_color_option_id',
          'meta',
          'product_addons.addons',
          'product_addons.meta',
          'product_addons',
          'size_options',
          'skus.color_option_id',
          'skus.meta',
          'skus.price_details',
          'skus.size_option_id',
          'skus.stock_level',
          'skus',
          // CP: required for hide variants to work!
          'color_option_variant_type',
          'size_option_variant_type',
        ].join(','),
        inventoryStoreCodeEq: inventoryStoreCode,
        priceStoreCodeEq: inventoryStoreCode,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { product: data, seoMetas: seoMetasData } = await fetchProduct(option)
      setProductAddons(_.get(data, 'productAddons', []))
      setSeoMetas(seoMetasData)
      updateTask('addons', 'success')
    } catch (error) {
      updateTask('addons', 'failure', { error })
    }
  }

  const fetchSiblings = async () => {
    if (_.isEmpty(siblingsProductCodes)) return
    try {
      // api call option
      setProductAddons([])
      const menuFiltersParam = getMetaMenuFilterParams()
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync()
      const option = {
        productCodeEq: siblingsProductCodes,
        pageCountless: true,
        schemaVersion: '2021-04-29',
        includes: [
          'addons.color_options',
          'addons.nested_product_addons',
          'addons.price_details',
          'addons.skus',
          'product_addons.addons',
          'product_addons.meta',
          'products.color_options',
          'products.meta',
          'products.product_addons',
          // 'skus.checkout_settings',
          'skus.color_option_id',
          'skus.meta',
          'skus.price_details',
          'skus.product',
          'skus.stock_level',
        ].join(','),
        inventoryStoreCodeEq: inventoryStoreCode,
        priceStoreCodeEq: inventoryStoreCode,
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
      }
      // call api
      const { skus } = await fetchSkus(option)
      const products = convertSkusToProducts(skus)
      const sortedProducts = _.sortBy(products, 'meta.siblingsDisplaySequence')
      setSiblings(sortedProducts)
      const siblingsTitle = _.get(product, 'meta.siblingsTitle')
        || _.get(product, 'meta.addonHeader.title')
      setProductAddons([
        {
          id: null,
          addons: sortedProducts,
          meta: siblingsTitle ? {
            addonHeader: {
              title: siblingsTitle,
            },
          } : {},
          maximumSkuSelect: 1,
          minimumSkuSelect: 1,
          maximumPerSkuQuantity: 1,
        },
      ])
      updateTask('siblings', 'success')
    } catch (error) {
      updateTask('siblings', 'failure', { error })
    }
  }

  /**
   * apiCreateCart
   * Call API to create a new cart
   */
  async function createCartApi() {
    const opts = {
      params: getParams({
        includeGroups: ['basic'],
      }),
    }
    return createCart(opts)
  }
  const updateCartApi = async ({ cartId: _cartId, data = {} }) => {
    try {
      const updatedCartData = await updateCart({
        cartId: _cartId,
        payload: {
          data,
          batchUpdateMode: 2,
        },
        params: {
          includes: [
            'cart_line_properties',
            'cart_line_properties.sku',
            'cart_shipments',
            'cart_shipments.inventory_store',
            'price_details', // for tracking purposes
          ],
          priceTemplate: PRICE_TEMPLATE_KEY,
          refreshCart: true,
        },
      })
      return updatedCartData
    } catch (error) {
      if (_.get(error, 'generalError.code') === 404) {
        const newCartData = await createCartApi()
        // retry
        return updateCartApi({ cartId: _.get(newCartData, 'cart.id'), data })
      }
      throw error
    }
  }

  /**
   * Update product when favourite changed
   */
  const onFavouriteChange = ({ colorOptionId, favourite }) => {
    setProduct((origProduct) => {
      const origColorOptions = _.get(origProduct, 'colorOptions', [])
      const colorOptionIndex = _.findIndex(origColorOptions, { id: colorOptionId })
      const updatedColorOptions = _.set(origColorOptions, [colorOptionIndex, 'favourite'], favourite)
      return {
        ...origProduct,
        colorOptions: updatedColorOptions,
      }
    })
  }

  const onColorOptionChange = (colorOptionId) => {
    setSelectedColorOptionId(colorOptionId)
    setSelectedSizeOptionId(undefined)
  }
  const onSizeOptionChange = (sizeOptionId) => {
    setSelectedSizeOptionId(sizeOptionId)
  }

  const onAddonsChange = (newValue, touched) => {
    setAddonsValue(newValue)
    setAddonsTouched(touched)
  }

  const onAddonsUpdate = (newValue) => {
    setIsAddonsValid(_.get(newValue, 'isValidCustomerRemark'))
  }

  const onAddonsValidate = (valid) => {
    setIsAddonsValid(valid)
  }

  const onProductQuantityChange = (value) => {
    setProductQuantity(value)
  }

  const onAddToCart = () => {
    if (
      getTaskState('addItemsToCart') !== 'loading'
    ) {
      addTask('addItemsToCart')
    }
  }

  const onBackInStockNotification = (params = {}) => {
    if (
      getTaskState('backInStockNotification') !== 'loading'
    ) {
      addTask('backInStockNotification', { params })
    }
  }

  const addItemsToCart = async () => {
    // setDisplayAddonsErrors(false)
    // Start Add Items to cart
    if (orderMethodCommerceType === 'dineIn') {
      try {
        await updateStagingCart({
          actions: updateCartActions,
        })
        updateTask('addItemsToCart', 'success')
      } catch (error) {
        updateTask('addItemsToCart', 'failure', { error })
      }
      return
    }

    try {
      const {
        cart: data,
      } = await updateCartApi({
        cartId: _.get(initCart, 'id'),
        data: {
          actions: updateCartActions,
        },
      })
      updateTask('addItemsToCart', 'success', { cart: data, actions: updateCartActions })
      return data
    } catch (error) {
      updateTask('addItemsToCart', 'failure', { error })
    }
  }

  const backInStockNotification = async (params = {}) => {
    try {
      // api call option
      // {
      //   "back_in_stock_notification": {
      //     "store_id": 6,
      //     "target_item_type": "Sku",
      //     "target_item_id": "105",
      //     "all_store": false,
      //     "frontend_url_params": { }
      //   }
      // }
      // call api
      await createBackInStockNotifications({
        targetItemType: 'Product',
        targetItemId: _.toString(_.get(product, 'id', id)),
        allStore: true,
        storeId: inventoryStoreId,
        ...params,
      })
      updateTask('backInStockNotification', 'success')
    } catch (error) {
      updateTask('backInStockNotification', 'failure', { error })
    }
  }

  const availableQuantity = useMemo(() => {
    // [TODO] handle dine-in cart
    const productReady = !_.includes(loadings, 'init')
      && !_.includes(loadings, 'noCache')
      && !_.includes(loadings, 'addons')
      && !_.includes(loadings, 'siblings')
    if (!productReady || !selectedMainProduct) return Infinity
    const cartLineProperties = _.get(initCart, 'cartLineProperties', [])

    const sku = _.find(_.get(product, 'skus', []), { colorOptionId: selectedColorOptionId, sizeOptionId: selectedSizeOptionId })
    const main = _.find(addonsValue, ['productAddonId', null])
      || { skuId: _.get(sku, 'id'), stockLevel: _.get(sku, 'stockLevel') }
    const mainSkuId = _.get(main, 'skuId')
    const mainStockLevel = parseStockLevel(_.get(main, 'stockLevel'))
    const mainQuantityInCart = getSkuTotalQuantityInCart({ skuId: mainSkuId, reject: { groupUuid: cartLinePropertiesGroupUuid } })
    const totalItemsQuantityInCart = _.sumBy(_.filter(cartLineProperties, 'isMainProperty'), 'quantity')
    const others = _.filter(addonsValue, ({ productAddonId }) => !_.isNull(productAddonId))
    const stockLevelsAffectedByAddons = _.map(others, ({
      skuId,
      stockLevel: addonStockLevel,
      quantity: qty,
    }) => {
      const addonsQuantityInCart = getSkuTotalQuantityInCart({ skuId, reject: { groupUuid: cartLinePropertiesGroupUuid } })
      const remainStockLevel = parseStockLevel(addonStockLevel) - addonsQuantityInCart
      return _.floor((remainStockLevel / qty) || 0)
    })
    const cartMaxNumOfQtyPerSku = _.get(initCart, 'cartMaxNumOfQtyPerSku', Infinity)
    const cartMaxNumOfQty = _.get(initCart, 'cartMaxNumOfQty', Infinity)
    const maxQty = _.min([
      cartMaxNumOfQtyPerSku - mainQuantityInCart,
      cartMaxNumOfQty - totalItemsQuantityInCart,
      mainStockLevel - mainQuantityInCart,
      ...stockLevelsAffectedByAddons,
    ])
    return maxQty
  }, [
    addonsValue,
    cartLinePropertiesGroupUuid,
    initCart,
    loadings,
    product,
    selectedMainProduct,
    selectedSizeOptionId,
    siblings,
  ])

  const getAddonAvailableQuantity = useCallback((sku) => {
    if (_.includes(loadings, 'addons') || _.isEmpty(sku)) return Infinity
    const skuId = _.get(sku, 'id')
    const addOnStockLevel = parseStockLevel(_.get(sku, 'stockLevel'))
    const skuTotalQtyInCart = getSkuTotalQuantityInCart({ skuId, cart: initCart, reject: { groupUuid: cartLinePropertiesGroupUuid } })
    const stockLevelAffectedByMainQty = _.floor((addOnStockLevel - skuTotalQtyInCart) / productQuantity)
    return stockLevelAffectedByMainQty
  }, [loadings, productAddons, initCart, productQuantity, cartLinePropertiesGroupUuid])

  useEffect(() => {
    cleanUp()
    reset()
    if (id) {
      initTask()
    }
  }, [id, currentLanguage])

  useEffect(() => {
    if (taskStates.initState === 'loading') {
      fetchProductApi()
    }
  }, [taskStates.initState])
  useEffect(() => {
    if (taskStates.cartState === 'loading') {
      fetchCartApi()
    }
  }, [taskStates.cartState])
  useEffect(() => {
    if (
      taskStates.initState === 'success'
      && taskStates.noCacheState === 'loading'
    ) {
      fetchProductDetailsApi()
    }
  }, [taskStates.noCacheState, taskStates.initState])
  useEffect(() => {
    if (
      taskStates.addonsState === 'loading'
      && taskStates.noCacheState === 'success'
    ) {
      fetchProductAddonsApi()
    }
  }, [taskStates.noCacheState, taskStates.addonsState])
  useEffect(() => {
    if (
      taskStates.siblingsState === 'loading'
      && taskStates.noCacheState === 'success'
    ) {
      fetchSiblings()
    }
  }, [taskStates.noCacheState, taskStates.siblingsState])
  useEffect(() => {
    if (
      taskStates.skusValidationState === 'loading'
      && taskStates.noCacheState === 'success'
    ) {
      validateSkus()
    }
  }, [taskStates.noCacheState, taskStates.skusValidationState])
  useEffect(() => {
    if (
      taskStates.addItemsToCartState === 'loading'
      && taskStates.noCacheState === 'success'
    ) {
      addItemsToCart()
    }
  }, [taskStates.noCacheState, taskStates.addItemsToCartState])
  useEffect(() => {
    if (
      taskStates.backInStockNotificationState === 'loading'
      && enableBackInStockNotification
    ) {
      const taskData = getTask('backInStockNotification')
      backInStockNotification(_.get(taskData, 'params', {}))
    }
  }, [taskStates.backInStockNotificationState, enableBackInStockNotification])

  useEffect(() => {
    if (
      !_.isEmpty(cartLinePropertiesGroupUuid)
      && _.isNil(getTaskState('cart'))
    ) {
      addTask('cart')
    }
  }, [cartLinePropertiesGroupUuid])
  useEffect(() => {
    if (
      getTaskState('noCache') === 'success'
      && _.isNil(getTaskState('addons')) // no addons' task yet
      && _.size(productAddonIds) > 0 // product has addons
      && _.includes(includes, 'addons')
    ) {
      addTask('addons')
    }
  }, [getTaskState, productAddonIds, includes])
  useEffect(() => {
    if (
      getTaskState('noCache') === 'success'
      && _.isNil(getTaskState('siblings')) // no addons' task yet
      && !_.isEmpty(siblingsProductCodes)
      && _.includes(includes, 'siblings')
    ) {
      addTask('siblings')
    }
  }, [getTaskState, siblingsProductCodes, includes])
  useEffect(() => {
    // re-calculate checkout availability based on sku menu filters
    if (
      getTaskState('skusValidation') !== 'loading'
    ) addTask('skusValidation')
  }, [selectedSkus, orderMethod, storeId])
  useEffect(() => {
    if (
      getTaskState('init') === 'success'
      && _.size(productAddonIds) <= 0
    ) {
      setIsAddonsValid(true)
    }
  }, [getTaskState, productAddonIds])

  /**
   * Auto select size option if only one size Option when color option changed
   */
  useEffect(() => {
    if (
      _.isEmpty(product)
      || getTaskState('init') !== 'success'
      || _.isNil(selectedColorOptionId)
      || !_.isNil(selectedSizeOptionId)
    ) return
    // Size Option
    const availableSizeOptions = _.filter(
      _.get(product, 'skus', []),
      ({ stockLevel, colorOptionId }) => (
        parseStockLevel(stockLevel) > 0
        && colorOptionId === selectedColorOptionId
      ),
    )
    const firstSizeOptionId = _.get(availableSizeOptions, '0.sizeOptionId')
    if (_.size(availableSizeOptions) === 1) {
      setSelectedSizeOptionId(firstSizeOptionId)
    } else {
      setSelectedSizeOptionId(undefined)
    }
  }, [selectedColorOptionId, selectedSizeOptionId, product, getTaskState])

  useEffect(() => (
    function fetchProductApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchProduct',
        'fetchSkus',
      ])
    }
  ), [])
  /**
   * Auto select Addons if in edit mode
   */
  useEffect(() => {
    if (
      !_.isEmpty(cartLinePropertiesToEdit)
      && !_.isEmpty(productAddons)
    ) {
      const defaultValue = _.reduce(
        cartLinePropertiesToEdit,
        (result, cartLineProperty) => {
          const {
            colorOption,
            identifierUuid,
            isMainProperty,
            parentIdentifierUuid,
            productAddon,
            quantity: qty,
            sku: clpSku,
            customerRemark,
          } = cartLineProperty

          if (isMainProperty) return result

          const productAddonId = _.get(productAddon, 'id')
          const skuId = _.get(clpSku, 'id')
          const skuCode = _.get(clpSku, 'code')
          const pa = findProductAddonById({ productAddons, id: productAddonId })
          const addonId = _.get(_.find(_.get(pa, 'addons', []), ({ skus = [] }) => _.includes(_.map(skus, 'id'), skuId)), 'id')
          const title = _.get(colorOption, 'name')

          result.push({
            productAddonId,
            skuId,
            skuCode,
            stockLevel: _.get(clpSku, 'stockLevel'),
            addonId,
            title,
            identifierUuid,
            parentIdentifierUuid,
            quantity: qty,
            customerRemark,
          })
          return result
        },
        [],
      )
      const defaultValueWithQuantity = groupAddonsWithQuantity(defaultValue)
      const defaultValueWithQuantityIndex = _.reduce(
        defaultValueWithQuantity,
        (result, defaultValueItem) => {
          const quantityIndex = _.size(
            _.filter(result, _.pick(defaultValueItem, [
              'addonId',
              'productAddonId',
              'skuId',
            ])),
          )
          result.push({
            ...defaultValueItem,
            quantityIndex,
          })
          return result
        },
        [],
      )

      const defaultValueWithParents = _.reduce(
        defaultValueWithQuantityIndex,
        (result, defaultValueItem) => {
          const parentItem = _.find(
            result,
            ({ groupedIdentifierUuid = [] }) => _.includes(groupedIdentifierUuid, _.get(defaultValueItem, 'parentIdentifierUuid')),
          )
          result.push({
            ...defaultValueItem,
            parents: _.isEmpty(parentItem)
              ? []
              : [
                ..._.get(parentItem, 'parents', []),
                {
                  ..._.pick(parentItem, [
                    'addonId',
                    'productAddonId',
                    'skuId',
                  ]),
                  quantityIndex: _.get(defaultValueItem, 'quantityIndex', 0),
                },
              ],
          })
          return result
        },
        [],
      )
      setAddonsValue(
        _.map(defaultValueWithParents, (defaultValueItem) => _.pick(defaultValueItem, [
          'addonId',
          'customerRemark',
          'parents',
          'productAddonId',
          'quantity',
          'quantityIndex',
          'skuCode',
          'skuId',
          'stockLevel',
          'title',
        ])),
      )
      setAddonsTouched(
        _.uniq(_.map(defaultValueWithParents, 'productAddonId')),
      )
    }
  }, [productAddons, cartLinePropertiesToEdit])

  /**
   * set default quantity on edit mode
   */
  useEffect(() => {
    if (!_.isEmpty(cartLinePropertiesToEdit)) {
      setProductQuantity(_.get(_.find(cartLinePropertiesToEdit, { isMainProperty: true }), 'quantity'))
    }
  }, [cartLinePropertiesToEdit])

  useEffect(() => {
    if (addItemsToCartState === 'success') {
      removeTask('addItemsToCart')
      reset()
    }
  }, [addItemsToCartState])

  useEffect(() => {
    // re-calculate checkout availability based on sku menu filters
    if (!enableAddToCart) {
      setAddToCartButtonState('disabled')
    } else if (noPrice) {
      setAddToCartButtonState('unavailable')
    } else if (
      !_.isEmpty(productAddonIds)
      && _.isEmpty(selectedMainProduct)
    ) {
      setAddToCartButtonState('selectAddons')
    } else if (!_.isEmpty(loadings)) {
      setAddToCartButtonState('loading')
    } else if (isOutOfStock && enableBackInStockNotification) {
      setAddToCartButtonState('backInStockNotification')
    } else if (isOutOfStock) {
      setAddToCartButtonState('outOfStock')
    } else if (availableQuantity <= 0) {
      setAddToCartButtonState('noAvailableQuantity')
    } else if (productQuantity > availableQuantity) {
      setAddToCartButtonState('exceededAvailableQuantity')
    } else if (
      _.isNil(selectedSizeOptionId)
      || _.isNil(selectedColorOptionId)
    ) {
      setAddToCartButtonState('select')
    } else if (!isAddonsValid) {
      setAddToCartButtonState('selectAddons')
    } else if (!isSkusValid) {
      setAddToCartButtonState('invalidSku')
    } else {
      setAddToCartButtonState(
        _.isEmpty(cartLinePropertiesGroupUuid)
          ? 'normal'
          : 'update',
      )
    }
  }, [
    availableQuantity,
    cartLinePropertiesGroupUuid,
    enableAddToCart,
    enableBackInStockNotification,
    noPrice,
    productAddonIds,
    productQuantity,
    selectedColorOptionId,
    selectedMainProduct,
    selectedSizeOptionId,
    isAddonsValid,
    isOutOfStock,
    isSkusValid,
    loadings,
  ])

  return {
    addRecentlyViewed,
    addToCartButtonState,
    loadings,
    tasks,
    ...taskStates,
    availableQuantity,
    stockLevel: displayStockLevel,
    runTask: addTask,
    getTask,
    product,
    seoMetas,
    productAddons,
    productQuantity,
    selectedColorOptionId,
    selectedSizeOptionId,
    siblings,
    siblingsProductCodes,
    addonsValue,
    addonsTouched,
    isAddonsValid,
    // storeMenuCodes,
    onAddToCart,
    onBackInStockNotification,
    onFavouriteChange,
    onColorOptionChange,
    onSizeOptionChange,
    onAddonsChange,
    onAddonsUpdate,
    onAddonsValidate,
    onProductQuantityChange,
    getAddonAvailableQuantity,
  }
}
