/* eslint-disable react/no-danger */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import combineClassNames from '../../helpers/combineClassNames'
import getAddressLinesFromStore from '../../helpers/get-address-lines-from-store'
import getStoreInfoFromDescription from '../../helpers/get-store-info-from-description'
import {
  customStylesOnlyText,
} from '../dropdown/custom-styles'
import Dropdown from '../dropdown'
import useStyles from './input-store-picker-style'

const InputStorePickerView = ({
  options: stores,
  value,
  ...rest
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const resolveValue = (store) => ({
    ...store,
    label: _.get(store, 'name'),
    value: _.get(store, 'id'),
  })

  const options = useMemo(() => (
    _.map(
      stores,
      resolveValue,
    )
  ), [stores])

  return (
    <Dropdown
      values={_.isEmpty(value) ? null : resolveValue(value)}
      customStyles={customStylesOnlyText}
      options={options}
      isSearchable={false} // enableSearch
      {...rest}
      components={{
        Option: (props) => {
          const store = _.omit(props.data, ['label', 'value'])
          const address = _.join(getAddressLinesFromStore(store), ', ')
          const phone = _.get(store, 'phoneData.formatted', _.get(store, 'phone', ''))
          const descriptionHtml = (_.get(store, 'description') || '').trim()
          const openingHours = _.map(getStoreInfoFromDescription('time', descriptionHtml), 'content')
          return (
            <components.Option {...props}>
              <div
                className={combineClassNames([styles.optionContent])}
              >
                <div
                  className={combineClassNames([styles.row, styles.title, styles.name])}
                >
                  {props.label}
                </div>
                {
                  !_.isEmpty(address) && (
                    <div
                      className={combineClassNames([styles.row, styles.info, styles.address])}
                      dangerouslySetInnerHTML={{ __html: address }}
                    />
                  )
                }
                {
                  !_.isEmpty(phone) && (
                    <div
                      className={combineClassNames([styles.row, styles.info, styles.phone])}
                    >
                      {phone}
                    </div>
                  )
                }
                {
                  !_.isEmpty(openingHours) && (
                    <div
                      className={combineClassNames([styles.row, styles.openingHours])}
                    >
                      <div
                        className={
                          combineClassNames([
                            styles.info,
                            styles.openingHoursLine,
                            styles.openingHoursTitle,
                          ])
                        }
                      >
                        {t('ui.inputStorePicker.options.openingHours', { defaultValue: t('screens.stores.hours') })}
                      </div>
                      {
                        _.map(openingHours, (line, index) => (
                          <div
                            key={`openingHourLine_${index}`}
                            className={combineClassNames([styles.info, styles.openingHoursLine])}
                            dangerouslySetInnerHTML={{ __html: line }}
                          />
                        ))
                      }
                    </div>
                  )
                }
              </div>
            </components.Option>
          )
        },
      }}
    />
  )
}

export default InputStorePickerView
