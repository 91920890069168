import _ from 'lodash'
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import combineClassNames from '../../helpers/combineClassNames'
import CrossIcon from '../../assets/icons/icon_cross.inline.svg'
import useStyles from './alert-style'

const AlertView = (props) => {
  const {
    alertMessage,
    onClose,
    show,
    state, // ['error', 'success']
  } = props
  const alertRef = useRef(null)
  const styles = useStyles()

  // does not render element when it is not show
  if (!show) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={
          combineClassNames([
            styles.alert,
            state === 'success' && styles.alertSuccess,
            state === 'error' && styles.alertError,
          ])
        }
        ref={alertRef}
      >
        <div
          className={styles.closeButton}
          role="button"
          tabIndex="0"
          onClick={onClose}
          onKeyPress={onClose}
        >
          {/* <img alt="" className={styles.cross} src={CrossIcon} /> */}
          <CrossIcon className={styles.cross} />
        </div>
        {!_.isEmpty(alertMessage) && <div className={styles.message}>{alertMessage}</div>}
      </div>
    </div>
  )
}

AlertView.propTypes = {
  alertMessage: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
}

AlertView.defaultProps = {
  alertMessage: '',
  show: false,
  onClose: () => {},
}

export default AlertView
