/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import _ from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useCart,
  useDineInCart,
  useOrders,
} from 'react-omnitech-api'
import { useModal } from '../use-modal'
import useOrderMethod from '../use-order-method'
import usePriceTemplate from '../use-price-template'
import parseStockLevel from '../../helpers/parse-stock-level'

function useCartHook() {
  const { t } = useTranslation()
  const modal = useModal()
  const apiCart = useCart()
  const apiDineInCart = useDineInCart()
  const {
    orderMethod,
    store,
  } = useOrderMethod()
  const { updateOrderToDecline } = useOrders()
  const { code: CART_PRICE_TEMPLATE_KEY } = usePriceTemplate()

  // variables for dependencies
  const orderMethodCode = useMemo(() => _.get(orderMethod, 'code'), [orderMethod])
  const depApiCart = useMemo(() => JSON.stringify(apiCart), [apiCart])
  const depApiDineInCart = useMemo(() => JSON.stringify(apiDineInCart), [apiDineInCart])
  const {
    cartId,
    dineInCartId,
    initCart,
    updateCart,
    ...rest
  } = useMemo(() => {
    switch (orderMethodCode) {
      case 'dineIn':
        return apiDineInCart
      default:
        return apiCart
    }
  }, [orderMethodCode, depApiCart, depApiDineInCart])
  const currentCartId = useMemo(() => {
    switch (orderMethodCode) {
      case 'dineIn':
        return dineInCartId
      default:
        return cartId
    }
  }, [orderMethodCode, dineInCartId, cartId])
  const orderUuid = useMemo(() => _.get(initCart, 'awaitConfirmOrder.uuid'), [initCart])
  const isCheckoutOperator = useMemo(() => _.get(initCart, 'awaitConfirmOrder.isCheckoutOperator'), [initCart])
  const cartState = useMemo(() => _.get(initCart, 'state'), [initCart])
  const resolveUpdateCart = (options) => {
    if (
      _.isEmpty(orderUuid)
      && !_.isEqual(cartState, 'checkout_in_progress')
    ) {
      return updateCart(options)
    }
    // return promise to wait for user actions
    return new Promise((resolve, reject) => {
      if (isCheckoutOperator) {
        modal.open({
          title: t('ui.cartPaymentInProgressModal.title'),
          message: t('ui.cartPaymentInProgressModal.description'),
          buttons: [
            {
              text: t('ui.cartPaymentInProgressModal.buttons.cancel'),
              onClick: () => reject(onCancel()),
            },
            {
              text: t('ui.cartPaymentInProgressModal.buttons.declineOrder'),
              onClick: async () => {
                // close modal
                modal.close()
                // decline order
                try {
                  await updateOrderToDecline({
                    byType: 'by_uuid',
                    uuid: orderUuid,
                    action: 'decline',
                  })
                  resolve(updateCart(options))
                } catch (error) {
                  reject(error)
                }
              },
            },
          ],
        })
      } else {
        modal.open({
          title: t('ui.cartPaymentInProgressNonCheckoutOperatorModal.title'),
          message: t('ui.cartPaymentInProgressNonCheckoutOperatorModal.description'),
          buttons: [
            {
              text: t('ui.cartPaymentInProgressNonCheckoutOperatorModal.buttons.cancel'),
              onClick: () => reject(onCancel()),
            },
          ],
        })
      }
    })
  }

  const onCancel = () => {
    modal.close()
    return { generalError: { code: 0 } }
  }

  const cartIncludes = [
    'available_delivery_types',
    'await_confirm_order',
    'cart_line_properties',
    'cart_line_properties.color_option',
    'cart_line_properties.price_details',
    'cart_line_properties.sku',
    'cart_line_properties.product',
    'cart_line_properties.product_addon',
    'cart_line_properties.cart_shipment_id',
    'cart_line_properties.sku_id',
    'price_details',
    'products.product_addons',
    'products.color_option_variant_type',
    'products.size_option_variant_type',
    'skus.active_custom_labels',
    'skus.color_option',
    'skus.product',
    'skus.size_option',
    'skus.stock_level',
    'cart_shipments',
    'cart_shipments.courier_service',
    'cart_shipments.delivery_address',
    'cart_shipments.delivery_time_slot',
    'cart_shipments.delivery_type',
    'cart_shipments.inventory_store',
    'cart_shipments.pickup_store',
    'stores.today_open_time_slots',
  ].join(',')

  const storeCode = useMemo(() => (
    (
      _.get(store, 'id') !== 'warehouse'
      && _.get(store, 'code')
    ) || _.get(initCart, 'cartShipments.0.inventoryStore.code')
  ), [store])
  const getParams = (params = {}) => {
    const {
      includes = [],
      // default empty for faster requests. possible values ['price', 'color_option']
      includeGroups = [],
      ...otherParams
    } = params;
    return _.merge(
      {},
      {
        includes: _.concat(
          [
            'await_confirm_order',
            'cart_line_properties.color_option',
            'cart_line_properties.price_details',
            'cart_line_properties.sku',
            'cart_line_properties',
            'cart_shipments.inventory_store',
            'cart_shipments.pickup_store',
            'cart_shipments',
            'price_details',
            'products.color_option_variant_type',
            'products.size_option_variant_type',
            'skus.active_custom_labels',
            'skus.color_option',
            'skus.product',
            'skus.size_option',
            'skus.stock_level',
            'stores.today_open_time_slots',
          ],
          _.includes(includeGroups, 'basic')
            ? [
              'available_delivery_types',
              'cart_line_properties.product_addon',
              'cart_line_properties.product',
              'cart_line_properties.sku_id',
              'cart_shipments.courier_service',
              'cart_shipments.delivery_address',
              'cart_shipments.delivery_time_slot',
              'cart_shipments.delivery_type',
              'products.product_addons',
              'skus.meta',
            ]
            : [],
          _.includes(includeGroups, 'checkout')
            ? [
              'billing_address',
              'cart_coupon_tokens.coupon_token',
              'cart_coupon_tokens',
              'cart_shipments.courier_service_id',
              'cart_shipments.courier_service',
              'cart_shipments.delivery_address_id',
              'cart_shipments.delivery_address',
              'cart_shipments.delivery_time_slot_id',
              'cart_shipments.delivery_time_slot',
              'cart_shipments.delivery_type',
              'categories.department',
              'categories.parent',
              'coupon_tokens.coupon',
              'meta',
              'orders.payment_requests',
              'payment_information',
              'payment_providers.available_payment_provider_tokens',
              'products.brands',
              'products.categories',
              'stores.address',
              'stores.meta',
            ]
            : [],
          _.includes(includeGroups, 'iniCart')
            ? [
              'cart_line_properties.product_addon',
              'cart_line_properties.product',
              'cart_line_properties.sku_id',
              'skus.checkout_settings',
              'skus.meta',
            ]
            : [],
          _.includes(includeGroups, 'dineInMiniCart')
            ? [
              'meta',
              'physical_store_id',
            ]
            : [],
          includes,
        ).join(','),
        priceTemplate: CART_PRICE_TEMPLATE_KEY,
        refreshCart: true,
        ...(
          !_.isEmpty(storeCode) ? {
            inventoryStoreCodeEq: storeCode,
            priceStoreCodeEq: storeCode,
          } : {}
        ),
      },
      otherParams,
    )
  }

  const getSkuTotalQuantityInCart = ({ skuId, filter = {}, reject = {} }) => {
    let cartLineProperties = _.get(initCart, 'cartLineProperties', [])
    if (!_.isEmpty(filter) && _.isObject(filter)) {
      cartLineProperties = _.filter(cartLineProperties, filter)
    }
    if (!_.isEmpty(reject) && _.isObject(reject)) {
      cartLineProperties = _.reject(cartLineProperties, reject)
    }
    const items = _.filter(
      cartLineProperties,
      ({ sku: clpSku, skuId: clpSkuId }) => (_.isEqual(_.get(clpSku, 'id', clpSkuId), skuId)),
    )
    return _.sumBy(items, 'calculationQuantity')
  }

  function getCartLineAvailableQuantity({ cartLine, cart = initCart }) {
    const {
      cartMaxNumOfQty = Infinity,
      cartMaxNumOfQtyPerSku = Infinity,
      cartLineProperties = [],
    } = cart
    const {
      groupUuid,
      sku = {},
      skuId,
      quantity: cartLineQuantity,
    } = cartLine
    const stockLevel = parseStockLevel(sku.stockLevel)
    const mainProperties = _.filter(cartLineProperties, 'isMainProperty')
    const otherCartLines = _.reject(mainProperties, ['groupUuid', groupUuid])
    const otherCartLinesWithSameSku = _.filter(otherCartLines, ({ sku: otherCartLineSku }) => _.get(sku, 'id') === _.get(otherCartLineSku, 'id', '///'))
    const otherCartLinesWithSameSkuQty = _.sumBy(otherCartLinesWithSameSku, 'quantity')
    const availableQtyForCartLine = stockLevel - otherCartLinesWithSameSkuQty
    const totalQtyOfOtherCartLines = _.sumBy(otherCartLines, 'quantity')
    const cartMaxAvailableNumOfQty = cartMaxNumOfQty - totalQtyOfOtherCartLines
    const sameSkuInOtherCartLines = _.filter(
      otherCartLines,
      ({ sku: otherCartLineSku, skuId: otherCartLineSkuId }) => (
        _.isEqual(
          _.get(otherCartLineSku, 'id', otherCartLineSkuId),
          _.get(sku, 'id', skuId),
        )
      ),
    )
    const totalQtyOfSameSkuCartLines = _.sumBy(sameSkuInOtherCartLines, 'quantity')
    const cartMaxAvailableNumOfQtyPerSku = cartMaxNumOfQtyPerSku - totalQtyOfSameSkuCartLines

    const allSkus = _.reduce(
      cartLineProperties,
      (result, { sku: addonSku, calculationQuantity }) => {
        const existingSku = _.find(result, { id: _.get(addonSku, 'id') })
        const otherSkus = _.reject(result, { id: _.get(addonSku, 'id') })
        result = [
          ...otherSkus,
          {
            ...addonSku,
            totalQuantity: calculationQuantity + _.get(existingSku, 'totalQuantity', 0),
          },
        ]
        return result
      },
      [],
    )
    const addons = _.filter(cartLineProperties, { groupUuid, isMainProperty: false })
    const payloadStockLevel = _.min(
      _.map(addons, ({ sku: addonSku }) => {
        const addonSkuId = _.get(addonSku, 'id')
        const {
          stockLevel: skuStockLevel,
          totalQuantity,
        } = _.find(allSkus, { id: addonSkuId }) || {}
        const totalQuantityOfAddonSkuInThisCartLine = _.sumBy(
          _.filter(
            addons,
            ({ sku: payloadSku }) => _.get(payloadSku, 'id') === addonSkuId,
          ),
          'quantity',
        )
        return _.floor(
          (parseStockLevel(skuStockLevel) - totalQuantity) / totalQuantityOfAddonSkuInThisCartLine,
        )
      }),
    ) + cartLineQuantity
    return _.min([
      cartMaxAvailableNumOfQty,
      cartMaxAvailableNumOfQtyPerSku,
      availableQtyForCartLine,
      payloadStockLevel,
    ])
  }

  return {
    cartId: currentCartId,
    cartIncludes,
    updateCart: resolveUpdateCart,
    initCart,
    getCartLineAvailableQuantity,
    getParams,
    getSkuTotalQuantityInCart,
    ...rest,
  };
}

export default useCartHook
