import { createContext } from 'react'

/**
 * FavouritesContext
 * Create Favourites context for custom hook
 */
const FavouritesContext = createContext({
  createFavourite: () => {},
  deleteFavourite: () => {},
  fetchFavourites: () => {},
  touchedFavourites: {},
})

export default FavouritesContext
