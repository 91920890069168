import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useThemeConfig } from '../../hook/use-theme-config'
import useStyles from './coupon-state-label-style'

import combineClassNames from '../../helpers/combineClassNames'

const CouponStateLabel = (props) => {
  const {
    isExpired,
    isNonStarted,
    isTransferable,
    isTransferInProgress,
    isUsable,
    className,
    textClassName,
    pendingClassName,
    inactiveClassName,
    textPendingClassName,
    textInactiveClassName,
  } = props
  const { getConfig } = useThemeConfig()
  const displayConfig = getConfig(
    'config.ui.couponStateLabel.display',
    {
      expired: true,
      giftable: true,
      gifted: true,
      nonStarted: false,
      usable: false,
    },
  )
  const { t } = useTranslation()
  const styles = useStyles()
  let labelType = 'active'
  let displayType = ''
  let labelText = ''
  if (isExpired) {
    displayType = 'expired'
    labelType = 'inactive'
  } else if (isNonStarted) {
    displayType = 'nonStarted'
    labelType = 'pending'
  } else if (isTransferInProgress) {
    displayType = 'transferInProgress'
    labelType = 'pending'
  } else if (isTransferable) {
    displayType = 'transferable'
  } else if (isUsable) {
    displayType = 'usable'
  } else {
    return null
  }
  if (!_.isEmpty(displayType)) {
    labelText = t('ui.minicartCoupons.couponStateLabel', { context: displayType })
  }
  if (
    _.isEmpty(labelText)
    || _.isEmpty(displayType)
    || !_.get(displayConfig, displayType, false)
  ) return null
  return (
    <span
      className={
        combineClassNames([
          styles.label,
          className,
          labelType === 'pending' && styles.labelPending,
          labelType === 'pending' && pendingClassName,
          labelType === 'inactive' && styles.labelInactive,
          labelType === 'inactive' && inactiveClassName,
        ])
      }
    >
      <span
        className={
          combineClassNames([
            styles.labelText,
            textClassName,
            labelType === 'pending' && styles.labelTextPending,
            labelType === 'pending' && textPendingClassName,
            labelType === 'inactive' && styles.labelTextInactive,
            labelType === 'inactive' && textInactiveClassName,
          ])
        }
      >
        {labelText}
      </span>
    </span>
  )
}

export default CouponStateLabel
