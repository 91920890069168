import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  couponCard: {
    padding: '1rem 0 .7rem',
    textAlign: 'left',
    borderBottom: '1px solid rgba(40,40, 40, 0.4)',
    background: theme.colors.background,
    position: 'relative',
  },
  couponCardMinicart: {
    marginBottom: '1.6rem',
    paddingBottom: '1rem',
  },
  couponContent: ({ displayImage } = {}) => ({
    display: 'flex',
    width: '100%',
    padding: displayImage
      ? '0 1rem 1rem'
      : '0 2rem 2rem',
  }),
  coupontText: ({ displayImage } = {}) => ({
    flex: '1 0 auto',
    maxWidth: displayImage
      ? 'calc(100% - 9.5rem)'
      : '100%',
    paddingTop: '.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  couponImage: {
    marginRight: '1.5rem',
    padding: 0,
    width: '7rem',
    '& div': {
      height: 0,
      paddingBottom: 'var(--ui-coupon-card-image-padding-bottom)',
      position: 'relative',
      backgroundColor: '#dddddd',
      overflowY: 'hidden',
    },
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: ' translate(-50%, -50%)',
      margin: 0,
      width: '100%',
    },
  },
  disabled: {
    opacity: 0.5,
  },
  noImage: {
    '& img': {
      width: '50% !important',
    },
  },
  selectable: {
    cursor: 'pointer',
  },
  titleBox: ({ miniCartCoupon } = {}) => ({
    display: 'flex',
    marginBottom: '.4rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: miniCartCoupon ? '25rem' : '100%',
  }),
  titleStyle: {
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: 1.15,
    color: theme.colors.text,
  },
  titleStyleMinicart: {
    textTransform: 'inherit',
  },
  termsStyle: {
    color: theme.colors.alert,
    fontSize: '1.4rem',
    marginBottom: '.4rem',
    padding: 0,
    fontWeight: 600,
    lineHeight: 1.15,
    // handle styles in current format
    '& > span': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      fontWeight: 'inherit',
    },
  },
  clauseStyle: {
    color: theme.colors.disabled,
    fontSize: '1.4rem',
    lineHeight: 1.15,
    marginBottom: '0',
    maxWidth: '90%',
    '& > *': {
      display: 'none',
    },
    '& > :first-child': {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  state: {
    fontSize: '1.4rem',
    margin: 0,
    lineHeight: 1.14,
    textTransform: 'capitalize',
  },
  redeem: {
    extend: 'state',
    color: theme.colors.alert,
  },
  add: {
    extend: 'state',
    color: theme.colors.success,
  },
  used: {
    extend: 'state',
    color: theme.colors.disabled,
  },
  couponCardFooter: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.6rem',
    padding: (({ displayImage } = {}) => (
      displayImage
        ? '.8rem 1rem 0'
        : '.8rem 1rem 0 3rem'
    )),
    borderTop: '1px solid rgba(40,40, 40, 0.2)',
    '& img': {
      margin: '0 0.5rem 0 0',
    },
  },
  couponCardFooterText: {
    fontSize: '1.2rem',
    lineHeight: 1.33,
  },
  couponCardFooterTextSharedLabel: {
    color: 'var(--ui-coupon-card-shared-label-color)',
  },
  couponCardFooterColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  couponCardFooterDate: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.text,
  },
  couponCardFooterDateColumn: {
    alignItems: 'flex-start',
  },
  couponCardFooterPointColumn: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  dateLabel: {
    fontSize: '1.2rem',
    lineHeight: 1.33,
    fontWeight: 'bold',
    letterSpacing: '0.14px',
    marginBottom: '.4rem',
  },
  points: {
    position: 'absolute',
    right: '1rem',
  },
  usableCouponTokenCount: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    maxWidth: '100%',
  },
  usableCouponTokenCountLabel: {
    fontSize: '1.2rem',
    lineHeight: 1.33,
    padding: '0.2em 1em',
    borderRadius: '1.2rem',
    backgroundColor: 'var(--ui-coupon-card-usable-coupon-token-count-label-background-color)',
    color: 'var(--ui-coupon-card-usable-coupon-token-count-label-color)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    couponContent: {
      padding: 0,
    },
    titleBox: {
      alignItems: 'center',
    },
    titleStyle: {
      maxWidth: 'initial',
    },
    dateStyle: {
      padding: '.3rem 0',
    },
  },
}))

export default useStyles
