/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import _ from 'lodash'
import URI from 'urijs'
import { useLocation } from '@reach/router'
import {
  useBrands,
  useCategories,
  useContentGroups,
  useDepartments,
  useI18n,
} from 'react-omnitech-api'
import MenuContext from './menu-context'
import getBrands from '../../helpers/get-brands-list'
import { useThemeConfig } from '../use-theme-config'
import useOrderMethod from '../use-order-method'
import getTopLevelCategoryCode from '../../helpers/get-top-level-category-code'

/**
 * MenuProvider
 * Contain most logic handing menu
 */
export default function MenuProvider({ children }) {
  const [menuData, setMenuData] = useState([])
  const [menuContentGroup, setMenuContentGroup] = useState({})
  const { brands } = useBrands()
  const { categories } = useCategories()
  const { fetchContentGroupByCode } = useContentGroups()
  const { departments, fetchDepartments } = useDepartments()
  const location = useLocation()
  const { getConfig, getContentGroup } = useThemeConfig()
  const { orderMethod, store } = useOrderMethod()
  const { currentLanguage } = useI18n()

  const orderMethodCode = useMemo(() => _.get(orderMethod, 'code'), [orderMethod])
  const storeCode = useMemo(() => _.get(store, 'code', ''), [store])
  const topLevelCategoryCode = useMemo(() => getTopLevelCategoryCode({
    topLevelCategory: _.get(orderMethod, 'topLevelCategory'),
    storeCode,
  }), [orderMethod, storeCode])

  function getChildrenCategories({ departmentId, categoryIds, extraParams = {} }) {
    // if (_.isEmpty(categoryIds) && _.isEmpty(departmentId)) {
    //   return []
    // }

    const {
      blackList,
      codePrefixBlackList,
      codePrefixWhiteList,
      whiteList,
    } = mainNavCategoryFilters

    let childrenCategories = []

    if (departmentId) {
      childrenCategories = _.filter(categories, (data) => departmentId === data.departmentId)
    } else {
      childrenCategories = _.filter(categories, (data) => _.includes(categoryIds, data.id))
    }

    const checkCodePrefix = ({ code, prefix }) => {
      if (_.isEmpty(prefix)) return false
      const regExp = new RegExp(`^${prefix}.*`, 'i')
      return regExp.test(code)
    }

    return _.compact(
      _.map(childrenCategories, (data) => {
        const { childIds: childrenSubCategoryIds = [], code, name } = data
        if (
          !_.isEmpty(blackList)
          && _.includes(blackList, code)
        ) {
          return null
        }
        if (
          !_.isEmpty(codePrefixBlackList)
          && _.some(codePrefixBlackList, (prefix) => checkCodePrefix({ code, prefix }))
        ) {
          return null
        }
        if (
          !_.isEmpty(whiteList)
          && !_.includes(whiteList, code)
        ) {
          return null
        }
        if (
          !_.isEmpty(codePrefixWhiteList)
          && !_.some(codePrefixWhiteList, (prefix) => checkCodePrefix({ code, prefix }))
        ) {
          return null
        }
        const urlObj = new URI('/products/')
          .addSearch({
            categoryCodeEq: code,
            ...extraParams,
          })
        const url = `${urlObj.path()}${urlObj.search()}`
        const childrenSubCategories = getChildrenCategories({
          departmentId: undefined,
          categoryIds: childrenSubCategoryIds,
          extraParams: {
            ...extraParams,
          },
        })

        return {
          children: childrenSubCategories,
          key: `category-${data.id}`,
          text: name,
          type: 'category',
          url,
        }
      }),
    )
  }

  function getCategory(categoryId) {
    const category = _.find(categories, { id: categoryId })
    // if category not found, return a empty object
    if (_.isEmpty(category)) return {}

    const { childIds = [], code, name } = category
    const urlObj = new URI('/products/')
      .addSearch({
        categoryCodeEq: code,
      })
    const url = `${urlObj.path()}${urlObj.search()}`
    const departmentId = null
    const childrenCategories = getChildrenCategories({
      departmentId,
      categoryIds: childIds,
    })
    const paramUrl = _.get(URI().search(true), 'categoryCodeEq', '//////')
    const paramLink = _.get(URI(url).search(true), 'categoryCodeEq', '======')

    return {
      children: childrenCategories,
      key: `category-${category.id}`,
      text: name,
      type: 'category',
      active: paramLink === paramUrl,
      url,
    }
  }

  function getDepartment(departmentId) {
    const department = _.find(departments, { id: departmentId })

    // if department not found, return a empty object
    if (_.isEmpty(department)) return {}

    const { categoryIds = [], code, name } = department
    const urlObj = new URI('/products/')
      .addSearch({
        departmentCodeEq: code,
      })
    const url = `${urlObj.path()}${urlObj.search()}`
    const childrenCategories = getChildrenCategories({
      departmentId,
      categoryIds,
      extraParams: {
        departmentCodeEq: code,
      },
    })
    const paramUrl = _.get(URI().search(true), 'departmentCodeEq', '//////')
    const paramLink = _.get(URI(url).search(true), 'departmentCodeEq', '======')

    return {
      children: childrenCategories,
      key: `department-${department.id}`,
      text: name,
      type: 'department',
      active: paramLink === paramUrl,
      url,
    }
  }

  function transformContentGroupLinesToMenu(contentGroup) {
    const contentGroupLines = _.get(contentGroup, 'contentGroupLines', [])
    const data = _.map(contentGroupLines, (line) => {
      const {
        id,
        linkTargetId,
        linkTargetType,
        name,
        url,
        configOptions,
      } = line
      const type = _.toLower(linkTargetType)
      const brandsMenu = _.get(configOptions, 'brandsMenu')
      // special handling
      let targetObject = {}
      switch (true) {
        case type === 'department':
          targetObject = getDepartment(linkTargetId)
          break
        case type === 'category':
          targetObject = getCategory(linkTargetId)
          break
        case brandsMenu === 'true':
          targetObject = getBrands(brands)
          break
        default:
          break
      }

      // object of a menu item
      return {
        key: `content-group-line-${id}`,
        text: name,
        // FL: Prevent url is empty string that make below check return `true`
        active: _.includes(window.location.href, (targetObject.url || url || '!!!!!!!')),
        type,
        url,
        className: _.get(configOptions, 'htmlClass'),
        classNameActive: _.get(configOptions, 'htmlClassActive'),
        ...targetObject,
      }
    })

    return data
  }

  const mainNavContentGroupProps = useMemo(() => (
    getContentGroup('config.ui.mainNavigation', 'ecom_main_navigation')
  ), [getContentGroup])

  const mainNavCategoryFilters = useMemo(() => {
    // codePrefixWhiteList
    let codePrefixWhiteList = []
    if (!_.isEmpty(orderMethodCode)) {
      codePrefixWhiteList = getConfig(`config.ui.mainNavigation.categoryFilters.codePrefix.whiteList.${orderMethodCode}`, [])
    }
    if (_.isEmpty(codePrefixWhiteList)) codePrefixWhiteList = getConfig('config.ui.mainNavigation.categoryFilters.codePrefix.whiteList.default', [])
    if (_.isEmpty(codePrefixWhiteList)) codePrefixWhiteList = getConfig('config.ui.mainNavigation.categoryFilters.codePrefix.whiteList', [])
    if (
      _.isEmpty(codePrefixWhiteList)
      && !_.isEmpty(orderMethodCode)
    ) codePrefixWhiteList = getConfig(`config.ui.mainNavigation.categoryFilters.codePrefix.${orderMethodCode}`, [])
    if (_.isEmpty(whiteList)) whiteList = getConfig('config.ui.mainNavigation.categoryFilters.codePrefix.default', [])
    if (_.isEmpty(whiteList)) whiteList = getConfig('config.ui.mainNavigation.categoryFilters.codePrefix', [])

    // whiteList
    let whiteList = []
    if (!_.isEmpty(orderMethodCode)) {
      whiteList = getConfig(`config.ui.mainNavigation.categoryFilters.whiteList.${orderMethodCode}`, [])
    }
    if (_.isEmpty(whiteList)) whiteList = getConfig('config.ui.mainNavigation.categoryFilters.whiteList.default', [])
    if (_.isEmpty(whiteList)) whiteList = getConfig('config.ui.mainNavigation.categoryFilters.whiteList', [])
    if (
      _.isEmpty(whiteList)
      && !_.isEmpty(orderMethodCode)
    ) whiteList = getConfig(`config.ui.mainNavigation.categoryFilters.${orderMethodCode}`, [])
    if (_.isEmpty(whiteList)) whiteList = getConfig('config.ui.mainNavigation.categoryFilters.default', [])

    // codePrefixBlackList
    let codePrefixBlackList = []
    if (!_.isEmpty(orderMethodCode)) {
      codePrefixBlackList = getConfig(`config.ui.mainNavigation.categoryFilters.codePrefix.blackList.${orderMethodCode}`, [])
    }
    if (_.isEmpty(codePrefixBlackList)) codePrefixBlackList = getConfig('config.ui.mainNavigation.categoryFilters.codePrefix.blackList.default', [])
    if (_.isEmpty(codePrefixBlackList)) codePrefixBlackList = getConfig('config.ui.mainNavigation.categoryFilters.codePrefix.blackList', [])

    // black list
    let blackList = []
    if (!_.isEmpty(orderMethodCode)) {
      blackList = getConfig(`config.ui.mainNavigation.categoryFilters.blackList.${orderMethodCode}`, [])
    }
    if (_.isEmpty(blackList)) blackList = getConfig('config.ui.mainNavigation.categoryFilters.blackList.default', [])
    if (_.isEmpty(blackList)) blackList = getConfig('config.ui.mainNavigation.categoryFilters.blackList', [])

    // if everything is empty
    if (
      _.isEmpty(whiteList)
      && _.isEmpty(blackList)
      && _.isEmpty(codePrefixBlackList)
      && _.isEmpty(codePrefixWhiteList)
    ) {
      whiteList = getConfig('config.ui.mainNavigation.categoryFilters', [])
    }

    return {
      blackList,
      codePrefixBlackList,
      codePrefixWhiteList,
      whiteList,
    }
  }, [getConfig, orderMethodCode])

  const mainNavContentGroupCode = useMemo(() => (
    _.get(mainNavContentGroupProps, 'code', 'ecom_main_navigation')
  ), [mainNavContentGroupProps])

  const apiFetchContentGroup = useCallback(async () => {
    let contentGroupCode = _.replace(mainNavContentGroupCode, '{{storeCode}}', storeCode)
    contentGroupCode = _.replace(contentGroupCode, '{{topLevelCategoryCode}}', topLevelCategoryCode)
    try {
      // api call option from system setting
      const option = {
        code: contentGroupCode,
        includes: [
          'content_group_lines',
        ].join(','),
      }
      const { contentGroup: data } = await fetchContentGroupByCode(option)
      setMenuContentGroup(data)
    } catch (error) {
      // TODO: handle error
      console.warn('[MenuProvider] apiFetchContentGroup error: ', error)
    }
  }, [fetchContentGroupByCode, mainNavContentGroupCode, storeCode, currentLanguage])

  useEffect(() => {
    apiFetchContentGroup()
  }, [apiFetchContentGroup])

  useEffect(() => {
    if (_.isEmpty(menuContentGroup)) return
    const menuObject = transformContentGroupLinesToMenu(menuContentGroup)
    setMenuData(menuObject)
  }, [brands, categories, departments, menuContentGroup, location, orderMethodCode])

  const state = {
    menuData,
    getDepartment,
  }

  return (
    <MenuContext.Provider value={state}>
      {children}
    </MenuContext.Provider>
  )
}
