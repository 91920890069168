import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({

  container: {
    letterSpacing: 'normal',
  },
  title: {
    fontWeight: 400,
    // textTransform: 'uppercase',
    marginBottom: '1.8rem',
  },
  logos: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1.8rem',
    flexWrap: 'wrap',
  },
  item: {
    position: 'relative',
    display: 'flex',
    height: '2.4rem',
    width: '2.4rem',
    cursor: 'pointer',
    lineHeight: 1,
  },
  icon: {
    width: '100%',
    margin: 0,
  },
  link: {
    position: 'relative',
  },
  popup: {
    position: 'absolute',
    opacity: 0,
    top: 5,
    width: 45,
    height: 26,
    backgroundColor: theme.colors.light,
    zIndex: 1,
    textAlign: 'center',
    '& span': {
      fontSize: '1rem',
    },
  },
  show: {
    opacity: 1,
    top: -12,
    transition: 'all 600ms',
  },
}))

export default useStyles
