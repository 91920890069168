/* eslint-disable semi-style */
/* eslint-disable no-extra-semi */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'
import Dropdown from '../dropdown'
import Loading from '../loading'
import { customStylesOnlyText } from '../dropdown/custom-styles'
import InputRadio from '../input-radio'
import Modal from '../modal'
import AddressManager from '../address-manager'
import useStyles from './order-method-modal-style'
import Cross from '../../assets/icons/icon_cross.svg'
import { combineClassNames } from '../../helpers'
import InputStorePicker from '../input-store-picker'

export default function OrderMethodModalView({
  confirmButtonDisabled,
  deliveryAddress,
  deliveryAddresses,
  deliveryAddressesLoading,
  handleDeleteAddress,
  isAddressManagerOpen,
  isBlocker,
  isChangeStoreDialogOpen,
  isCommerceChannelNotMatch,
  isLoggedIn,
  isNoStoreDialogOpen,
  isOrderMethodDialogOpen,
  onAddressManagerRequestClose,
  onAddressSelectorClick,
  onChange,
  onChangeStoreConfirm,
  onChangeStoreCancel,
  onChangeStoreDialogRequestClose,
  onConfirm,
  onFetchDeliveryAddresses,
  onNoStoreDialogRequestClose,
  onRequestClose,
  onSelectDeliveryAddress,
  onStoreChange,
  onUpdateDeliveryAddress,
  orderMethod,
  orderMethodOptions,
  selectedItem,
  selectedStoreItem,
  showAddressSelector,
  showStoreSelector,
  storeOptions,
  storesLoading,
  themeConfig,
  updateOrderMethodInProgress,
}) {
  const { t } = useTranslation()

  const isDineIn = useMemo(() => (_.get(orderMethod, 'code') === 'dineIn'), [orderMethod])

  const orderMethodBackgroundUrls = useMemo(() => (
    _.get(themeConfig, 'config.orderMethodBackgroundUrls', {})
  ), [themeConfig])
  const orderMethodSelectionInput = useMemo(() => (
    _.get(themeConfig, 'config.ui.orderMethodModal.orderMethodSelectionInput', 'dropdown')
  ), [themeConfig])
  const styles = useStyles({ orderMethodBackgroundUrls })
  const translationContext = {
    context: isCommerceChannelNotMatch
      ? 'commerceChannelNotMatch'
      : _.get(selectedItem, 'value', 'default'),
  }

  return (
    <>
      <Modal
        dialog
        isOpen={isOrderMethodDialogOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick={!isBlocker}
        shouldCloseOnEsc={!isBlocker}
        overlayClassName={styles.overlay}
      >
        <div className={styles.dialogContainer}>
          {
            !isBlocker && (
              <Button
                className={styles.closeButton}
                iconClassName={styles.closeButtonIcon}
                inline
                iconImage={Cross}
                onClick={onRequestClose}
              />
            )
          }
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('ui.orderMethodModal.title', translationContext)}</h1>
            { isCommerceChannelNotMatch && (
              <p className={styles.dialogDescription}>
                {t('ui.orderMethodModal.description', translationContext)}
              </p>
            ) }
            {
              orderMethodSelectionInput === 'radio'
                ? (
                  <div
                    className={combineClassNames([
                      styles.inputContainer,
                      styles.radioGroup,
                      _.size(orderMethodOptions) < 3 && styles.radioGroupShort,
                    ])}
                  >
                    {
                      _.map(orderMethodOptions, (om) => {
                        const {
                          value,
                          label,
                        } = om
                        return (
                          <InputRadio
                            key={`order_method_modal_option_${value}`}
                            checked={_.get(selectedItem, 'value', '///') === value}
                            onChange={() => {
                              onChange(om)
                            }}
                            // make sure label htmlFor/input id have same uniq id
                            id={`order_method_modal_option_${value}`}
                            labelClassName={styles.radioGroupItem}
                            radioClassName={styles.radioGroupItemRadio}
                          >
                            {label}
                          </InputRadio>
                        )
                      })
                    }
                  </div>
                ) : (
                  <div className={styles.inputContainer}>
                    {
                      isDineIn
                      && _.isEmpty(orderMethodOptions)
                        ? (
                          <p>{t('ui.orderMethodModal.options', { context: 'dineIn' })}</p>
                        )
                        : (
                          <Dropdown
                            className={styles.dropdownStyle}
                            customStyles={customStylesOnlyText}
                            options={orderMethodOptions}
                            values={selectedItem}
                            onChange={onChange}
                            disabled={_.isEmpty(orderMethodOptions)}
                          />
                        )
                    }
                  </div>
                )
            }
            <div className={styles.inputContainer}>
              {
                showAddressSelector
                  && (
                    <>
                      <Dropdown
                        className={styles.dropdownStyle}
                        customStyles={customStylesOnlyText}
                        options={[]}
                        values={!_.isEmpty(deliveryAddress) && {
                          label: _.get(deliveryAddress, 'name') || _.join(_.get(deliveryAddress, 'formattedAddressLines', []), ', '),
                          value: _.get(deliveryAddress, 'id'),
                        }}
                        placeholder={t(
                          'ui.orderMethodModal.placeholder',
                          {
                            context: isLoggedIn
                              ? _.get(selectedItem, 'value')
                              : 'loginRequired',
                          },
                        )}
                      />
                      <button
                        className={styles.addressSelectorTrigger}
                        onClick={onAddressSelectorClick}
                        type="button"
                      >
                        Select Delivery Address
                      </button>
                    </>
                  )
              }
              {
                showStoreSelector && (
                  <InputStorePicker
                    className={styles.dropdownStyle}
                    options={storeOptions}
                    value={selectedStoreItem}
                    onChange={onStoreChange}
                    placeholder={
                      t('ui.orderMethodModal.placeholder', {
                        context: storesLoading ? 'loading' : _.get(selectedItem, 'value'),
                      })
                    }
                  />
                )
              }
            </div>
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('ui.orderMethodModal.buttons.confirm', { context: _.get(selectedItem, 'value') })}
              onClick={onConfirm}
              disabled={confirmButtonDisabled || updateOrderMethodInProgress}
            />
          </div>
        </div>
      </Modal>
      <Modal
        dialog
        isOpen={isChangeStoreDialogOpen}
        onRequestClose={onChangeStoreDialogRequestClose}
      >
        <div className={styles.dialogContainer}>
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('ui.orderMethodModal.modalDialog.changeStore.title')}</h1>
            <p className={styles.dialogDescription}>{t('ui.orderMethodModal.modalDialog.changeStore.description')}</p>
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('ui.orderMethodModal.modalDialog.changeStore.buttons.confirm')}
              onClick={onChangeStoreConfirm}
            />
            <Button
              className={styles.button}
              dark
              text={t('ui.orderMethodModal.modalDialog.changeStore.buttons.dismiss')}
              onClick={onChangeStoreCancel}
            />
          </div>
        </div>
      </Modal>
      <Modal
        dialog
        isOpen={isNoStoreDialogOpen}
        onRequestClose={onNoStoreDialogRequestClose}
      >
        <div className={styles.dialogContainer}>
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('ui.orderMethodModal.modalDialog.noStore.title')}</h1>
            <p className={styles.dialogDescription}>{t('ui.orderMethodModal.modalDialog.noStore.description')}</p>
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('ui.orderMethodModal.modalDialog.noStore.buttons.dismiss')}
              onClick={onNoStoreDialogRequestClose}
            />
          </div>
        </div>
      </Modal>
      <Modal
        dialog
        innerScroll
        isOpen={isAddressManagerOpen}
        onRequestClose={onAddressManagerRequestClose}
        style={{
          content: {
            position: 'relative',
            minHeight: '15rem',
            paddingTop: 0,
          },
        }}
      >
        {
          deliveryAddressesLoading && <Loading />
        }
        {
          !deliveryAddressesLoading && (
            <Button
              className={styles.closeButton}
              iconClassName={styles.closeButtonIcon}
              inline
              iconImage={Cross}
              onClick={onAddressManagerRequestClose}
            />
          )
        }
        <AddressManager
          addresses={deliveryAddresses}
          addressSelectable
          addressType="delivery"
          closeAfterSelectAddress
          defaultAddress={
            _.find(deliveryAddresses, { isPrimary: true })
            || _.first(deliveryAddresses)
          }
          onAddress
          onClose={onAddressManagerRequestClose}
          onDeleteAddress={handleDeleteAddress}
          onFetchDeliveryAddresses={onFetchDeliveryAddresses}
          onSelectAddress={onSelectDeliveryAddress}
          onUpdateDeliveryAddress={onUpdateDeliveryAddress}
          selectedAddress={_.find(deliveryAddresses, { id: _.get(deliveryAddress, 'id') })}
        />

      </Modal>
    </>
  )
}
