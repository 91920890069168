import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@keyframes loading': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  productAddons: {
    position: 'relative',
    pointerEvents: 'none',
    overflow: 'hidden',

    '&::after': {
      content: '""',
      display: 'block',
      opacity: 0.5,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to right, ${hexToRgba(theme.colors.background, 0)} 0%,${hexToRgba(theme.colors.background, 1)} 50%,${hexToRgba(theme.colors.background, 0)} 100%)`,
      transform: 'translateX(-100%)',
      animationName: '$loading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
  },
  section: {},
  header: {
    padding: '1.2rem 1.6rem',
    backgroundColor: theme.colors.light,
    borderTop: '0.1rem solid #eee',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.6rem',
    borderTop: '0.1rem solid #eee',
  },
  text: {
    height: '1.7rem',
    // marginBottom: '1rem',
    backgroundColor: '#eee',
    borderRadius: '0.5rem',
    display: 'block',
  },
  title: {
    extend: 'text',
    height: '2rem',
    width: '30%',
  },
  itemName: {
    extend: 'text',
    width: '50%',
  },
  itemControl: {
    extend: 'text',
    width: '1.7rem',
  },
}))

export default useStyles
