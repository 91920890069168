/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import _ from 'lodash'
import URI from 'urijs'
import React, { useState, useEffect } from 'react'
import { useSystemSettings } from 'react-omnitech-api'

import CompareContext from './compare-context'
import { useAlert } from '../use-alert'
import { useLink } from '../use-link'

const CompareProvaider = ({ children }) => {
  const [compareData, setCompareData] = useState([])
  const [idsArray, setIdsArray] = useState([])

  const [hasMoreThan, setHasMoreThan] = useState(false)
  const { navigate } = useLink()
  const [isOpen, setIsOpen] = useState(false)
  const alert = useAlert()
  const { getSystemSetting } = useSystemSettings()

  const enableComparisonEcom = getSystemSetting('frontend.product.comparison.enable.ecom')
  const maxNumberComparisonEcom = getSystemSetting('frontend.product.comparison.max_number_item_to_compare.ecom')
  function storeData(products) {
    window.localStorage.setItem('compareProducts', JSON.stringify(products))
  }

  function addItemToCompare(item) {
    let values
    if (!_.find(compareData, item)) {
      values = [...compareData, item]
      setCompareData(values)
      storeData(values)
    } else {
      values = _.filter(compareData, (e) => e.id !== item.id)
      setCompareData(values)
      storeData(values)
    }
  }

  function addItemsToCompare(items) {
    setCompareData(items)
    storeData(items)
  }

  function clearItemToCompare() {
    setCompareData([])
    window.localStorage.setItem('compareProducts', JSON.stringify([]))
  }

  function goToCompareProducts() {
    navigate(`/products-comparison/?id[]=${_.map(compareData, 'id')}`)
  }

  useEffect(() => {
    const values = JSON.parse(window.localStorage.getItem('compareProducts'))
    if (values && values.length > 0) setCompareData(values)
  }, [])

  useEffect(() => {
    const values = JSON.parse(window.localStorage.getItem('compareProductsIds'))
    if (values && values.length > 0) setIdsArray(values)
  }, [])

  useEffect(() => {
    setHasMoreThan(compareData.length >= maxNumberComparisonEcom)
    setIsOpen(!_.isEmpty(compareData))
  }, [compareData, maxNumberComparisonEcom])

  useEffect(() => {
    if (compareData.length > maxNumberComparisonEcom) {
      alert.show('too muchs products', { state: 'error' })
    }
  }, [compareData, alert, maxNumberComparisonEcom])

  const state = {
    compareData,
    isOpen,
    hasMoreThan,
    enableComparisonEcom,
    maxNumberComparisonEcom,
    goToCompareProducts,
    addItemToCompare,
    addItemsToCompare,
    clearItemToCompare,
  };

  return (
    <CompareContext.Provider value={state}>
      {children}
    </CompareContext.Provider>
  )
}

export default CompareProvaider
