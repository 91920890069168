import _ from 'lodash'
import React, { useRef } from 'react'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './product-addons-style'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductAddonsPlaceholderView = (props) => {
  const {
    sectionCount = 2,
    className,
    sectionClassName,
    headerClassName,
    titleClassName,
    contentClassName,
  } = props
  const styles = useStyles()
  const itemRandomNumbers = useRef(
    _.times(
      sectionCount,
      () => (
        _.times(
          _.random(2, 5),
          () => (_.random(30, 70)),
        )
      ),
    ),
  )

  return (
    <div className={combineClassNames([styles.productAddons, className])}>
      {
        _.times(sectionCount, (sectionIndex) => (
          <div
            key={`product_addons_section_${sectionIndex}`}
            className={combineClassNames([styles.section, sectionClassName])}
          >
            <div className={combineClassNames([styles.header, headerClassName])}>
              <div className={combineClassNames([styles.title, titleClassName])} />
            </div>
            {
              _.times(
                _.size(_.get(itemRandomNumbers, `current.${sectionIndex}`, [])),
                (itemIndex) => (
                  <div
                    key={`product_addons_section_${sectionIndex}_itme_${itemIndex}`}
                    className={combineClassNames([styles.content, contentClassName])}
                  >
                    <div
                      className={styles.itemName}
                      style={{ width: `${_.get(itemRandomNumbers, `current.${sectionIndex}.${itemIndex}`)}%` }}
                    />
                    <div className={styles.itemControl} />
                  </div>
                ),
              )
            }
          </div>
        ))
      }
    </div>
  )
}

export default ProductAddonsPlaceholderView
