/* eslint-disable no-param-reassign */
import _ from 'lodash'
import URI from 'urijs'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { globalHistory } from '@reach/router'
import {
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import isBrowser from '../../helpers/is-browser'
import { useAlert } from '../../hook/use-alert'
import useProduct from '../../hook/use-product'
import useCustomerRankSettings from '../../hook/use-customer-rank-settings'
import { useThemeConfig } from '../../hook/use-theme-config'
import { useModal } from '../../hook/use-modal'
import { useLink } from '../../hook/use-link'
import { useLocation } from '../../hook/use-location'
import ProductQuickAddModalView from './product-quick-add-modal-view'

export default function ProductQuickAddModalController(props) {
  const {
    isOpen = false,
    id,
    colorOptionId,
    sizeOptionId,
    groupUuid,
    onRequestClose,
    onAddToCartSuccess,
    onOpen,
    onClose,
  } = props
  const { t } = useTranslation()
  const { default: defaultRank } = useCustomerRankSettings()
  const { getConfig } = useThemeConfig()
  const {
    loyaltyInformation,
  } = useUser()
  const alert = useAlert()
  const modal = useModal()
  const { location: loc } = useLocation()
  const { navigate } = useLink()
  const { getSystemSetting } = useSystemSettings()
  const fnbEnabled = getSystemSetting('features.fnb.enable')
  const favouritesEnabled = !getSystemSetting('hide_favourites', false)
  let enableQuantityInput = getConfig('config.pages.product.enableQuantityInput')
  const enableRecentlyViewed = getConfig('config.pages.product.enableRecentlyViewed', false)
  // use fnbEnabled as fallback
  if (!_.isBoolean(enableQuantityInput)) {
    enableQuantityInput = fnbEnabled
  }
  const enableQuantityInputValuePicker = getConfig('config.pages.product.enableQuantityInputValuePicker', false)
  const quantityInputValueEditMode = getConfig(
    'config.pages.product.quantityInputValueEditMode',
    enableQuantityInputValuePicker ? 'picker' : 'default',
  )
  // Configure when the content will show up
  // ['always', 'auto', 'never']
  const contentDisplayApproach = getConfig('config.ui.productQuickAddModal.contentDisplayApproach', 'always')

  const {
    // ...taskStates,
    addItemsToCartState,
    // addonsState,
    backInStockNotificationState,
    // cartState: fetchCartForEditState,
    initState,
    // noCacheState,
    // siblingsState,

    addToCartButtonState,
    loadings,
    // tasks,
    availableQuantity,
    stockLevel,
    product,
    seoMetas,
    productAddons,
    productQuantity,
    selectedColorOptionId,
    selectedSizeOptionId,
    siblings,
    siblingsProductCodes,
    addonsValue,
    addonsTouched,
    isAddonsValid,
    // storeMenuCodes,
    onAddToCart,
    onBackInStockNotification: createBackInStockNotification,
    onFavouriteChange,
    onColorOptionChange,
    onSizeOptionChange,
    onAddonsChange,
    onAddonsUpdate,
    onAddonsValidate,
    onProductQuantityChange,
    addRecentlyViewed,
    getAddonAvailableQuantity,
    getTask,
  } = useProduct(id, {
    cartLinePropertiesGroupUuid: groupUuid,
    includes: _.compact([
      'noCache',
      'addons',
      !isEdit && 'siblings',
    ]),
    defaultSelectedColorOptionId: colorOptionId,
    defaultSelectedSizeOptionId: sizeOptionId,
  })

  const isEdit = useMemo(() => (
    !_.isEmpty(groupUuid)
  ), [groupUuid])

  const currentRank = _.get(loyaltyInformation, 'currentCustomerRank.code', defaultRank)
  const selectedSku = _.find(
    _.get(product, 'skus', []),
    (sku, index) => {
      if (selectedColorOptionId && selectedSizeOptionId) {
        return sku.colorOptionId === selectedColorOptionId
          && sku.sizeOptionId === selectedSizeOptionId
      }
      if (selectedColorOptionId) {
        return sku.colorOptionId === selectedColorOptionId
      }
      return index === 0
    },
  )
  const minimumBundlePrice = _.get(selectedSku, 'meta.priceDisplayMode') === 'minimum_bundle_price'
    ? _.get(selectedSku, 'meta.minimumBundlePrice', null)
    : null
  const haveSiblings = !_.isEmpty(siblingsProductCodes)
  const priceSuffix = (haveSiblings || _.isNumber(minimumBundlePrice)) && t('screens.product.price.up')
  const lowestSellPriceSibling = _.reduce(siblings, (result, sibling) => {
    if (
      _.toNumber(_.get(result, 'sellPrice', 0))
      > _.toNumber(_.get(sibling, 'sellPrice', 0))
    ) {
      result = sibling
    }
    return result
  }, _.first(siblings))
  let displaySellPrice = _.get(selectedSku, 'sellPrice', null)
  if (haveSiblings) displaySellPrice = _.get(lowestSellPriceSibling, 'sellPrice')
  if (_.isNumber(minimumBundlePrice)) displaySellPrice = minimumBundlePrice
  let displayOriginalPrice = _.get(selectedSku, 'originalPrice', null)
  if (haveSiblings) displayOriginalPrice = _.get(lowestSellPriceSibling, 'originalPrice')
  if (_.isNumber(minimumBundlePrice)) displayOriginalPrice = null
  let displayMemberPrice = _.get(selectedSku, `meta.memberPrice.${_.camelCase(currentRank)}`)
  if (haveSiblings) displayMemberPrice = _.get(lowestSellPriceSibling, `meta.memberPrice.${_.camelCase(currentRank)}`)
  if (_.isNumber(minimumBundlePrice)) displayMemberPrice = null

  const haveSelections = useMemo(() => {
    if (haveSiblings) return true
    if (!_.isEmpty(_.get(product, 'productAddonIds'))) return true
    if (_.size(_.get(product, 'skus', [])) > 1) return true
    return false
  }, [haveSiblings, product])

  const showContent = useMemo(() => {
    // Show content after product init success
    const init = initState === 'success'
    switch (contentDisplayApproach) {
      case 'always':
        return init
      case 'never':
        return false
      default:
        return init && haveSelections
    }
  }, [contentDisplayApproach, haveSelections, initState])

  const urlParams = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      search = URI(loc.href).search(true)
    }
    return search
  }, [loc])

  const recommendationOptions = useMemo(() => ({
    products: _.get(product, 'id'),
    colorOptions: selectedColorOptionId || _.get(product, 'defaultColorOptionId'),
    skus: _.get(_.find(_.get(product, 'skus', []), { colorOptionId: _.get(product, 'defaultColorOptionId') }), 'id'),
    trackEventOptions: {
      list: 'PDP Related Products',
    },
  }), [product, selectedColorOptionId])

  const onRequestUser = () => {
    const {
      page,
      search,
    } = loc
    const redirectUrl = `${page}${search}`
    navigate(
      '/login/',
      {
        state: {
          redirectUrl,
          // throw back selected order method when come back from login
          // callbackState: { preSelectedOrderMethod: selectedItem, isBlocker },
        },
        replace: true,
      },
    )
  }

  const onBackInStockNotification = () => {
    createBackInStockNotification({
      frontendUrlParams: urlParams,
    })
  }

  useEffect(() => {
    if (addItemsToCartState === 'success') {
      if (_.isFunction(onAddToCartSuccess)) {
        onAddToCartSuccess()
      }
      onRequestClose()
    }
  }, [addItemsToCartState])

  useEffect(() => {
    if (backInStockNotificationState === 'success') {
      // show modal dialog
      modal.open({
        title: t('screens.product.modalDialog.backInStockNotificationsSuccess.title'),
        message: t('screens.product.modalDialog.backInStockNotificationsSuccess.description'),
        buttons: [
          {
            text: t('screens.product.modalDialog.backInStockNotificationsSuccess.buttons.cancel'),
            onClick: () => modal.close(),
          },
        ],
      })
    } else if (backInStockNotificationState === 'failure') {
      const backInStockNotificationTask = getTask('backInStockNotification')
      const error = _.get(backInStockNotificationTask, 'error', {})
      const generalError = _.get(error, 'generalError', {})
      if (_.get(generalError, 'code') === 401) {
        onRequestUser()
      } else {
        alert.show(generalError.message, { state: 'error' })
      }
    }
  }, [backInStockNotificationState])

  useEffect(() => {
    if (!enableRecentlyViewed) return
    if (isEdit) return
    if (initState !== 'success') return
    const productId = _.get(product, 'id')
    const url = _.get(product, 'canonicalHref')
    addRecentlyViewed({
      colorOptionId: selectedColorOptionId,
      productId,
      url,
    })
  }, [
    addRecentlyViewed,
    initState,
    isEdit,
    enableRecentlyViewed,
    selectedColorOptionId,
  ])

  useEffect(() => {
    if (isOpen) {
      if (_.isFunction(onOpen)) {
        onOpen()
      }
    } else if (_.isFunction(onClose)) {
      onClose()
    }
  }, [isOpen])

  // when going to a new page, close the modal
  useEffect(() => {
    onRequestClose()
  }, [globalHistory.location.href])

  const viewProps = {
    isOpen,
    showContent,

    // Product detials
    addToCartButtonState,
    // fetchCartForEditState,
    loadings,
    availableQuantity,
    stockLevel,
    product,
    seoMetas,
    productAddons,
    productQuantity,
    selectedColorOptionId,
    selectedSizeOptionId,
    siblings,
    addonsValue,
    addonsTouched,
    isAddonsValid,
    recommendationOptions,
    onAddToCart,
    onBackInStockNotification,
    onFavouriteChange,
    onColorOptionChange,
    onSizeOptionChange,
    onAddonsChange,
    onAddonsUpdate,
    onAddonsValidate,
    onProductQuantityChange,
    addRecentlyViewed,
    getAddonAvailableQuantity,
    onRequestClose,

    // price
    memberPrice: displayMemberPrice,
    sellPrice: displaySellPrice,
    originalPrice: displayOriginalPrice,
    priceSuffix,

    // config
    enableRecentlyViewed,
    favouritesEnabled,
    quantityInputValueEditMode,
  }
  return (
    <ProductQuickAddModalView
      {...viewProps}
    />
  )
}
