import React from 'react'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import CouponMonthBlock from '../../../coupon-month-block'
import CouponCard from '../../../coupon-card'
import Spinner from '../../../spinner'

export default function CouponsTabPanel({
  groups,
  hasMore,
  loadMore,
  getItemData,
  type,
  onSelect,
}) {
  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={<Spinner key="loding-spinner" />}
      useWindow={false}
    >
      {
        _.map(groups, (group) => (
          <CouponMonthBlock
            miniCartCoupon
            key={`coupon-${type}-${group.index}`}
            sectionHeaderText={_.get(group, 'title')}
          >
            {
              _.get(group, 'data', []).map((data) => {
                const {
                  title,
                  discountType,
                  discountValue,
                  description,
                  displayTime,
                  displayTimeLabel,
                  imageUrl,
                  sharedLabel,
                  status,
                  statusLabel,
                  disabled,
                  requiredPoints,
                  isExpired,
                  isTransferable,
                  isTransferInProgress,
                  isNonStarted,
                  itemType = type,
                  usableCouponTokenCount,
                  couponId,
                } = getItemData(data)
                return (
                  <CouponCard
                    miniCartCoupon
                    key={`coupon-${type}-${data.id}`}
                    type={itemType}
                    id={couponId || data.id}
                    displayImage
                    imageUrl={imageUrl}
                    title={title}
                    discountType={discountType}
                    discountValue={discountValue}
                    description={description}
                    displayTimeLabel={displayTimeLabel}
                    displayTime={displayTime}
                    sharedLabel={sharedLabel}
                    status={status}
                    statusLabel={statusLabel}
                    disabled={disabled}
                    requiredPoints={requiredPoints}
                    isExpired={isExpired}
                    isTransferable={isTransferable}
                    isTransferInProgress={isTransferInProgress}
                    isNonStarted={isNonStarted}
                    usableCouponTokenCount={usableCouponTokenCount}
                    onSelect={onSelect}
                  />
                )
              })
            }
          </CouponMonthBlock>
        ))
      }
    </InfiniteScroll>
  )
}
