/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */

import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'react-omnitech-api'
// import { Helmet } from 'react-helmet'
import Title from '../title-detail'
import ProductCard from '../product-card'
import Button from '../button'
import {
  useCustomerRankSettings,
} from '../../hook'

import ArrowFat from '../../assets/icons/icon_solid_arrow.svg'
import { combineClassNames } from '../../helpers'
import { useThemeConfig } from '../../hook/use-theme-config'
import useContentGroupStyles from '../content-group/templates/content-group-style'
import useStyles from './product-group-style'

function ProductGroupView(props) {
  const {
    className,
    currency,
    productGroupCode,
    productGroupLines,
    title,
    siblings,
    siblingsLoading,
    onClick,
    onClickTrackEvent,
    onProductClick,
  } = props
  const { t } = useTranslation()
  const { default: defaultRank } = useCustomerRankSettings()
  const {
    loyaltyInformation,
  } = useUser()
  const { getConfig } = useThemeConfig()
  const enableQuickAddItem = getConfig('config.enableQuickAddItem', false)
  const currentRank = _.get(loyaltyInformation, 'currentCustomerRank.code', defaultRank)
  const contentGroupStyles = useContentGroupStyles()
  const styles = useStyles()

  if (productGroupLines.length === 0) return <div className={styles.placeholderDiv} />

  const getProductProps = (item, index) => {
    const siblingsProductCodes = _.get(item, 'sku.product.meta.siblingsProductCodes', [])
    const thisSiblings = _.filter(siblings, (sibling) => (
      _.includes(siblingsProductCodes, _.get(sibling, 'product.code', '////'))
    ))
    const minimumBundlePrice = _.get(item, 'sku.meta.priceDisplayMode') === 'minimum_bundle_price'
      ? _.get(item, 'sku.meta.minimumBundlePrice', null)
      : null
    const haveSiblings = !_.isEmpty(thisSiblings)
    const priceSuffix = (haveSiblings || _.isNumber(minimumBundlePrice)) && t('screens.product.price.up')
    const lowestSellPriceSibling = _.reduce(thisSiblings, (result, sibling) => {
      if (
        _.toNumber(_.get(result, 'sellPrice', 0))
        > _.toNumber(_.get(sibling, 'sellPrice', 0))
      ) {
        result = sibling
      }
      return result
    }, _.first(thisSiblings))
    let displaySellPrice = _.get(item, 'sku.colorOption.sellPrice', null)
    if (haveSiblings) displaySellPrice = _.get(lowestSellPriceSibling, 'sellPrice')
    if (_.isNumber(minimumBundlePrice)) displaySellPrice = minimumBundlePrice
    let displayOriginalPrice = _.get(item, 'sku.colorOption.originalPrice', null)
    if (haveSiblings) displayOriginalPrice = _.get(lowestSellPriceSibling, 'originalPrice')
    if (_.isNumber(minimumBundlePrice)) displayOriginalPrice = null
    let displayMemberPrice = _.get(item, `sku.meta.memberPrice.${_.camelCase(currentRank)}`)
    if (haveSiblings) displayMemberPrice = _.get(lowestSellPriceSibling, `meta.memberPrice.${_.camelCase(currentRank)}`)
    if (_.isNumber(minimumBundlePrice)) displayMemberPrice = null
    const itemProps = {
      className: styles.productCard,
      productId: _.get(item, 'sku.product.id'),
      colorOptionVariantType: _.get(item, 'sku.product.colorOptionVariantType', {}),
      imageUrl: _.get(item, 'sku.colorOption.defaultImage.versions.webLarge'),
      title: _.get(item, 'sku.product.title'),
      colorName: _.get(item, 'sku.colorOption.name'),
      colorOptionId: _.get(item, 'sku.colorOption.id'),
      sellPrice: displaySellPrice,
      originalPrice: displayOriginalPrice,
      memberPrice: displayMemberPrice,
      priceSuffix,
      priceLoading: siblingsLoading,
      activeCustomLabels: _.get(item, 'sku.colorOption.activeCustomLabels'),
      favourite: _.get(item, 'sku.colorOption.favourite'),
      url: _.get(item, 'sku.product.canonicalHref'),
      sku: _.get(item, 'sku'),
      stockLevel: _.get(item, 'sku.colorOption.stockLevel'),
      index: index + 1,
      list: title,
      onClick: enableQuickAddItem ? onProductClick : null,
      onClickTrackEvent,
    }
    return itemProps
  }

  const urlNavigation = _.isNumber(productGroupCode)
    ? `/products/?productGroupId=${productGroupCode}`
    : `/products/?productGroupCodeEq=${productGroupCode}`

  return (
    <div className={combineClassNames([styles.productGroupStyle, className])}>
      {/* TODO: Need to check why price and stock level is not loaded correctly
      in the html output */}
      {/* <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'ItemList',
            name: title,
            itemListElement: _.map(productGroupLines, (item, index) => {
              const itemProps = getProductProps(item, index)
              return ({
                '@type': 'Product',
                name: item.title,
                // description: pi.description,
                image: item.imageUrl,
                url: item.url,
                offers: {
                  '@type': 'Offer',
                  availability: _.isNull(item.stockLevel) || item.stockLevel > 0
                    ? 'https://schema.org/InStock'
                    : 'https://schema.org/SoldOut',
                  price: item.sellPrice,
                  priceCurrency: _.get(currency, 'code'),
                },
              })
            }),
          })}
        </script>
      </Helmet> */}
      <Title
        text={title}
        className={styles.title}
      />
      <div className={combineClassNames([styles.productGroupRow])}>
        {
          productGroupLines.map((item, index) => {
            const itemProps = getProductProps(item, index)
            return (
              <ProductCard
                key={`product-group-${_.get(item, 'sku.id', '')}`}
                item={item}
                {...itemProps}
              />
            )
          })
        }
      </div>
      <div className={contentGroupStyles.buttonMoreStyle}>
        <Button
          inline
          text={t('screens.home.ui.productsGroup.more')}
          navigate={urlNavigation}
          iconImage={ArrowFat}
          onClick={onClick}
          className={contentGroupStyles.moreButton}
          iconClassName={contentGroupStyles.moreButtonIcon}
        />
      </div>
    </div>
  )
}

export default ProductGroupView
