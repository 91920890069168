import { createUseStyles } from 'react-jss'
import themeConfigStyleService from '../../services/theme-config-style-service'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    '*': {
      boxSizing: 'inherit',
      '&:after, &:before': {
        boxSizing: 'inherit',
      },
    },
    html: {
      fontSize: '10px',
      boxSizing: 'border-box',
    },
    img: {
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      marginBottom: '1.687rem',
    },
    body: {
      // overflow: 'hidden',
      background: `white url(${theme.config.backgroundUrl})`,
      backgroundSize: 'cover !important',
      backgroundAttachment: 'fixed !important',
      fontSize: 10,
      fontFamily: [`${theme.typo.fontFamilyBody}`, 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
      color: theme.colors.text,
      fontStretch: 'normal',
      lineHeight: 1.678,
      fontVariantLigatures: 'normal',
      fontVariantCaps: 'normal',
      fontVariantNumeric: 'normalize',
      fontVariantEastAsian: 'normal',
      fontFeatureSettings: 'kern, liga, clig, calt',
      '& p, & li, & span': {
        fontSize: theme.typo.textBodySize,
        lineHeight: '2.84597rem',
        fontWeight: 400,
        margin: 0,
        padding: 0,
        textRendering: 'optimizeLegibility',
      },
      '& ul': {
        margin: 0,
        padding: 0,
      },
      '& li': {
        marginBottom: 'calc(1.687rem / 2)',
      },
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: '1.687rem',
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        textRendering: 'optimizeLegibility',
        fontWeight: 600,
      },
      '& h1': {
        fontSize: '4.8rem',
        lineHeight: '3.374rem',

      },
      '& h2': {
        fontSize: '3.6rem',
        lineHeight: '3.0366rem',

      },
      '& h3': {
        fontSize: '2.8rem',
        lineHeight: '2.6992rem',

      },
      '& h4': {
        fontSize: '2.4rem',
        lineHeight: '2.3618rem',

      },
      '& h5': {
        fontSize: '1.8rem',
        lineHeight: '2.0244rem',

      },
      '& h6': {
        fontSize: '1.6rem',
        lineHeight: '2.5305rem',
      },
      '&[class*=modal-open]': {
        overflow: 'hidden',
        height: '100vh',
        width: '100%',
        position: 'fixed',
      },
    },
    ...themeConfigStyleService.getStyles({
      theme,
      isRoot: true,
    }),
  },
  containerFluid: {
    padding: [50, 0, 0],
    width: '100%',
    minHeight: '100vh',
  },
  container: {
    maxWidth: '100%',
    margin: [[0, 'auto']],
  },
  header: {
    position: 'fixed',
    zIndex: 12,
    top: 0,
    left: 0,
    transition: 'left 300ms',
  },
  [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
    containerFluid: {
      padding: 0,
      width: '100%',
      minHeight: '90rem',
    },
    container: {
      maxWidth: 1170,
      margin: [[0, 'auto']],
    },
    header: {
      position: 'relative',
    },
  },
}))

export default useStyles
