/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './social-media-style'
import iconBuilder from '../../helpers/iconBuilder'
import IconLink from '../../assets/icons/icon_link.svg'
import combineClassNames from '../../helpers/combineClassNames'

const SocialMediaView = (props) => {
  const { t } = useTranslation()
  const {
    onShare,
    className,
    socialMediaActive,
    hasAnySocialMediaActive,
    copyToClipboard,
    show,
    logosClassName,
    itemClassName,
    titleClassName,
    iconClassName,
  } = props
  const styles = useStyles()
  if (!hasAnySocialMediaActive) return null
  return (
    <div className={combineClassNames([styles.container, className])}>
      <h6
        className={combineClassNames([
          styles.title,
          titleClassName,
        ])}
      >
        {t('ui.socialmedia.share')}
      </h6>
      <div
        className={combineClassNames([
          styles.logos,
          logosClassName,
        ])}
      >
        {
          Object.entries(socialMediaActive).map(([key, value]) => {
            if (!value.isActive) return null
            return (
              <i
                key={key}
                onClick={onShare.bind(this, key)}
                className={combineClassNames([
                  styles.item,
                  itemClassName,
                ])}
              >
                <img
                  className={combineClassNames([
                    styles.icon,
                    iconClassName,
                  ])}
                  src={iconBuilder(key)}
                  alt={key}
                />
              </i>
            )
          })
        }
        <i
          className={combineClassNames([
            styles.item,
            itemClassName,
            styles.link,
          ])}
          onClick={copyToClipboard}
        >
          <div className={combineClassNames([styles.popup, show && styles.show])}>
            <span>{t('ui.socialmedia.copied')}</span>
          </div>
          <img
            className={combineClassNames([
              styles.icon,
              iconClassName,
            ])}
            src={IconLink}
            alt="link"
          />
        </i>
      </div>
    </div>
  )
}

export default SocialMediaView
