import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment/min/moment-with-locales'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { useSystemSettings } from 'react-omnitech-api'
import { useCouponMarketplace, useThemeConfig } from '../../../../hook'
import useStyles from './coupon-marketplace-tabs-style'
import {
  convertItemsToSectionList,
  isExpireSoon,
  isExpired,
} from '../../../../helpers'
import MessageScreen from '../../../message-screen'
import CouponMarketplaceHeader from '../coupon-marketplace-header'
import CouponsTabPanel from './coupons-tab-panel'
import Spinner from '../../../spinner'

const CouponMarketplaceTabsView = (props) => {
  const {
    coupons,
    couponTokens,
    hasMoreCoupons,
    hasMoreCouponTokens,
    isCouponsReady,
    isCouponTokensReady,
    userCurrentPoints,
    onFetchCouponsNextPage,
    onFetchCouponTokensNextPage,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()
  const {
    onCouponMarketplaceClose,
    onCouponDetailOpen,
  } = useCouponMarketplace()
  const { getSystemSetting } = useSystemSettings()
  const { getConfig } = useThemeConfig()
  const hideLoyaltyPoints = getConfig('config.hideLoyaltyPoints', false)
  const myCouponsDisplayMode = getSystemSetting(
    'api.v2.coupon_tokens.index.my_coupons.ecom.display_mode',
    'date',
  )
  const marketPlaceDisplayMode = getSystemSetting(
    'api.v2.coupons.index.market_places.ecom.display_mode',
    'date',
  )
  const enableUniqueByCoupon = getConfig(
    'config.ui.minicartCoupons.myCoupons.enableUniqueByCoupon',
    true,
  )

  const [tabIndex, setTabIndex] = useState(0);

  const getCouponToItem = (coupon = {}) => {
    const {
      description,
      discountType,
      discountValue,
      endAt,
      name,
      // redeemableAvailableForUserRedeem,
      redeemableRequiredLoyaltyPoints,
      startAt,
    } = coupon
    const displayTime = `${moment(startAt).format('DD.MM.YYYY')} - ${moment(endAt).format('DD.MM.YYYY')}`
    return {
      imageUrl: _.get(coupon, 'defaultImage.versions.webSmall'),
      title: name,
      discountType,
      discountValue,
      description,
      displayTime,
      displayTimeLabel: t('ui.minicartCoupons.coupons.redemptionPeriod'),
      status: '',
      // disabled: !redeemableAvailableForUserRedeem,
      requiredPoints: redeemableRequiredLoyaltyPoints,
    }
  }

  const getCouponTokenToItem = (couponToken = {}) => {
    const {
      canActions = [],
      coupon,
      computedGracePeriodExpiresAt: endAt,
      computedStartAt,
      sharedAt,
      status,
      statusLabel,
      transferState,
    } = couponToken
    const {
      description,
      discountType,
      discountValue,
      name,
    } = coupon || {}
    const displayTime = `${moment(computedStartAt).format('DD.MM.YYYY')} - ${moment(endAt).format('DD.MM.YYYY')}`
    const isTransferable = !_.isEmpty(
      _.intersection(
        canActions,
        ['transfer_out', 'transfer_in', 'transfer_undo'],
      ),
    )
    const usableCouponTokenCount = _.get(coupon, 'usableCouponTokenCount', 1)
    const sharedLabel = _.isNil(sharedAt)
      ? sharedAt
      : t(
        'ui.minicartCoupons.myCoupons.sharedAt',
        { time: moment(sharedAt).format('DD.MM.YYYY') },
      )
    return {
      imageUrl: _.get(coupon, 'defaultImage.versions.webSmall'),
      title: name,
      discountType,
      discountValue,
      description,
      displayTime,
      displayTimeLabel: t('ui.minicartCoupons.myCoupons.validPeriod'),
      sharedLabel,
      status,
      statusLabel,
      isExpired: moment().isAfter(endAt),
      isTransferable: isTransferable && transferState !== 'completed',
      isTransferInProgress: transferState === 'transfer_in_progress',
      isNonStarted: status === 'non_started',
      ...(
        enableUniqueByCoupon
          ? { usableCouponTokenCount }
          : {}
      ),
      ...(
        enableUniqueByCoupon && usableCouponTokenCount > 1
          ? { couponId: _.get(coupon, 'id'), itemType: 'couponTokensGroupByCoupon' }
          : {}
      ),
    }
  }

  const handleOpenCouponTab = () => {
    handleTabSelect(1)
  }

  const handleTabSelect = (index) => {
    setTabIndex(index)
  }

  /**
   * grouping coupons by month or coupon_group
   */
  const couponsListData = useMemo(() => (
    convertItemsToSectionList({
      items: coupons,
      sectionTitle: (item) => {
        const date = new Date(item.endAt)
        const dateFormat = t('ui.dateFormat.month', { defaultValue: 'MMMM YYYY' })
        const formatedDate = moment(date).format(dateFormat)
        if (
          marketPlaceDisplayMode === 'coupon_group'
          && !_.every(
            coupons,
            (coupon) => _.isNil(_.get(coupon, 'couponGroup')),
          ) // Prevent only one default group
        ) {
          return _.get(
            item,
            'couponGroup.name',
            t('ui.minicartCoupons.coupons.couponGroupDefault'),
          )
        }
        if (isExpired(date)) return t('ui.minicartCoupons.expire', { context: 'expired' })
        if (isExpireSoon(date)) return t('ui.minicartCoupons.expire', { context: 'expireSoon' })
        return t('ui.minicartCoupons.expire', { month: formatedDate })
      },
    })
  ), [coupons])

  /**
   * grouping coupon tokens by month
   */
  const couponTokensListData = useMemo(() => (
    convertItemsToSectionList({
      items: couponTokens,
      sectionTitle: (item) => {
        const date = new Date(item.computedGracePeriodExpiresAt)
        const dateFormat = t('ui.dateFormat.month', { defaultValue: 'MMMM YYYY' })
        const formatedDate = moment(date).format(dateFormat)
        if (
          myCouponsDisplayMode === 'coupon_group'
          && !_.every(
            couponTokens,
            ({ coupon }) => _.isNil(_.get(coupon, 'couponGroup')),
          ) // Prevent only one default group
        ) {
          return _.get(
            item,
            'coupon.couponGroup.name',
            t('ui.minicartCoupons.myCoupons.couponGroupDefault'),
          )
        }
        if (isExpired(date)) return t('ui.minicartCoupons.expire', { context: 'expired' })
        if (isExpireSoon(date)) return t('ui.minicartCoupons.expire', { context: 'expireSoon' })
        return t('ui.minicartCoupons.expire', { month: formatedDate })
      },
    })
  ), [couponTokens])

  return (
    <div className={styles.container}>
      <CouponMarketplaceHeader
        title={t('ui.minicartCoupons.heading')}
        onCloseDetail={onCouponMarketplaceClose}
      />
      <Tabs
        selectedIndex={tabIndex}
        className={styles.tabContainer}
        onSelect={handleTabSelect}
      >
        <TabList className={styles.couponsTabs}>
          <Tab className={styles.couponsTab}>{t('ui.minicartCoupons.myCoupons.tab')}</Tab>
          <Tab className={styles.couponsTab}>{t('ui.minicartCoupons.coupons.tab')}</Tab>
        </TabList>
        <TabPanel className={styles.couponsPanel}>
          {
            isCouponTokensReady
              ? (
                <>
                  {
                    couponTokensListData.length > 0
                      ? (
                        <CouponsTabPanel
                          groups={couponTokensListData}
                          hasMore={hasMoreCouponTokens}
                          loadMore={onFetchCouponTokensNextPage}
                          getItemData={getCouponTokenToItem}
                          type="couponToken"
                          onSelect={onCouponDetailOpen}
                        />
                      )
                      : (
                        <div className={styles.noContent}>
                          <MessageScreen
                            message={t('ui.minicartCoupons.myCoupons.message')}
                            button={{
                              className: styles.button,
                              text: t('ui.minicartCoupons.myCoupons.button'),
                              onClick: handleOpenCouponTab,
                            }}
                          />
                        </div>
                      )
                  }
                </>
              )
              : (
                <div className={styles.loading}>
                  <Spinner />
                </div>
              )
          }
        </TabPanel>
        <TabPanel className={styles.couponsPanel}>
          {
            !hideLoyaltyPoints && (
              <div className={styles.pointsCoupons}>
                <p>
                  <Trans i18nKey="ui.minicartCoupons.coupons.points">
                    My Points
                    <span>{{ points: userCurrentPoints }}</span>
                  </Trans>
                </p>
              </div>
            )
          }
          {
            isCouponsReady
              ? (
                <>
                  {
                    couponsListData.length > 0
                      ? (
                        <CouponsTabPanel
                          groups={couponsListData}
                          hasMore={hasMoreCoupons}
                          loadMore={onFetchCouponsNextPage}
                          getItemData={getCouponToItem}
                          type="coupon"
                          onSelect={onCouponDetailOpen}
                        />
                      )
                      : (
                        <div className={styles.noContent}>
                          <MessageScreen
                            message={t('ui.minicartCoupons.coupons.message')}
                            button={{
                              className: styles.button,
                              text: t('ui.minicartCoupons.coupons.button'),
                              navigate: '/',
                            }}
                          />
                        </div>
                      )
                  }
                </>
              )
              : (
                <div className={styles.loading}>
                  <Spinner />
                </div>
              )
          }
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default CouponMarketplaceTabsView
