const mediaQueries = {
  large: 1420,
  desktop: 1170,
  tablet: 769,
  tabletLandscape: 1025,
  mobile: 480,
  minimum: 320,
}

export default mediaQueries
