// import _ from 'lodash'
import React, { useState } from 'react'
import {
  useFavourites,
} from 'react-omnitech-api'
import FavouritesContext from './favourites-context'

/**
 * FavouritesProvider
 */
export default function FavouritesProvider({ children }) {
  const {
    createFavourite,
    deleteFavourite,
    ...other
  } = useFavourites()

  // { [colorOptionId]: [boolean], ... }
  const [touchedFavourites, setTouchedFavourites] = useState({})

  const handleCreateFavourite = (colorOptionId) => (
    createFavourite(colorOptionId)
      .then((data) => {
        setTouchedFavourites(
          (prevtouchedFavourites) => ({
            ...prevtouchedFavourites,
            [colorOptionId]: true,
          }),
        )
        return data
      })
  )
  const handleDeleteFavourite = (colorOptionId) => (
    deleteFavourite(colorOptionId)
      .then((data) => {
        setTouchedFavourites(
          (prevtouchedFavourites) => ({
            ...prevtouchedFavourites,
            [colorOptionId]: false,
          }),
        )
        return data
      })
  )

  const state = {
    createFavourite: handleCreateFavourite,
    deleteFavourite: handleDeleteFavourite,
    touchedFavourites,
    ...other,
  };

  return (
    <FavouritesContext.Provider value={state}>
      {children}
    </FavouritesContext.Provider>
  )
}
