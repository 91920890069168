import _ from 'lodash'

/**
 *
 * @param {array} addons an array of addons
 */
export default function groupOrderLineAddonsWithQuantity(addons = []) {
  const uniqAddons = _.uniqWith(addons, (a, b) => (
    _.isEqual(_.get(a, 'sku.id', 'a'), _.get(b, 'sku.id', 'b'))
    && _.isEqual(_.get(a, 'parentOrderLinePropertyId', 'a'), _.get(b, 'parentOrderLinePropertyId', 'b'))
  ))
  const addonsWithQuantity = _.map(uniqAddons, (addon) => {
    const items = _.filter(addons, (item) => (
      _.isEqual(_.get(addon, 'sku.id', 'a'), _.get(item, 'sku.id', 'b'))
      && _.isEqual(_.get(addon, 'parentOrderLinePropertyId', 'a'), _.get(item, 'parentOrderLinePropertyId', 'b'))
    ))
    const itemCount = _.sumBy(items, 'quantity')
    return {
      ...addon,
      quantity: itemCount,
    }
  })
  return addonsWithQuantity
}
