/* eslint-disable no-unused-expressions */
import _ from 'lodash'
import React, { useState } from 'react'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import { Helmet } from 'react-helmet'
import {
  useLink,
  useMobileMenu,
  useModal,
  useOrderMethodModal,
  useProductQuickAddModal,
  useThemeConfig,
  useUser,
} from '../../hook'
import combineClassNames from '../../helpers/combineClassNames'
import isUserProfileComplete from '../../helpers/is-user-profile-complete'
import Alert from '../alert'
import Footer from '../footer'
import Header from '../header'
import AccountMenu from '../account-menu'
import ContentGroup from '../content-group'
import MiniCart from '../mini-cart'
import DineInMiniCart from '../dine-in-mini-cart'
import MenuMobile from '../menu-mobil'
import MiniCartCoupon from '../mini-cart-coupons'
import Modal from '../modal'
import Button from '../button'
import OrderMethodModal from '../order-method-modal'
import ProductQuickAddModal from '../product-quick-add-modal'

import IconInfo from '../../assets/icons/icon_info.svg'
import useStyles from './layout-style'
import Link from '../link'

function LayoutView({
  children,
  cartButtonDisabled,
  login,
  onLanguageChange,
  availableLocales,
  currentLocale,
  isOpenSearch,
  handelOpenSearch,
  HeaderBottomComponent,
  HeaderTopComponent,
  FooterBottomComponent,
  FooterTopComponent,
  showOrderMethodBlocker,
  // handleOrderMethodBlockerClose,
  onCouponMarketplaceOpen,
  userEditProfileFieldsConfig,
  siteName,
  siteUrl,
}) {
  const {
    user,
  } = useUser()
  const {
    mobileMenuOpen,
    openMobileMenu,
    closeMobileMenu,
  } = useMobileMenu()
  const modal = useModal()
  const orderMethodModalProps = useOrderMethodModal()
  const productQuickAddModalProps = useProductQuickAddModal()
  const { navigate } = useLink()
  const [headerHeight, setHeaderHeight] = useState(0)
  const memberShipCode = _.get(user, 'membershipCode')
  const qrCode = _.get(user, 'qrCodeValue')
  const theme = useTheme()
  const { getContentGroup, getConfig } = useThemeConfig()
  const { t } = useTranslation()
  const styles = useStyles({ headerHeight })
  const extraScripts = _.get(theme, 'config.pages.layout.extraScripts', [{}])
  const onHeaderLayoutChange = (rect) => {
    setHeaderHeight(_.get(rect, 'height', 0))
  }

  const customStylesContentGroupProps = getContentGroup('config.ui.layout.customStyles')
  const enableUserProfileCheck = getConfig('config.enableUserProfileCheck', false)

  // const showOrderMethodBlocker = _.get(orderMethodModalProps, 'isOpen', false)

  const onMemberCodeOpen = () => {
    const enableInfoButton = _.get(theme, 'config.ui.memberCodeModal.infoButton.enable', true)
    const infoPageSlug = _.get(theme, 'config.ui.memberCodeModal.infoButton.pageSlug', 'membership')
    // check user profile first
    if (
      isUserProfileComplete({
        user,
        config: userEditProfileFieldsConfig,
      }) || !enableUserProfileCheck
    ) {
      modal.open({
        title: t('ui.memberModal.title'),
        contentComponent: (
          <>
            <QRCode
              value={qrCode}
              size={170}
            />
            {
              !_.isEmpty(memberShipCode) && (
                <p className={styles.memberNumber}>{memberShipCode}</p>
              )
            }
            <p className={styles.dialogDescription}>{t('ui.memberModal.info')}</p>
          </>
        ),
        buttons: [
          {
            text: t('ui.memberModal.buttons.close'),
            onClick: () => modal.close(),
          },
        ],
        ...(
          enableInfoButton ? {
            infoButton: {
              to: `/pages/${infoPageSlug}`,
              onClick: () => {
                modal.close()
              },
            },
          } : {}
        ),
      })
    } else {
      modal.open({
        title: t('ui.incompleteUserProfileModal.title'),
        message: t('ui.incompleteUserProfileModal.description'),
        buttons: [
          {
            text: t('ui.incompleteUserProfileModal.buttons.editProfile'),
            onClick: () => {
              modal.close()
              navigate('/account/profile/')
            },
          },
          {
            text: t('ui.incompleteUserProfileModal.buttons.cancel'),
            onClick: () => modal.close(),
          },
        ],
      })
    }
  }

  return (
    <div className={combineClassNames([
      styles.containerFluid,
      mobileMenuOpen && styles.containerFluidOpen,
    ])}
    >
      <div className={combineClassNames([
        styles.contianerWrapper,
        mobileMenuOpen && styles.contianerWrapperOpen,
      ])}
      >
        {!_.isEmpty(_.get(theme, 'typo.fontFamilyUrl')) && (
          <Helmet>
            <link href={_.get(theme, 'typo.fontFamilyUrl')} rel="stylesheet" />
          </Helmet>
        )}
        {HeaderTopComponent}
        <Header
          className={
            combineClassNames([
              styles.header,
              mobileMenuOpen && styles.headerOpen,
              !_.isEmpty(theme.config.backgroundUrl) && styles.headerWithBackground,
            ])
          }
          cartButtonDisabled={cartButtonDisabled}
          openMenuMobile={openMobileMenu}
          onLanguageChange={onLanguageChange}
          availableLocales={availableLocales}
          currentLocale={currentLocale}
          isOpenSearch={isOpenSearch}
          handelOpenSearch={handelOpenSearch}
          onLayoutChange={onHeaderLayoutChange}
          onMemberCodeOpen={onMemberCodeOpen}
          qrCode={qrCode}
          siteName={siteName}
          siteUrl={siteUrl}
        />
        {HeaderBottomComponent}
        {login && <AccountMenu handleOpenMemberCode={onMemberCodeOpen} />}
        <div className={styles.container}>{showOrderMethodBlocker ? null : children}</div>
        {FooterTopComponent}
        <Footer
          className={!_.isEmpty(theme.config.backgroundUrl) && styles.footerWithBackground}
          useSuspense={false}
        />
        {FooterBottomComponent}
        <MiniCart />
        <DineInMiniCart />
        <MenuMobile
          login={login}
          openMenuMobile={closeMobileMenu}
          onLanguageChange={onLanguageChange}
          availableLocales={availableLocales}
          currentLocale={currentLocale}
          isOpenSearch={isOpenSearch}
          handelOpenSearch={handelOpenSearch}
          onCouponMarketplaceOpen={onCouponMarketplaceOpen}
          onMemberCodeOpen={onMemberCodeOpen}
          qrCode={qrCode}
        />
        <MiniCartCoupon
          isOutsideCheckout
        />
        <OrderMethodModal
          {...orderMethodModalProps}
        />
        <ProductQuickAddModal
          {...productQuickAddModalProps}
        />
        <Modal
          dialog
          className={styles.dialogModal}
          overlayClassName={styles.dialogModalOverlay}
          titleClassName={styles.dialogTitle}
          isOpen={modal.isOpen}
          title={modal.title}
        >
          {
            modal.infoButton && (
              <Link
                gatsbyLink
                className={styles.dialogInfoButton}
                {...modal.infoButton}
              >
                <img src={IconInfo} alt="info" className={styles.dialogInfoButtonImage} />
              </Link>
            )
          }
          <div className={styles.dialogContainer}>
            <div className={styles.dialogBody}>
              {
                !_.isEmpty(modal.message) && (
                  <p className={styles.dialogDescription}>{modal.message}</p>
                )
              }
              {modal.contentComponent}
            </div>
            <div className={styles.dialogButtons}>
              {
                _.map(modal.buttons, (button, index) => (
                  <Button
                    key={`modal_button_${index}`}
                    className={styles.button}
                    dark={index === 0}
                    border={index !== 0}
                    {...button}
                  />
                ))
              }
            </div>
          </div>
        </Modal>
      </div>
      {!_.isEmpty(extraScripts)
        && _.map(extraScripts, (script, idx) => <script key={idx} type={script.type}>{`${script.code}`}</script>)}
      {
        _.has(customStylesContentGroupProps, 'code')
        && <ContentGroup {...customStylesContentGroupProps} />
      }
      <Alert />
    </div>
  )
}

export default LayoutView
