import { createUseStyles } from 'react-jss'
import themeConfigStyleService from '../../services/theme-config-style-service'

const useStyles = createUseStyles((theme) => ({
  title: {
    margin: '3rem 0 1rem',
  },
  placeholderDiv: {
    minHeight: 400,
  },
  productGroupStyle: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginBottom: '2.5rem',
    },
  },
  productCardItem: {
    padding: '0 0.8rem',
    width: '50%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '0 1.5rem',
      width: '25%',
    },
  },
  productGroupRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    gap: 'var(--ui-product-group-list-gap)',
    padding: '1.6rem 0',
  },
  productCard: {
    flexBasis: 'var(--ui-product-group-item-width)',
    ...themeConfigStyleService.getStyles({
      theme,
      overrides: [
        {
          key: 'config.ui.productGroup.item.thumbnail.position',
          styleProperty: '--ui-product-card-flex-direction',
        },
        {
          key: 'config.ui.productGroup.item.thumbnail.position',
          styleProperty: '--ui-product-card-thumbnail-width',
        },
        {
          key: 'config.ui.productGroup.item.thumbnail.position',
          styleProperty: '--ui-product-card-thumbnail-max-width',
        },
        {
          key: 'config.ui.productGroup.item.thumbnail.position',
          styleProperty: '--ui-product-card-content-padding-default',
        },
        {
          key: 'config.ui.productGroup.item.contentAlign',
          styleProperty: '--ui-product-card-content-padding',
        },
        {
          key: 'config.ui.productGroup.item.thumbnail.position',
          styleProperty: '--ui-product-card-content-text-align-default',
        },
        {
          key: 'config.ui.productGroup.item.contentAlign',
          styleProperty: '--ui-product-card-content-text-align',
        },
        {
          key: 'config.ui.productGroup.item.thumbnail.position',
          styleProperty: '--ui-product-card-price-list-justify-content-default',
        },
        {
          key: 'config.ui.productGroup.item.contentAlign',
          styleProperty: '--ui-product-card-price-list-justify-content',
        },
        {
          key: 'config.ui.productGroup.item.padding',
          styleProperty: '--ui-product-card-padding',
        },
        {
          key: 'colors.ui.productGroup.item.backgroundColor',
          styleProperty: '--ui-product-card-background-color',
        },
        {
          key: 'config.ui.productGroup.item.roundedCorners',
          styleProperty: '--ui-product-card-border-radius',
        },
        {
          key: 'config.ui.productGroup.item.thumbnail.roundedCorners',
          styleProperty: '--ui-product-card-image-border-radius',
        },
        {
          key: 'config.ui.productGroup.item.price.position',
          styleProperty: '--ui-product-card-price-container-justify-content',
        },
        {
          key: 'config.ui.productGroup.item.price.fontSize',
          styleProperty: '--ui-product-card-price-font-size',
        },
        {
          key: 'config.ui.productGroup.item.enableDetails',
          styleProperty: '--ui-product-card-content-details-display',
        },
      ],
    }),
  },
}))

export default useStyles
