/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
import React, { useMemo } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useCouponMarketplace } from '../../../../hook'
import useStyles from './coupon-marketplace-detail-style'
import combineClassNames from '../../../../helpers/combineClassNames'
import CouponMarketplaceHeader from '../coupon-marketplace-header'
import CouponMarketplaceCouponInfo from '../coupon-marketplace-coupon-info'
import Button from '../../../button'
import Spinner from '../../../spinner'

const CouponMarketplaceCouponTokenDetailView = ({
  canSelfUse,
  cartCouponTokens = [],
  couponToken = {},
  couponUsage = {},
  isDetailOpen,
  isDetailReady,
  isLoading,
  isOutsideCheckout,
  isShareable,
  showClock,
  shareLoading,
  showQrCodeMask,
  showQrCode,
  showQrCodeValue,
  showSecretQrCode,
  secretQrCodeValue,
  transferLoading,
  onApplyCoupon,
  onCouponDetailClose,
  onRequireCouponSecret,
  onResendTrnasferMessage,
  onShare,
  onTransferIn,
  onTransferOut,
  onTransferUndo,
}) => {
  const { t } = useTranslation()
  const { onCouponMarketplaceClose } = useCouponMarketplace()

  const {
    id,
    coupon = {},
    canActions = [],
    isValid,
    qrCodeValue,
    sharedAt,
    usageLeft,
  } = couponToken
  const { terms, name } = coupon || {}
  // useMemo
  const isCouponTokenApplied = useMemo(() => {
    const appliedCoupon = _.find(cartCouponTokens, (cartCouponToken) => {
      const couponTokenId = _.get(cartCouponToken, 'couponToken.id')
      return couponTokenId === id
    })
    return !_.isEmpty(appliedCoupon)
  }, [id, cartCouponTokens])

  const isSubmitDisabled = isLoading || isCouponTokenApplied || !isValid || isOutsideCheckout
  const isTransferable = !_.isEmpty(
    _.intersection(
      canActions,
      ['transfer_out', 'transfer_in', 'transfer_undo'],
    ),
  )
  const requiredManualTrigger = _.get(coupon, 'cartDiscount.requiredManualTrigger', false)

  const buttonText = useMemo(() => {
    let text = ''
    switch (true) {
      case isOutsideCheckout:
        text = t('ui.minicartCoupons.detail.buttons.applyCheckout')
        break
      case isCouponTokenApplied:
        text = t('ui.minicartCoupons.detail.buttons.couponApplied')
        break
      case !isValid:
        text = t('ui.minicartCoupons.detail.buttons.invalidCoupon')
        break
      default:
        text = t('ui.minicartCoupons.detail.buttons.useCoupon')
        break
    }
    return text
  }, [isCouponTokenApplied, isValid])

  const handleSubmit = () => {
    onApplyCoupon(couponToken.token)
  }

  const styles = useStyles({
    isSubmitDisabled,
  })

  return (
    <div className={
      combineClassNames([styles.containerDetail,
        isDetailOpen && styles.containerDetailOpen])
      }
    >
      <CouponMarketplaceHeader
        title={name}
        withBackButton={_.isEmpty(couponUsage)}
        onBack={onCouponDetailClose}
        onCloseDetail={onCouponMarketplaceClose}
      />
      {
        isDetailReady
          ? (
            <div className={styles.bodyDetail}>
              <div className={styles.content}>
                <CouponMarketplaceCouponInfo
                  canSelfUse={canSelfUse}
                  canActions={_.get(couponToken, 'canActions', [])}
                  isCheckout={!isOutsideCheckout}
                  coupon={coupon}
                  couponUsage={couponUsage}
                  startAt={couponToken.computedStartAt || couponToken.startAt}
                  endAt={couponToken.computedGracePeriodExpiresAt}
                  isShareable={isShareable}
                  isValid={couponToken.isValid}
                  usageLeft={usageLeft}
                  qrCodeValue={qrCodeValue}
                  secretQrCodeValue={secretQrCodeValue}
                  sharedAt={sharedAt}
                  shareLoading={shareLoading}
                  showClock={showClock}
                  showQrCodeMask={showQrCodeMask}
                  showQrCode={showQrCode}
                  showQrCodeValue={showQrCodeValue}
                  showSecretQrCode={showSecretQrCode}
                  status={couponToken.status}
                  transferState={couponToken.transferState}
                  transferLoading={transferLoading}
                  onRequireCouponSecret={onRequireCouponSecret}
                  onResendTrnasferMessage={onResendTrnasferMessage}
                  onShare={onShare}
                  onTransferIn={onTransferIn}
                  onTransferOut={onTransferOut}
                  onTransferUndo={onTransferUndo}
                  validPeriod
                />
                {/* Coupon Terms */}
                <div
                  className={styles.terms}
                  dangerouslySetInnerHTML={{ __html: terms }}
                />
              </div>
            </div>
          )
          : (
            <Spinner />
          )
      }

      {/* TODO: redeem coupon / apply coupon */}
      {/* if user already redeemed all the coupon per each user, show redeemed */}
      {
        isDetailReady
        && _.isEmpty(couponUsage)
        && !requiredManualTrigger
        && !isOutsideCheckout
        && (
          <div className={styles.footerDetail}>
            <Button
              dark
              className={styles.buttonDetail}
              disabled={isSubmitDisabled}
              text={buttonText}
              onClick={handleSubmit}
            />
          </div>
        )
      }
    </div>
  )
}

export default CouponMarketplaceCouponTokenDetailView
