const colors = {
  // mostly change primary and secondary color, don't touch the others
  // don't touch the others if it is not really necessary,
  // these bothh will be enought to adapt the website to the company corporate colors

  // Primary: color is used for Tiltes such as Title in home,
  // PLP departaments or categories, PDP description, you might also like,
  // Blog main title, categories and tag title.
  // Also it is used in buttons of background, paginator background
  primary: '#282828',
  // Seconday: color is used in Details,
  // such as line under title, under main menu.
  secondary: '#fecf0a',
  // Text: It is used for all text blog, articles detail, PDP & PLP (title, price, descriptions)
  text: '#282828',
  textInvert: '#f7f7f7',
  // background: It is used for any background,
  // to pass a dark mode website would be convert this color to black and text color to white
  background: '#fff',

  bgDark: '#bfbfbf',
  light: '#f7f7f7',
  dark: '#282828',
  black: '#000000',
  white: '#ffffff',
  gray: '#676767',
  gray100: '#f7f7f7',
  gray300: '#dcdcdc',
  gray500: '#9b9b9b',
  gray700: '#4c4c4c',
  gray900: '#282828',
  // disabled: It is used for disabled buttons
  disabled: '#9b9b9b',
  // link: It is used for links, Theme01 use same secondaty color, but it can be changed
  link: '#fecf0a',
  // link: It is used for link in registration page, links without color (grey)
  inlineLink: '#414141',
  // hover: It is used for hovers, Theme01 use same secondaty color, but it can be changed
  hover: '#fecf0a',
  // alert: It is used for errors, should always be kind of red, also as negative point (My coupons)
  alert: '#d0021b',
  // active: It is used for pendding, should always be kind of orange,
  // used for pendding orders (My coupons)
  active: '#f1ad4e',
  inactive: '#dcdcdc',
  // success: It is used for success, should always be kind of green,
  // also as positives points (My coupons), complete order
  success: '#2ea500',
  // confirm: It is used for orders/ account as confirm account
  confirm: '#367fa9',
  // facebook: It is used for facebook color, in icons or buttons
  facebook: '#3b5998',
  // overlay: It is used for overlays in Modal,but it is used with 40% of opacity
  overlay: '#000',
}

export default colors
