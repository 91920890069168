/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form, useFormikContext,
} from 'formik'
import AddressFormField from './address-form-field'
import AddressFormGoogleMap from './address-form-google-map'
import Button from '../button'
import Checkbox from '../checkbox'
import useStyles from './address-form-style'

const AddressFormForm = ({
  addressFieldKeys,
  addressType,
  cityOptions,
  countryCallingCodeOptions,
  countryOptions,
  defaultDeliveryCountry,
  disableCancel,
  districtOptions,
  formConfig = {},
  isEdit,
  isPrimaryEditable,
  labelsOverride,
  latLongAreMandatory,
  onCancel,
  onFieldChange,
  onMapCenterChanged,
  onMapDragEnd,
  onMapDragStart,
  onMapPlaceSelect,
  onMapUserCenterChanged,
  onMapZoomChanged,
  stateOptions,
  streetOptions,
}) => {
  const { t } = useTranslation()
  const {
    isSubmitting,
    isValid,
    values,
    submitForm,
  } = useFormikContext()
  // form config
  const { labels = {}, selectableFields } = formConfig
  const isDefaultAddress = addressType === 'delivery'
    ? values.isPrimary || !isPrimaryEditable
    : values.isBilling
  const defaultAddressFieldName = addressType === 'delivery'
    ? 'isPrimary'
    : 'isBilling'

  function getPlaceholder(name, options) {
    const value = values[name]
    // const placeholder = _.chain(options)
    //   .find({ value })
    //   .get('label')
    //   .value()
    // return placeholder
    return _.get(_.find(options, { value }), 'label')
  }
  const getLabel = (key) => {
    const label = _.get(labels, key)
    const labelOverride = _.get(labelsOverride, key)
    if (!labelOverride) return label
    if (_.isString(labelOverride)) return labelOverride
    if (_.has(labelOverride, 'translationKey')) {
      return t(labelOverride.translationKey, { defaultValue: label })
    }
    if (_.isFunction(labelOverride)) {
      return labelOverride(label) || label
    }
    return label
  }

  const [isChecked] = useState(isDefaultAddress)
  const styles = useStyles({ latLongAreMandatory })

  return (
    <div className={styles.formAddress}>
      <Form noValidate autoComplete="off">
        <h4>
          {
            isEdit
              ? t('screens.checkout.checkout.delivery.edit')
              : t('screens.checkout.checkout.delivery.add')
          }
        </h4>
        <div>
          {
            latLongAreMandatory && (
              <AddressFormGoogleMap
                defaultCenter={{
                  lat: _.get(values, 'lat'),
                  lng: _.get(values, 'long'),
                }}
                defaultDeliveryCountry={defaultDeliveryCountry}
                // center={{
                //   lat: _.get(values, 'lat'),
                //   lng: _.get(values, 'long'),
                // }}
                onCenterChanged={onMapCenterChanged}
                onDragEnd={onMapDragEnd}
                onDragStart={onMapDragStart}
                onPlaceSelect={onMapPlaceSelect}
                onUserCenterChanged={onMapUserCenterChanged}
                onZoomChanged={onMapZoomChanged}
              />
            )
          }
          <div className={styles.fieldsets}>
            <fieldset className={styles.fieldset}>
              <legend className={styles.legend}>Personal Information</legend>
              <div className={styles.row}>
                <div className={styles.col}>
                  <AddressFormField
                    uppercase
                    required
                    containerClassName={styles.inputBoxStyle}
                    label={t('screens.checkout.checkout.delivery.first')}
                    name="firstName"
                    type="text"
                    formik
                    onChange={onFieldChange}
                  />
                </div>
                <div className={styles.col}>
                  <AddressFormField
                    uppercase
                    required={formConfig.streetLine1Required}
                    containerClassName={styles.inputBoxStyle}
                    label={t('screens.checkout.checkout.delivery.last')}
                    name="lastName"
                    type="text"
                    formik
                    onChange={onFieldChange}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col}>
                  <AddressFormField
                    uppercase
                    required={formConfig.streetLine2Required}
                    containerClassName={styles.inputBoxStyle}
                    label={t('screens.checkout.checkout.delivery.mobile')}
                    name="phone"
                    type="tel"
                    formik
                    countryCallingCodeOptions={countryCallingCodeOptions}
                    onChange={onFieldChange}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className={styles.fieldset}>
              <legend className={styles.legend}>Address Details</legend>
              {_.map(addressFieldKeys, (key) => {
                switch (key) {
                  case 'street_line_1':
                    return (
                      <div key={`address-field-${key}`} className={styles.row}>
                        <div className={styles.colSpan2}>
                          <AddressFormField
                            uppercase
                            required={formConfig.streetLine1Required}
                            containerClassName={styles.inputLongStyle}
                            label={getLabel('streetLine1')}
                            selectableFields={selectableFields}
                            options={streetOptions}
                            name="streetLine1"
                            type="text"
                            formik
                            hide={formConfig.streetLine1Hide}
                            onChange={onFieldChange}
                          />
                        </div>
                      </div>
                    )
                  case 'street_line_2':
                    return (
                      <div key={`address-field-${key}`} className={styles.row}>
                        <div className={styles.colSpan2}>
                          <AddressFormField
                            uppercase
                            required={formConfig.streetLine2Required}
                            containerClassName={styles.inputLongStyle}
                            label={getLabel('streetLine2')}
                            selectableFields={selectableFields}
                            name="streetLine2"
                            type="text"
                            formik
                            hide={formConfig.streetLine2Hide}
                            onChange={onFieldChange}
                            disabled={latLongAreMandatory}
                          />
                        </div>
                      </div>
                    )
                  case 'district':
                    return (
                      <div key={`address-field-${key}`} className={styles.row}>
                        <div className={styles.col}>
                          <AddressFormField
                            uppercase
                            required={formConfig.districtRequired}
                            containerClassName={styles.inputBoxStyle}
                            label={getLabel('district')}
                            selectableFields={selectableFields}
                            options={districtOptions}
                            name="district"
                            type="text"
                            formik
                            placeholder={getPlaceholder('district', districtOptions)}
                            hide={formConfig.districtHide}
                            onChange={onFieldChange}
                            disabled={latLongAreMandatory}
                          />
                        </div>
                      </div>
                    )
                  case 'city':
                    return (
                      <div key={`address-field-${key}`} className={styles.row}>
                        <div className={styles.col}>
                          <AddressFormField
                            uppercase
                            required={formConfig.cityRequired}
                            containerClassName={styles.inputBoxStyle}
                            label={getLabel('city')}
                            selectableFields={selectableFields}
                            options={cityOptions}
                            name="city"
                            type="text"
                            formik
                            placeholder={getPlaceholder('city', cityOptions)}
                            hide={formConfig.cityHide}
                            onChange={onFieldChange}
                            disabled={latLongAreMandatory}
                          />
                        </div>
                      </div>
                    )
                  case 'state':
                    return (
                      <div key={`address-field-${key}`} className={styles.row}>
                        <div className={styles.col}>
                          <AddressFormField
                            uppercase
                            required={formConfig.stateRequired}
                            containerClassName={styles.inputBoxStyle}
                            label={getLabel('state')}
                            selectableFields={selectableFields}
                            options={stateOptions}
                            name="state"
                            type="text"
                            formik
                            placeholder={getPlaceholder('state', stateOptions)}
                            hide={formConfig.stateHide}
                            onChange={onFieldChange}
                            disabled={latLongAreMandatory}
                          />
                        </div>
                      </div>
                    )
                  case 'zip':
                    return (
                      <div key={`address-field-${key}`} className={styles.row}>
                        <div className={styles.col}>
                          <AddressFormField
                            uppercase
                            required
                            containerClassName={styles.inputBoxStyle}
                            label={getLabel('zip')}
                            selectableFields={selectableFields}
                            name="zip"
                            type="text"
                            formik
                            hide={formConfig.zipHide}
                            onChange={onFieldChange}
                          />
                        </div>
                      </div>
                    )
                  case 'country':
                    return _.size(countryOptions) > 1
                      ? (
                        <div key={`address-field-${key}`} className={styles.row}>
                          <div className={styles.col}>
                            <AddressFormField
                              uppercase
                              required
                              containerClassName={styles.inputBoxStyle}
                              label={getLabel('country')}
                              selectableFields={selectableFields}
                              options={countryOptions}
                              name="country"
                              type="text"
                              formik
                              placeholder={getPlaceholder('country', countryOptions)}
                              hide={formConfig.countryHide}
                              onChange={onFieldChange}
                              disabled={latLongAreMandatory}
                            />
                          </div>
                        </div>
                      ) : null
                  default:
                    return null
                }
              })}
            </fieldset>
          </div>
          {/*
            depend on address type, it can be default delivery or default billing
          */}
          <Checkbox
            wrapperStyle={styles.checkbox}
            checked={isDefaultAddress}
            disabled={isChecked}
            formik
            name={defaultAddressFieldName}
          >
            {t('screens.checkout.checkout.delivery.setDefault', { context: addressType })}
          </Checkbox>
          <div className={styles.buttons}>
            <div className={styles.row}>
              <div className={styles.col}>
                <Button
                  className={styles.button}
                  dark
                  uppercase
                  disabled={isSubmitting || !isValid}
                  text={
                    isEdit
                      ? t('screens.checkout.checkout.buttons.update')
                      : t('screens.checkout.checkout.buttons.submit')
                  }
                  onClick={submitForm}
                />
              </div>
              {
                !disableCancel && (
                  <div className={styles.col}>
                    <Button
                      border
                      className={styles.button}
                      uppercase
                      text={t('screens.checkout.checkout.buttons.cancel')}
                      onClick={onCancel}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default AddressFormForm
