import { useContext } from 'react'
import FavouritesContext from './favourites-context'

/**
 * useFavourites
 * The custom hook which provided to external use via context
 */
const useFavourites = () => {
  const context = useContext(FavouritesContext);
  return context
};

export default useFavourites;
