import React from 'react'
import { FaFacebookF } from 'react-icons/fa'
// import { useTheme } from 'react-jss'
import { useLink } from '../../hook'
import useStyles from './button-style'
import combineClassNames from '../../helpers/combineClassNames'

const ButtonView = (props) => {
  const {
    border: outline,
    dark,
    light,
    mini,
    inline,
    text,
    navigate: navTo,
    onClick,
    alert,
    className,
    classNameDisabled,
    facebook,
    disabled,
    iconImage,
    iconInline,
    iconClassName,
    children,
    ...others
  } = props
  const { navigate } = useLink()
  const styles = useStyles({
    // dark,
    // alert,
    // border,
    // facebook,
    // disabled,
  })
  // const theme = useTheme()

  const handleNav = () => {
    navigate(navTo)
  }
  const Icon = iconImage
  return (
    <button
      onClick={navTo ? handleNav : onClick}
      className={
        combineClassNames([
          styles.button,
          dark && styles.dark,
          light && styles.light,
          alert && styles.alert,
          outline && styles.outline,
          facebook && styles.facebook,
          inline && styles.inline,
          mini && styles.mini,
          className,
          disabled && styles.disabled,
          disabled && classNameDisabled,
        ])
        }
      disabled={disabled}
      type="button"
      {...others}
    >
      {facebook && (
        <FaFacebookF
          className={combineClassNames([styles.icon, inline && styles.inlineIcon, iconClassName])}
          // color={theme.background}
          size={22}
        />
      )}
      {text}
      {
        iconImage && (
          iconInline
            ? (
              <Icon
                className={
                  combineClassNames([styles.icon, inline && styles.inlineIcon, iconClassName])
                }
              />
            )
            : (
              <img
                className={
                  combineClassNames([styles.icon, inline && styles.inlineIcon, iconClassName])
                }
                src={iconImage}
                alt={text}
              />
            )
        )
      }
      {children}
    </button>
  )
}

export default ButtonView
