import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { useLink } from '../../hook'

/**
 * LinkController
 * HOC wrapping Gatsby's Link component to support prepend locale code in the link
 */
export default function LinkController({
  to,
  gatsbyLink,
  params,
  ...otherProps
}) {
  const { getPath } = useLink()
  const toWithLocale = to ? getPath(to, { params }) : null
  const LinkCompoenent = useMemo(() => (gatsbyLink ? Link : 'a'), [gatsbyLink])
  return (
    <LinkCompoenent
      href={toWithLocale}
      to={toWithLocale}
      {...otherProps}
    />
  )
}
