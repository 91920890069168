import _ from 'lodash'
import React, {
  useMemo,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'
import FavouriteButton from '../favourite-button'
import Image from '../image'
// import Link from '../link'
import LoadingDots from '../loading-dots'
import Modal from '../modal'
import CustomLabels from '../custom-labels'
import InputQuantity from '../input-quantity'
import PriceList from '../price-list'
import ProductAddonsPlaceholder from '../product-addons-placeholder'
import RecentlyViewedProducts from '../recently-viewed-products'
import RecommendationProducts from '../recommendation-products'
import ProductDetailAttributes from '../product-detail-attributes'
import Title from '../title-detail'
import combineClassNames from '../../helpers/combineClassNames'
import ProductAddons from '../../screens/product/components/product-addons'
import ColorPopoverView from '../../screens/product/components/color-popover/color-popover-view'
import useStyles from './product-quick-add-modal-style'
import IconCross from '../../assets/icons/icon_modal_cross.svg'

export default function ProductQuickAddModalView({
  isOpen = false,
  showContent = false,
  addToCartButtonState,
  loadings,
  // tasks,
  availableQuantity,
  stockLevel,
  product,
  // seoMetas,
  productAddons,
  productQuantity,
  selectedColorOptionId,
  selectedSizeOptionId,
  siblings,
  addonsValue,
  addonsTouched,
  recommendationOptions,
  // isAddonsValid,
  onAddToCart,
  onBackInStockNotification,
  onFavouriteChange,
  onColorOptionChange,
  onSizeOptionChange,
  onAddonsChange,
  onAddonsUpdate,
  onAddonsValidate,
  onProductQuantityChange,
  // addRecentlyViewed,
  getAddonAvailableQuantity,
  onRequestClose,

  isEdit,

  // pirce
  memberPrice,
  sellPrice,
  originalPrice,
  priceSuffix,

  // config
  enableRecentlyViewed,
  favouritesEnabled,
  quantityInputValueEditMode,
}) {
  const styles = useStyles()
  const { t } = useTranslation()

  const bodyRef = useRef(null)

  const addToCartInProgress = _.includes(loadings, 'addItemToCart')
  const fetchCartForEditReady = !_.includes(loadings, 'cart')
  const priceLoading = _.includes(loadings, 'noCache')
  const addonsLoading = _.includes(loadings, 'addons')
  const createBackInStockNotificationsInProgress = _.includes(loadings, 'backInStockNotification')

  const addToCartLabel = useMemo(() => (
    t('screens.product.cart.add', { context: addToCartButtonState, availableQuantity })
  ), [addToCartButtonState])
  const isAddToCartButtonDisabled = useMemo(() => (
    !_.includes([
      'update',
      'normal',
    ], addToCartButtonState)
  ), [addToCartButtonState])
  const scrollAvailable = useMemo(() => (
    bodyRef.current
      ? (bodyRef.current.scrollHeight - bodyRef.current.offsetHeight) > 20
      : false
  ), [loadings])

  const colorOptions = _.get(product, 'colorOptions', [])
  const sizeOptions = _.get(product, 'sizeOptions', [])
  const skus = _.get(product, 'skus', [])
  const selectedColorOption = _.find(colorOptions, { id: selectedColorOptionId })
  const favourite = _.get(selectedColorOption, 'favourite')
  const title = _.get(product, 'title')
  const imageUrl = _.get(selectedColorOption, 'defaultImage.versions.webLarge')
    || _.get(selectedColorOption, 'images.0.versions.webLarge')
  const activeCustomLabels = _.get(selectedColorOption, 'activeCustomLabels')
  const detailsPlainText = _.get(product, 'detailsPlainText')
  const details = _.get(product, 'details')
  const colorOptionVariantType = _.get(product, 'colorOptionVariantType')
  const sizeOptionVariantType = _.get(product, 'sizeOptionVariantType')
  const detailAttributes = _.get(product, 'productDetailAttributes', [])

  return (
    <Modal
      fullPage
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
    >
      <div
        className={
          combineClassNames([
            styles.dialogContainer,
            showContent && styles.dialogContainerActive,
          ])
        }
      >
        <div className={styles.dialogHeader}>
          <Button
            className={styles.closeButton}
            inline
            onClick={onRequestClose}
            iconImage={IconCross}
            iconClassName={styles.closeButtonIcon}
          />
        </div>
        <div
          ref={bodyRef}
          className={styles.dialogBody}
        >
          <div
            className={styles.content}
          >
            <figure
              className={combineClassNames([styles.figure])}
            >
              {
                favouritesEnabled && (
                  <div
                    className={combineClassNames([
                      styles.favouriteButton,
                    ])}
                  >
                    <FavouriteButton
                      colorOptionId={selectedColorOptionId}
                      favourite={favourite}
                      onChange={onFavouriteChange}
                      icon
                    />
                  </div>
                )
              }
              <Image
                alt={title}
                className={combineClassNames([styles.image])}
                placeholderClassName={combineClassNames([styles.noImage])}
                src={imageUrl}
              />
            </figure>
            <div className={styles.customLabelsContainer}>
              <CustomLabels
                className={styles.customLabels}
                stockLevel={stockLevel}
                customLabels={_.take(activeCustomLabels)}
                frontendPosition="1"
              />
            </div>

            {/* TODO: replace it with MultilineTitle, using `|` as line break */}
            <div className={styles.title}>
              <p
                className={combineClassNames([
                  styles.titleText,
                ])}
              >
                {title}
              </p>
            </div>
            <div
              className={combineClassNames([
                styles.details,
              ])}
            >
              {
                !_.isEmpty(detailsPlainText) && (
                  <>
                    <Title
                      className={combineClassNames([
                        styles.sectionTitle,
                        styles.detialsTitle,
                      ])}
                      text={t('screens.product.description')}
                    />
                    <article
                      className={combineClassNames([
                        styles.detailsContent,
                      ])}
                      dangerouslySetInnerHTML={{ __html: details }}
                    />
                  </>
                )
              }
            </div>
            <div
              className={
                combineClassNames([
                  styles.priceContainer,
                ])
              }
            >
              {
                priceLoading
                  ? (
                    <LoadingDots />
                  )
                  : (
                    <PriceList
                      className={combineClassNames([styles.price])}
                      // currency={currency}
                      // Show price for member if rank price provided by sku.meta
                      items={[
                        {
                          ...(!_.isNil(memberPrice) && { label: t('screens.product.price.nonMember') }),
                          sellPrice,
                          originalPrice,
                          suffix: priceSuffix,
                        },
                        ...!_.isNil(memberPrice)
                          ? [{
                            label: t('screens.product.price.member'),
                            sellPrice: memberPrice,
                            suffix: priceSuffix,
                          }] : [],
                      ]}
                    // align="center"
                    />
                  )
              }
            </div>
            {!_.get(colorOptionVariantType, 'hideVariant', false) && (
              <div className={styles.colorsBox}>
                <h5 className={styles.variantTitle}>{_.get(colorOptionVariantType, 'name')}</h5>
                <div className={styles.optionsContainer}>
                  {!isEdit && _.map(colorOptions, (color) => (
                    <div className={styles.optionsItem} key={`color-${color.id}`}>
                      <ColorPopoverView
                        colorOption={color}
                        onClick={() => {
                          onColorOptionChange(color.id)
                        }}
                        isSelected={color.id === selectedColorOptionId}
                      />
                    </div>
                  ))}
                  {isEdit && (
                    <div className={styles.optionsItem}>
                      <ColorPopoverView
                        colorOption={_.find(colorOptions, { id: selectedColorOptionId })}
                        isSelected
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!_.get(sizeOptionVariantType, 'hideVariant', false) && (
              <div className={styles.sizesBox}>
                <div className={styles.sizeTitle}>
                  <h5 className={styles.variantTitle}>{_.get(sizeOptionVariantType, 'name')}</h5>

                  {/* {sizeGuideUrl && (
                    <Link
                      gatsbyLink
                      to={sizeGuideUrl}
                      className={styles.linkViewGuide}
                    >
                      <span>{t('screens.product.sizeGuide')}</span>
                      <img src={ArrowFat} alt={t('screens.product.sizeGuide')} />
                    </Link>
                  )} */}
                </div>
                <div className={styles.optionsContainer}>
                  {!isEdit && _.map(sizeOptions, (size) => {
                    const sku = _.find(skus, {
                      sizeOptionId: size.id,
                      colorOptionId: selectedColorOptionId,
                    })
                    const isSkuOutOfStock = _.get(sku, 'stockLevel', 0) <= 0
                    if (_.isEmpty(sku)) return null
                    return (
                      <div
                        role="button"
                        tabIndex={_.get(size, 'id')}
                        className={combineClassNames([
                          styles.optionsItem,
                          styles.sizeStyle,
                          selectedSizeOptionId === size.id && styles.sizeSelectedStyle,
                          isSkuOutOfStock && styles.sizeOutOfStock,
                        ])}
                        key={`size-${size.id}`}
                        onClick={() => {
                          onSizeOptionChange(size.id)
                        }}
                        onKeyDown={() => {
                          onSizeOptionChange(size.id)
                        }}
                      >
                        {size.name}
                      </div>
                    )
                  })}
                  {isEdit && (
                    <div
                      className={combineClassNames([
                        styles.optionsItem,
                        styles.sizeStyle,
                        styles.sizeSelectedStyle,
                        // styles.sizeDisabled,
                      ])}
                    >
                      {_.get(_.find(sizeOptions, { id: selectedSizeOptionId }), 'name', '')}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.addons}>
              {
                addonsLoading
                  ? <ProductAddonsPlaceholder />
                  : (
                    <ProductAddons
                      // ref={productAddonsRef}
                      productAddons={productAddons}
                      value={addonsValue}
                      touched={addonsTouched}
                      isEdit={isEdit}
                      disabled={addToCartInProgress || !fetchCartForEditReady}
                      // displayErrors={displayAddonsErrors}
                      siblings={siblings}
                      onChange={onAddonsChange}
                      onUpdate={onAddonsUpdate}
                      onValidate={onAddonsValidate}
                      getAddonAvailableQuantity={getAddonAvailableQuantity}
                    />
                  )
              }
            </div>
            {
              !_.isEmpty(detailAttributes) && (
                <div className={combineClassNames([styles.attributes])}>
                  <Title
                    text={t('screens.product.specifications.title')}
                    className={combineClassNames([
                      styles.sectionTitle,
                      styles.attributesTitle,
                    ])}
                  />
                  <ProductDetailAttributes
                    data={detailAttributes}
                  />
                </div>
              )
            }
            <div
              className={styles.recommendations}
            >
              <RecommendationProducts
                {...recommendationOptions}
                ListHeaderComponent={(
                  <Title
                    className={combineClassNames([
                      styles.sectionTitle,
                      styles.recommendationsTitle,
                    ])}
                    text={t('screens.product.like')}
                  />
                )}
              />
            </div>
            {
              enableRecentlyViewed && (
                <div
                  className={styles.recentlyViewed}
                >
                  <RecentlyViewedProducts
                    key={`product_${_.get(product, 'id')}_recently_viewed_products`}
                    excludedProducts={[_.get(product, 'id')]}
                    ListHeaderComponent={(
                      <Title
                        className={combineClassNames([
                          styles.sectionTitle,
                          styles.recentlyViewedTitle,
                        ])}
                        text={t('screens.product.recentlyViewed')}
                      />
                    )}
                  />
                </div>
              )
            }
          </div>

          {/* <ProductDetails
            {...productDetailsPorps}
          /> */}
        </div>
      </div>
      <div
        className={
          combineClassNames([
            styles.dialogFooter,
            scrollAvailable && styles.dialogFooterWithShadow,
          ])
        }
      >
        <div className={styles.productQuantity}>
          <InputQuantity
            key={`input_qty_${_.get(product, 'id', '')}`}
            onChange={onProductQuantityChange}
            value={productQuantity}
            min={1}
            max={availableQuantity}
            valueEditMode={quantityInputValueEditMode}
          />
        </div>
        {
          addToCartButtonState === 'backInStockNotification'
            ? (
              <Button
                dark
                className={styles.backInStockNotificationButton}
                disabled={createBackInStockNotificationsInProgress}
                loading={createBackInStockNotificationsInProgress}
                classNameDisabled={styles.backInStockNotificationButtonDisabled}
                text={addToCartLabel}
                onClick={onBackInStockNotification}
              />
            )
            : (
              <Button
                className={styles.button}
                dark
                text={addToCartLabel}
                onClick={onAddToCart}
                disabled={isAddToCartButtonDisabled}
              />
            )
        }
      </div>
    </Modal>
  )
}
